export interface IAdminState {
  activeMembersCount: number;
  inactiveMembersCount: number;
  lastMassUpdateSendingDate: Date;
}

export const initialAdminState: IAdminState = {
  activeMembersCount: null,
  inactiveMembersCount: null,
  lastMassUpdateSendingDate: null,
};
