import React from 'react';
import { useTranslation } from 'react-i18next';

function MembershipAlreadyCompleted() {
  const { t } = useTranslation();
  return (
    <>
      <h2 className="f4eSimpleLayout__panel__content__title">{t('membershipPage.alreadyCompleted.title')}</h2>
      <p className="f4eSimpleLayout__panel__content__text">
        {t('membershipPage.alreadyCompleted.whatsNext')}
      </p>
    </>
  );
}

export default MembershipAlreadyCompleted;
