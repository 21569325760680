import { IHistory } from '@common/models/history';
import apiClient from '@modules/shared/api-client';
// import { toast } from 'react-toastify';

export const getHistory = (id: string) => {
  return apiClient.get<IHistory>(`/history/${id}`).catch((error) => {
    // toast.error(error.message);
    // note: an error is returned when there's no history,
    // in practice it doesn't really matter
    throw error;
  });
};
