import { LevelOfSchooling } from '../constants/level-of-schooling';
import {
  ActiveState,
  AGDelegateStatus,
  Authorization,
  Gender,
  JobType,
  MemberStatus,
  OtherMemberStatus,
  Province,
  Role,
  Schooling,
} from '../constants/member';
import { getPhoneValidationSchema } from '../utils/validation-schema';
import { IAssociation } from './association';
import { IContribution } from './contribution';
import { IHistory } from './history';
import { IMembership } from './membership';
import { ISchool } from './school';
import { IServiceCenter } from './service-center';

export interface IMember {
  id?: string;
  sin: string;
  identificationNumber?: string;
  activeState?: ActiveState;
  status?: MemberStatus;

  firstName: string;
  lastName: string;
  personalEmail?: string;
  dob?: Date;
  gender?: Gender;
  personalPhoneNumber?: string;
  schooling?: Schooling;

  addrCivicNumber?: string;
  addrStreet?: string;
  addrApp?: string;
  addrCity?: string;
  addrProvince?: string | Province;
  addrPostalCode?: string;

  role?: Role;
  levelsOfSchooling?: LevelOfSchooling[];
  jobClassification?: string;

  professionalPhoneNumber?: string;
  professionalPhoneNumberExt?: string;
  professionalEmail?: string;
  cellPhoneNumber?: string;
  startDate?: Date;
  jobType?: JobType;
  salary?: number;

  isContributor?: boolean;
  lastContributionDate?: Date;
  agDelegateStatus?: AGDelegateStatus;
  otherStatuses?: OtherMemberStatus[];

  ackConsent?: boolean;
  ackAuthorizeRepresentationFqde?: boolean;
  ackAuthorizeContribution?: boolean;
  ackAuthorizeServiceCenter?: boolean;
  ackAuthorizePersonalInfo?: Authorization;

  membership?: IMembership;
  contributions?: IContribution[];
  association?: Partial<IAssociation>;
  serviceCenter?: Partial<IServiceCenter>;
  schools?: ISchool[];

  history?: IHistory;

  lastServiceCenterChangeDate?: Date;
  deleted?: Date;
  lastUpdateDate?: Date;
}

const getMemberBaseValidationSchema = (Yup, areFieldsRequired) => {
  const conditionallyRequired = (field) => (areFieldsRequired ? field.required() : field.nullable());

  return Yup.object({
    // personal
    firstName: Yup.string().max(150).required(),
    lastName: Yup.string().max(150).required(),
    personalEmail: conditionallyRequired(Yup.string().email()),
    dob: areFieldsRequired ? Yup.date().required() : Yup.date().nullable(),
    gender: conditionallyRequired(Yup.string()),
    personalPhoneNumber: getPhoneValidationSchema(Yup, false).nullable(),
    schooling: conditionallyRequired(Yup.string()),
    addrCivicNumber: conditionallyRequired(Yup.string().max(10)),
    addrStreet: conditionallyRequired(Yup.string()),
    addrApp: areFieldsRequired ? Yup.string().max(8) : Yup.string().max(8).nullable(),
    addrCity: conditionallyRequired(Yup.string()),
    addrProvince: conditionallyRequired(Yup.string()),
    addrPostalCode: conditionallyRequired(
      Yup.string()
        .max(7)
        .matches(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/),
    ),
    serviceCenter: Yup.object({
      id: Yup.string().required(),
    }),
    role: conditionallyRequired(Yup.string()),
    levelsOfSchooling: conditionallyRequired(
      areFieldsRequired ? Yup.array().of(Yup.string()).min(1) : Yup.array().of(Yup.string()),
    ),
    jobClassification: conditionallyRequired(Yup.string()),
    professionalPhoneNumber: getPhoneValidationSchema(Yup, false).nullable(),
    professionalPhoneNumberExt: areFieldsRequired ? Yup.string().max(8) : Yup.string().max(8).nullable(),
    professionalEmail: conditionallyRequired(Yup.string().email()),
    cellPhoneNumber: conditionallyRequired(getPhoneValidationSchema(Yup, areFieldsRequired)),
    startDate: conditionallyRequired(Yup.date()),
    jobType: conditionallyRequired(Yup.string()),
  });
};

export const getMemberValidationSchema = (Yup) =>
  getMemberBaseValidationSchema(Yup, false).shape({
    identificationNumber: Yup.string().nullable(),
    jobType: Yup.string().nullable(),
    activeState: Yup.string().required(),
    status: Yup.string().required(),
    ackAuthorizePersonalInfo: Yup.mixed()
      .transform((value) => (!value ? null : value))
      .oneOf([null, Authorization.Accept, Authorization.Refuse], 'must-select-one')
      .nullable(),
  });

export const getMemberRegistrationValidationSchema = (Yup) =>
  getMemberBaseValidationSchema(Yup, true).shape({
    schools: Yup.array()
      .of(Yup.object({ id: Yup.string().required() }))
      .min(1)
      .required(),
    ackConsent: Yup.bool().oneOf([true], 'must-accept').required(),
    ackAuthorizeRepresentationFqde: Yup.bool().oneOf([true], 'must-accept').required(),
    ackAuthorizeContribution: Yup.bool().oneOf([true], 'must-accept').required(),
    ackAuthorizeServiceCenter: Yup.bool().oneOf([true], 'must-accept').required(),

    ackAuthorizePersonalInfo: Yup.string()
      .oneOf([Authorization.Accept, Authorization.Refuse], 'must-select-one')
      .required(),
  });
