import { IChange } from '@common/models/change';
import { IMember } from '@common/models/member';
import { IMemberMergeRequest } from '@common/models/member-merge-request';
import apiClient from '@modules/shared/api-client';
import { handleServiceException } from '@modules/shared/service-error-handler';

export function mergeMember(request: IMemberMergeRequest): Promise<IMember> {
  return apiClient.post<IMember>('/member/merge', request).catch(handleServiceException);
}

export function getMemberToMerge(member: IMember, propertiesToMerge: (keyof IMember)[]): IMember {
  return (Object.keys(member) as (keyof IMember)[]).reduce((obj, property) => {
    obj[property.toString()] = propertiesToMerge.includes(property) ? undefined : member[property];
    return obj;
  }, {} as IMember);
}

export function getConflictsResolutions(
  original: IMember,
  updated: IMember,
  properties: (keyof IMember)[],
): IChange<IMember, any>[] {
  return properties.map(
    (property) =>
      ({
        property,
        currentValue: original[property],
        newValue: updated[property],
      } as IChange<IMember, any>),
  );
}
