import './AuthPage.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import SignInForm from '@modules/auth/components/SignInForm';
import { SimpleLayout } from '@modules/shared/components/Layout';

export default function AuthPage() {
  const { t } = useTranslation();

  return (
    <SimpleLayout width="small">
      <div className="f4eSignIn">
        <h2 className="f4eSignIn__title">{t('signInPage.title')}</h2>
        <SignInForm />
      </div>
    </SimpleLayout>
  );
}
