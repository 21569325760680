import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { selectUser } from '@modules/app/redux/selectors';
import Layout, { SimpleLayout } from '@modules/shared/components/Layout/';
import ErrorMessage from './components/ErrorMessage';

function PageNotFound() {
  const currentUser = useSelector(selectUser);
  const history = useHistory();
  const { t } = useTranslation();

  const isAuthenticated = currentUser !== null && currentUser !== undefined;

  const renderContent = (unauthenticated: boolean) => {
    return (
      <ErrorMessage
        hosted={unauthenticated}
        title={t('notFoundPage.title')}
        subTitle={t('notFoundPage.subTitle')}
        description={t('notFoundPage.desc')}
        actionLabel={t('notFoundPage.actionLabel')}
        actionHandler={() => {
          history.push('/');
        }}
      />
    );
  };

  const renderAuthenticated = () => {
    return <Layout>{renderContent(false)}</Layout>;
  };

  const renderUnAuthenticated = () => {
    return <SimpleLayout>{renderContent(true)}</SimpleLayout>;
  };

  return isAuthenticated ? renderAuthenticated() : renderUnAuthenticated();
}

export default PageNotFound;
