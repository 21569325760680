import './AppHeader.scss';

import React from 'react';
import { useLocation } from 'react-router-dom';

import { ReactComponent as Logo } from '@assets/logo.svg';
import { AppRouteSections } from '@constants/routes';
import AdministrationSubMenu from '@modules/admin/components/AdministrationSubMenu';
import CurrentUserMenu from '@modules/admin/components/CurrentUserMenu';
import FinancesSubMenu from '@modules/finances/components/FinancesSubMenu';
import MembersSubMenu from '@modules/members/components/MembersSubMenu';
import { sectionFromPathname } from '@modules/shared/url';
import AppMenu from './AppMenu';

function AppHeader() {
  const location = useLocation();

  const renderSubMenu = () => {
    const section = sectionFromPathname(location.pathname);

    switch (section) {
      case AppRouteSections.Members:
        return <MembersSubMenu />;
      case AppRouteSections.Finances:
        return <FinancesSubMenu />;
      case AppRouteSections.Administration:
        return <AdministrationSubMenu />;
      default:
        return null;
    }
  };

  return (
    <header className="f4eHeader">
      <section className="f4eHeader__section">
        <Logo className="f4eHeader__section__logo" />
        <nav className="f4eHeader__section__navigation">
          <AppMenu />
        </nav>
        <div className="f4eHeader__section__currentUser">
          <CurrentUserMenu />
        </div>
      </section>
      <section className="f4eHeader__section f4eHeader__section--secondary">
        <nav className="f4eHeader__section__navigation">{renderSubMenu()}</nav>
      </section>
    </header>
  );
}

export default AppHeader;
