import { AppRouteSections } from '@constants/routes';

export const sectionFromPathname = (pathname: string): AppRouteSections => {
  const pathNameParts = pathname.toLowerCase().split('/');

  switch (`/${pathNameParts[1]}`) {
    case AppRouteSections.Members:
      return AppRouteSections.Members;
    case AppRouteSections.Finances:
      return AppRouteSections.Finances;
    case AppRouteSections.Administration:
      return AppRouteSections.Administration;
    default:
      return AppRouteSections.Unknown;
  }
};
