import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DataTable, useDataTable } from '@novo-electronique/react-data-table';

import { AccessLevel, PermissionScope } from '@common/constants/permission';
import { IGricsImport } from '@common/models/grics-import';
import { getLatestBudgetYear } from '@common/utils/period';
import { selectUser } from '@modules/app/redux/selectors';
import { generateColumnDefinition } from '@modules/data-table/column-definition-factory';
import { DataTableSource } from '@modules/data-table/data-table-source';
import Layout, { LayoutContent, LayoutHeader } from '@modules/shared/components/Layout/';
import PeriodSelector from '@modules/shared/components/PeriodSelector';
import Toolbar, { ToolbarActionType } from '@modules/shared/components/Toolbar';
import { hasPermission } from '@modules/shared/permission';
import { useQuery } from '@modules/shared/useQuery';
import { AppRoutePages } from '@src/constants';
import GricsImportDetails from './components/GricsImportDetails';
import GricsImportForm from './components/GricsImportForm';

function GricsImportPage() {
  const { t } = useTranslation();
  const currentUser = useSelector(selectUser);
  const query = useQuery();
  const history = useHistory();

  const gricsImportId = query.get('id');

  const onTableSelectionChanged = ({ id }: IGricsImport) => {
    history.push(`${AppRoutePages.GricsImport}?id=${id}`);
  };

  const { isReady, gridApi, gridProperties, resetFilters, refresh } = useDataTable(
    DataTableSource.GricsImports,
    generateColumnDefinition(DataTableSource.GricsImports),
    onTableSelectionChanged,
    {
      options: { showSidebar: false, getRowNodeId: (data) => data.id },
    },
  );

  useEffect(() => {
    if (isReady) {
      onPeriodChange(getLatestBudgetYear());
    }
  }, [isReady]);

  useEffect(() => {
    if (gricsImportId) {
      gridApi?.getRowNode(gricsImportId)?.setSelected(true);
    }
  }, [gricsImportId]);

  const onPeriodChange = (budgetYear: string) => {
    const filter = gridApi.getFilterInstance('budgetYear');
    filter.setModel({
      type: 'equals',
      filter: budgetYear,
    });

    gridApi.onFilterChanged();
  };

  const openImportForm = () => {
    history.push(`${AppRoutePages.GricsImport}?id`);
  };

  const closeImportForm = (filesUploaded?: boolean) => {
    history.push(AppRoutePages.GricsImport);
    if (filesUploaded) {
      refresh({ purge: true });
    }
  };

  const closeDetailForm = () => {
    history.push(AppRoutePages.GricsImport);
  };

  const hasFullAccess = hasPermission(PermissionScope.Import, AccessLevel.Full, currentUser);
  return (
    <Layout>
      <LayoutHeader title={t('gricsImportPage.importTitle')}>
        <PeriodSelector onChange={onPeriodChange} small />
        <Toolbar
          actions={{
            [ToolbarActionType.Add]: hasFullAccess && {
              label: t('gricsImportPage.importNewFiles'),
              action: openImportForm,
            },
            [ToolbarActionType.FilterOff]: () => {
              resetFilters();
              onPeriodChange(getLatestBudgetYear());
            },
            [ToolbarActionType.Refresh]: () => refresh({ purge: true }),
          }}
        />
      </LayoutHeader>
      <LayoutContent>
        <DataTable {...gridProperties} />
        {!gricsImportId && gricsImportId !== null && <GricsImportForm closeHandler={closeImportForm} />}
        {gricsImportId && <GricsImportDetails closeHandler={closeDetailForm} gricsImportId={gricsImportId} />}
      </LayoutContent>
    </Layout>
  );
}

export default GricsImportPage;
