import './MembershipPage.scss';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AccessLevel } from '@common/constants/permission';
import { IMember } from '@common/models/member';
import { getTokenPayload } from '@common/utils/token';
import Initializing from '@modules/shared/components/Initializing';
import { SimpleLayout } from '@modules/shared/components/Layout';
import Toolbar, { ToolbarActionType } from '@modules/shared/components/Toolbar';
import { handleServiceException } from '@modules/shared/service-error-handler';
import { useQuery } from '@modules/shared/useQuery';
import MembershipCompleted from './components/MembershipCompleted';
import MembershipConfirmation from './components/MembershipConfirmation';
import MembershipForm from './components/MembershipForm';
import { getAdherent, saveAdherent } from './service';

enum MembershipPageStates {
  Loading = 'loading',
  Default = 'default',
  Confirmation = 'confirmation',
  Completed = 'completed',
}

const membershipDefaultInitialValues: Partial<{ [key in keyof IMember]: any }> = {
  firstName: '',
  lastName: '',
  personalEmail: '',
  dob: '',
  gender: '',
  personalPhoneNumber: '',
  schooling: '',
  addrCivicNumber: '',
  addrStreet: '',
  addrApp: '',
  addrCity: '',
  addrProvince: '',
  addrPostalCode: '',
  startDate: '',

  serviceCenter: { id: '' },
  role: '',
  levelsOfSchooling: [],
  schools: [],
  jobClassification: '',
  jobType: '',
  professionalPhoneNumber: '',
  professionalPhoneNumberExt: '',
  professionalEmail: '',
  cellPhoneNumber: '',

  ackConsent: false,
  ackAuthorizeRepresentationFqde: false,
  ackAuthorizeContribution: false,
  ackAuthorizeServiceCenter: false,

  ackAuthorizePersonalInfo: false,
};

function MembershipPage() {
  const { t } = useTranslation();
  const [initialValues, setInitialValues] = useState<typeof membershipDefaultInitialValues | IMember>(
    membershipDefaultInitialValues,
  );
  const [pageState, setPageState] = useState<MembershipPageStates>(MembershipPageStates.Loading);
  const [isKnownMember, setIsKnownMember] = useState<boolean>(false);
  const [member, setMember] = useState<IMember>(null);
  const query = useQuery();

  const token = query.get('token');
  const tokenPayload = getTokenPayload(token);

  useEffect(() => {
    if (token !== null) {
      getAdherent(token)
        .then((member: IMember) => {
          setInitialValues({ ...member, serviceCenter: { id: member.serviceCenter?.id } });
          setIsKnownMember(true);
        })
        .catch((error) => console.error(error))
        .finally(() => setPageState(MembershipPageStates.Default));
    } else {
      setPageState(MembershipPageStates.Default);
    }
  }, []);

  const onSubmit = (values: IMember) => {
    return saveAdherent(token, values)
      .then(() => {
        setMember(values);
        setPageState(isKnownMember ? MembershipPageStates.Completed : MembershipPageStates.Confirmation);
      })
      .catch(handleServiceException);
  };

  const renderContent = () => {
    switch (pageState) {
      case MembershipPageStates.Default:
        const readonly = isKnownMember && tokenPayload && tokenPayload.accessLevel === AccessLevel.Read;
        return (
          <>
            {readonly && <Toolbar actions={{ [ToolbarActionType.Print]: window.print }} />}
            <MembershipForm
              onSubmit={onSubmit}
              readonly={readonly}
              initialValues={initialValues}
              submitLabelKey="membershipPage.submit"
            />
          </>
        );
      case MembershipPageStates.Confirmation:
        return <MembershipConfirmation email={member.professionalEmail} />;
      case MembershipPageStates.Completed:
        return <MembershipCompleted />;
      case MembershipPageStates.Loading:
        return <Initializing />;
    }
  };

  return <SimpleLayout title={t('membershipPage.title')}>{renderContent()}</SimpleLayout>;
}

export default MembershipPage;
