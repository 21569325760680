import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, ButtonSize, ButtonStyle } from '@novo-electronique/react-button';
import {
  Form,
  FormActionButton,
  FormActionButtonType,
  FormCheckboxField,
  FormContext,
  FormFields,
  FormSelectField,
  FormSelectFieldOptions,
  FormTextAreaField,
} from '@novo-electronique/react-forms';
import {
  SidePanelContent,
  SidePanelFooter,
  SidePanelFooterActions,
  SidePanelHeader,
  SidePanelHeaderNav,
} from '@novo-electronique/react-side-panel';
import { Tab, TabContext, TabList, TabPanel } from '@novo-electronique/react-tabs';

import { emptyCharacter } from '@common/constants/global';
import { MembershipStatus } from '@common/constants/membership';
import { MemberNotificationCode, NotificationCode } from '@common/constants/notification';
import { IMembership } from '@common/models/membership';
import { formatDate } from '@common/utils/date';
import { getAllowedCodes } from '@common/utils/notification';
import { setAppIsLoading } from '@modules/app/redux/action';
import { selectServiceCenterValueProvider } from '@modules/app/redux/selectors';
import { DataTableSource } from '@modules/data-table/data-table-source';
import History from '@modules/history/History';
import { sendNotification } from '@modules/members/services/members';
import { getMembership, processStateOptions, statusOptionsFiltered } from '@modules/membership/service';
import SidePanel from '@modules/shared/components/SidePanel';
import Toolbar, { ToolbarActionType } from '@modules/shared/components/Toolbar';
import { AppRoutePages } from '@src/constants';

const datesProperties: (keyof IMembership)[] = [
  'formCompletedDate',
  'formConfirmedDate',
  'firstContributionDate',
  'approvingDate',
];

const excludedCodes: MemberNotificationCode[] = [
  NotificationCode.MemberUpdateRequest,
  NotificationCode.NewMemberConfirmed,
];

enum HeaderTabs {
  Membership = 'membership',
  History = 'history',
  Notes = 'notes',
}

interface IProps {
  membershipId: string;
  readonly: boolean;
  cancelHandler: () => void;
  submitHandler: (membership: IMembership) => Promise<void>;
}

function MembershipEditionForm({ membershipId, readonly, cancelHandler, submitHandler }: IProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const serviceCenterValueProvider = useSelector(selectServiceCenterValueProvider);
  const history = useHistory();

  const [subTitle, setSubTitle] = useState(' ');
  const [selectedTab, setSelectedTab] = useState<HeaderTabs>();
  const [initialValues, setInitialValues] = useState<IMembership>(null);
  const [isSendingNotificationsMap, setIsSendingNotificationsMap] = useState<
    Partial<{ [code in MemberNotificationCode]: boolean }>
  >({});

  useEffect(() => {
    dispatch(setAppIsLoading(true));
    getMembership(membershipId)
      .then((membership: IMembership) => {
        if (membership.member?.serviceCenter) {
          setSubTitle(serviceCenterValueProvider.label(membership.member.serviceCenter.id));
        }

        datesProperties.forEach(
          (property) =>
            (membership[property as string] =
              membership[property as string] && new Date(membership[property as string])),
        );
        setInitialValues(membership);
      })
      .finally(() => dispatch(setAppIsLoading(false)));
  }, [membershipId]);

  const sortDatesProperties = (a, b): number => {
    const timeA = (initialValues[a] as Date)?.getTime();
    const timeB = (initialValues[b] as Date)?.getTime();

    if (timeA === timeB) {
      return datesProperties.indexOf(a) - datesProperties.indexOf(b);
    } else {
      return (
        ((initialValues[a] as Date)?.getTime() || Number.MAX_SAFE_INTEGER) -
        ((initialValues[b] as Date)?.getTime() || Number.MAX_SAFE_INTEGER)
      );
    }
  };

  const onNotificationSend = (code: MemberNotificationCode) => {
    if (confirm(t('membershipsPage.sendNotificationConfirmation', { context: code }))) {
      setIsSendingNotificationsMap((map) => ({ ...map, [code]: true }));
      return sendNotification(initialValues.member.id, code).finally(() =>
        setIsSendingNotificationsMap((map) => ({ ...map, [code]: false })),
      );
    }
  };

  if (!initialValues) {
    return null;
  }

  const initialStatus = initialValues.status;
  const confirmSubmit = async (value) => {
    if (
      value.status !== MembershipStatus.Cancelled ||
      value.status === initialStatus ||
      (value.status === MembershipStatus.Cancelled && confirm(t('membershipsPage.cancelMessageConfirmation')))
    ) {
      return submitHandler(value);
    }
  };

  const codes =
    initialValues.status === MembershipStatus.Processing
      ? getAllowedCodes(initialValues.member).filter((code) => !excludedCodes.includes(code))
      : [];

  const statusOptions = statusOptionsFiltered(initialValues);
  return (
    <FormContext initialValues={initialValues} validationSchema={null} onSubmit={confirmSubmit}>
      <SidePanel closeHandler={cancelHandler}>
        <TabContext onChange={(value) => setSelectedTab(value as HeaderTabs)}>
          <SidePanelHeader subTitle={subTitle} displayCloseButton={true}>
            {initialValues.member.firstName} {initialValues.member.lastName}
            <Toolbar
              actions={{
                [ToolbarActionType.View]: {
                  action: () => history.push(`${AppRoutePages.Members}?id=${initialValues.member.id}`),
                  label: t('membershipsPage.viewProfile'),
                  disabled: initialValues.status === MembershipStatus.Cancelled,
                },
              }}
            />
          </SidePanelHeader>
          <SidePanelHeaderNav isHost>
            <TabList>
              <Tab value={HeaderTabs.Membership} active>
                {t('membersPage.tabs.membership')}
              </Tab>
              <Tab value={HeaderTabs.History}>{t('membersPage.tabs.history')}</Tab>
              <Tab value={HeaderTabs.Notes}>{t('membersPage.tabs.notes')}</Tab>
            </TabList>
          </SidePanelHeaderNav>

          <Form readonly={readonly}>
            <SidePanelContent>
              <TabPanel value={HeaderTabs.Membership}>
                <FormFields>
                  <FormSelectField name="status" label={t('models.membership.status')} autoFocus>
                    <FormSelectFieldOptions
                      options={statusOptions}
                      addEmptyOption={!statusOptions.some((option) => option.value === initialValues.status)}
                      keepOriginalOrder
                    />
                  </FormSelectField>
                  <FormSelectField name="processState" label={t('models.membership.processState')} disabled>
                    <FormSelectFieldOptions options={processStateOptions()} />
                  </FormSelectField>
                  <FormCheckboxField
                    name="externalAccountCreated"
                    label={t('models.membership.externalAccountCreated')}
                  />
                </FormFields>
                <dl className="f4eDefinitions">
                  {datesProperties.sort(sortDatesProperties).map((property) => (
                    <React.Fragment key={property}>
                      <dt className="f4eDefinitions__part f4eDefinitions__part--term">
                        {t(`models.membership.${property}`)}
                      </dt>
                      <dd className="f4eDefinitions__part">
                        {formatDate(initialValues[property as string]) || emptyCharacter}
                      </dd>
                    </React.Fragment>
                  ))}
                </dl>
              </TabPanel>

              <TabPanel value={HeaderTabs.History}>
                <History
                  id={initialValues.history?.id}
                  datasource={DataTableSource.Memberships}
                  hidden={selectedTab !== HeaderTabs.History}
                />
              </TabPanel>

              <TabPanel value={HeaderTabs.Notes}>
                <FormTextAreaField name="notes" width={100} rows={10} />
              </TabPanel>
            </SidePanelContent>
            {!readonly && selectedTab !== HeaderTabs.History && (
              <SidePanelFooter>
                <SidePanelFooterActions>
                  <FormActionButton type={FormActionButtonType.Submit} small={true}>
                    {t('general.forms.save')}
                  </FormActionButton>
                  {codes.map((code) => (
                    <Button
                      key={code}
                      style={ButtonStyle.Normal}
                      size={ButtonSize.Small}
                      onClick={() => onNotificationSend(code)}
                      isLoading={isSendingNotificationsMap[code]}
                    >
                      {t('membershipsPage.sendNotification', { context: code })}
                    </Button>
                  ))}
                </SidePanelFooterActions>
                <SidePanelFooterActions isSecondary={true}>
                  <FormActionButton type={FormActionButtonType.Reset} small={true} />
                </SidePanelFooterActions>
              </SidePanelFooter>
            )}{' '}
          </Form>
        </TabContext>
      </SidePanel>
    </FormContext>
  );
}

export default MembershipEditionForm;
