import './Wizard.scss';

import React, { createContext, ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonSize, ButtonStyle } from '@novo-electronique/react-button';
import { FormActionButton, FormActionButtonType } from '@novo-electronique/react-forms';
import classNames from 'classnames';

import { IWizardStep } from '@modules/shared/components/Wizard/models';

interface IWizardContext {
  activeIndex: number;
  register: (step: IWizardStep) => void;
  remove: (id: string) => void;
  getIndex: (id: string) => number;
}

export const WizardContext = createContext<IWizardContext>(null);

interface IProps {
  children: ReactNode | ReactNode[];
  onComplete?: () => void;
  onCancel?: () => void;
  isNextEnabled?: (activeIndex: number) => boolean;
}

function Wizard({ children, onCancel, isNextEnabled }: IProps) {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);
  const [steps, setSteps] = useState<IWizardStep[]>([]);
  const isDeletedRef = useRef(false);

  const isLastStep = activeIndex === steps.length - 1;

  useEffect(() => {
    return () => {
      isDeletedRef.current = true;
    };
  });

  const register = (step: IWizardStep) => {
    if (!isDeletedRef.current) {
      setSteps((steps) => [...steps, step]);
    }
  };

  const remove = (id: string) => {
    if (!isDeletedRef.current) {
      setSteps((steps) => steps.filter((step) => step.id !== id));
    }
  };

  const getIndex = (id: string): number => {
    return steps.findIndex((step) => step.id === id);
  };

  const renderStep = (step: IWizardStep) => {
    const index = getIndex(step.id);
    const className = classNames('f4eWizard__steps__item', {
      'f4eWizard__steps__item--completed': index < activeIndex,
      'f4eWizard__steps__item--active': index === activeIndex,
    });

    return (
      <li className={className} key={step.id}>
        {step.title}
      </li>
    );
  };

  return (
    <WizardContext.Provider value={{ activeIndex, register, remove, getIndex }}>
      <article className="f4eWizard">
        <ul className="f4eWizard__steps" role="heading">
          {steps.map((step) => renderStep(step))}
        </ul>

        <div className="f4eWizard__content">{children}</div>

        <footer className="f4eWizard__footer">
          <Button style={ButtonStyle.Normal} size={ButtonSize.Small} onClick={() => onCancel && onCancel()}>
            {t('general.actions.cancel')}
          </Button>

          {!isLastStep && (
            <Button
              style={ButtonStyle.Primary}
              size={ButtonSize.Small}
              onClick={() => setActiveIndex((activeIndex) => ++activeIndex)}
              disabled={isNextEnabled && !isNextEnabled(activeIndex)}
            >
              {t('general.actions.next')}
            </Button>
          )}
          {isLastStep && (
            <FormActionButton small type={FormActionButtonType.Submit}>
              {t('general.actions.confirm')}
            </FormActionButton>
          )}
        </footer>
      </article>
    </WizardContext.Provider>
  );
}

export default Wizard;
