import { financialYearFirstMonth, initialYear } from '@common/constants/global';
import { IPeriod, IPeriods } from '@common/models/period';
import { getWeek, parseIsoDateString, subtractMonths } from '@common/utils/date';

export function getBudgetYearFromDate(date: Date | string): string {
  date = date instanceof Date ? date : parseIsoDateString(date);

  return date.getMonth() < financialYearFirstMonth
    ? `${date.getFullYear() - 1}${date.getFullYear()}`
    : `${date.getFullYear()}${date.getFullYear() + 1}`;
}

export function getPeriodFromBudgetYear(budgetYear: string): IPeriod {
  const start = parseInt(budgetYear.substring(0, 4), 10);
  const end = parseInt(budgetYear.substring(4), 10);

  return {
    start,
    end,
  };
}

export function getLatestPeriods(count: number = 10): IPeriods {
  const currentDate = new Date();
  const currentYear =
    currentDate.getMonth() >= financialYearFirstMonth
      ? currentDate.getFullYear() + 1
      : currentDate.getFullYear();

  const fiscalYearPeriods = {};
  for (let i = currentYear, len = Math.max(initialYear, currentYear - count); i > len; i--) {
    fiscalYearPeriods[`${i - 1}${i}`] = { start: i - 1, end: i };
  }

  return fiscalYearPeriods;
}

export function getLatestBudgetYear(): string {
  return Object.keys(getLatestPeriods(1))[0];
}

export function getLatestPeriod(): IPeriod {
  return Object.values(getLatestPeriods(1))[0];
}

/**
 * @note This is an approximation of the period number. Could be misleading sometime.
 */
export function getPeriodNumberFromDate(date: Date): number {
  return Math.ceil(getWeek(subtractMonths(date, financialYearFirstMonth)) / 2);
}
