import { toast } from 'react-toastify';
import i18n from 'i18next';

export function handleServiceException(error: any): Promise<any> {
  const { statusCode, message } = getStatusCodeAndMessageFromError(error);

  toast.error(message);
  console.error(`[${statusCode}] (${error.message}) ${message}`);

  throw error;
}

export function getStatusCodeAndMessageFromError(error: any): {
  statusCode: number | string;
  message: string;
} {
  const statusCode = error.payload?.statusCode || error.response?.statusCode;

  const localKey = `apiErrors.error${statusCode}.message`;
  let message = '';

  message = i18n.t(localKey, {
    context: error.message,
    status: statusCode,
  });

  if (message === localKey) {
    message = i18n.t(`apiErrors.unknown`, {
      context: error.message,
      status: statusCode,
    });
  }

  return {
    statusCode,
    message,
  };
}
