import { IOption } from '@novo-electronique/react-forms';

import { getLatestPeriods } from '@common/utils/period';

export const periodOptions = (): IOption[] => {
  const periods = getLatestPeriods();

  return Object.keys(periods)
    .map((key: string) => ({
      key,
      value: key,
      label: `${periods[key].start}-${periods[key].end}`,
    }))
    .sort((a, b) => b.value.localeCompare(a.value));
};
