export function toPromise<T>(promiseLike: PromiseLike<T>): Promise<T> {
  return new Promise<T>((resolve, reject) => promiseLike.then(resolve, reject));
}

export async function runSerial<T>(asyncFunctions: (() => Promise<T>)[]): Promise<T[]> {
  const results: T[] = [];
  for (const asyncFunction of asyncFunctions) {
    results.push(await asyncFunction());
  }
  return Promise.all(results);
}
