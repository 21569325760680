import './CumulativeConciliationReport.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { IAssociationConciliationItem, IConciliationReport } from '@common/models/contribution-report';
import { formatCurrency } from '@common/utils/currency';
import { formatDate } from '@common/utils/date';
import Report from '@modules/shared/components/Report';

interface IProps {
  title: string;
  data: IConciliationReport;
}

function CumulativeConciliationReport({ title, data }: IProps) {
  const { t } = useTranslation();

  if (!data) return null;

  const toAssociationConciliationLine = (associationConciliation: IAssociationConciliationItem) => {
    return (
      <tr key={associationConciliation.id}>
        <td>{`${associationConciliation.number} : ${associationConciliation.name}`}</td>
        <td>{associationConciliation.activeContributorsCount}</td>
        <td>{formatCurrency(associationConciliation.federationShare)}</td>
        <td>{formatCurrency(associationConciliation.associationShare)}</td>
        <td>{formatCurrency(associationConciliation.socialContribution)}</td>
        <td>{formatCurrency(associationConciliation.specialContribution)}</td>
        <td>{formatCurrency(associationConciliation.totalAmount)}</td>
        <td>{formatCurrency(associationConciliation.paidAmount)}</td>
        <td>{formatCurrency(associationConciliation.balance)}</td>
      </tr>
    );
  };

  const renderTotal = (conciliationReport: IConciliationReport) => {
    const totals = conciliationReport.associations.reduce(
      (acc: Partial<IAssociationConciliationItem>, associationConciliation: IAssociationConciliationItem) => {
        return {
          activeContributorsCount:
            acc.activeContributorsCount + associationConciliation.activeContributorsCount || 0,
          federationShare: acc.federationShare + associationConciliation.federationShare || 0,
          associationShare: acc.associationShare + associationConciliation.associationShare || 0,
          socialContribution: acc.socialContribution + associationConciliation.socialContribution || 0,
          specialContribution: acc.specialContribution + associationConciliation.specialContribution || 0,
          totalAmount: acc.totalAmount + associationConciliation.totalAmount || 0,
          paidAmount: acc.paidAmount + associationConciliation.paidAmount || 0,
          balance: acc.balance + associationConciliation.balance || 0,
        };
      },
      {
        id: 'total',
        activeContributorsCount: 0,
        federationShare: 0,
        associationShare: 0,
        socialContribution: 0,
        specialContribution: 0,
        totalAmount: 0,
        paidAmount: 0,
        balance: 0,
      },
    );

    return (
      <tr key={totals.id}>
        <td>{t('reports.cumulativeConciliation.summaryTotal')}</td>
        <td>{totals.activeContributorsCount}</td>
        <td>{formatCurrency(totals.federationShare)}</td>
        <td>{formatCurrency(totals.associationShare)}</td>
        <td>{formatCurrency(totals.socialContribution)}</td>
        <td>{formatCurrency(totals.specialContribution)}</td>
        <td>{formatCurrency(totals.totalAmount)}</td>
        <td>{formatCurrency(totals.paidAmount)}</td>
        <td>{formatCurrency(totals.balance)}</td>
      </tr>
    );
  };

  return (
    <Report
      title={title}
      subTitle={t('reports.cumulativeConciliation.generated', {
        date: formatDate(data.date, 'd LLLL yyyy'),
        generationDate: formatDate(new Date(), 'd LLLL yyyy'),
        generationTime: formatDate(new Date(), 'H:mm'),
      })}
    >
      <section className="f4eReport__content">
        <table className="f4eConciliationReport">
          <thead className="f4eConciliationReport__header">
            <tr>
              <th>{t('reports.cumulativeConciliation.association')}</th>
              <th>{t('reports.cumulativeConciliation.contributors')}</th>
              <th>{t('reports.cumulativeConciliation.fqdeShare')}</th>
              <th>{t('reports.cumulativeConciliation.assocShare')}</th>
              <th>{t('reports.cumulativeConciliation.social')}</th>
              <th>{t('reports.cumulativeConciliation.special')}</th>
              <th>{t('reports.cumulativeConciliation.total')}</th>
              <th>{t('reports.cumulativeConciliation.payments')}</th>
              <th>{t('reports.cumulativeConciliation.balance')}</th>
            </tr>
          </thead>
          <tfoot className="f4eConciliationReport__footer">{renderTotal(data)}</tfoot>
          <tbody className="f4eConciliationReport__body">
            {data.associations.map(toAssociationConciliationLine)}
          </tbody>
        </table>
      </section>
    </Report>
  );
}

export default CumulativeConciliationReport;
