import '@theme/objects/_tags.scss';

import { ColDef, ValueFormatterParams } from '@ag-grid-community/core';
import classNames from 'classnames';
import i18next from 'i18next';

import { DataType } from '@common/constants/data-type';
import { emptyCharacter } from '@common/constants/global';
import { Mask } from '@common/constants/mask';
import { MembershipIssue } from '@common/constants/membership';
import { formatCurrency } from '@common/utils/currency';
import { formatDate } from '@common/utils/date';
import { formatPhoneNumber, formatPostalCode } from '@common/utils/formatter';
import IValueProvider, {
  accountingMonthValueProvider,
  enumAllowedTypes,
  enumDataTypes,
  genderValueProvider,
  levelOfSchoolingValueProvider,
  membershipIssueValueProvider,
  otherMemberStatusProvider,
} from '@common/value-provider';
import createEnumValueProvider from '@common/value-provider/enum-value-provider-factory';
import { ICache } from '@modules/app/redux/state';

const tagDataTypes = [
  DataType.ActiveState,
  DataType.MemberStatus,
  DataType.MembershipStatus,
  DataType.MembershipProcessState,
  DataType.GricsImportStatus,
  DataType.GricsImportChangeStatus,
  DataType.UserRole,
];

const valueComparator = (valueProvider: IValueProvider) => (a, b) =>
  valueProvider?.label(a).localeCompare(valueProvider?.label(b));

const getEnumArrayColumn = (valueProvider: IValueProvider): ColDef => ({
  filter: 'agSetColumnFilter',
  filterParams: {
    valueFormatter: ({ value }: ValueFormatterParams) => valueProvider.label(value),
    values: valueProvider.values(),
  },
  valueFormatter: ({ value }: ValueFormatterParams) => {
    if (!value) return '';

    const values = Array.isArray(value) ? value : value.split(',');
    return values
      .map((value) => valueProvider.label(value))
      .sort((a, b) => a.localeCompare(b))
      .join(', ');
  },
});

const getEntityColumn = (
  valueProvider: IValueProvider,
  valueFilter: (value: string) => boolean = () => true,
): ColDef => ({
  filter: 'agSetColumnFilter',
  valueFormatter: ({ value }: ValueFormatterParams) => {
    if (!value) {
      return emptyCharacter;
    }
    return valueProvider.label(value);
  },
  filterParams: {
    comparator: (a, b) => {
      a = valueProvider.label(a).split('–')[0];
      b = valueProvider.label(b).split('–')[0];

      if (isFinite(a) && isFinite(b)) {
        return +a - +b;
      } else {
        return a.localeCompare(b);
      }
    },
    values: valueProvider?.values().filter(valueFilter),
    valueFormatter: ({ value }: ValueFormatterParams) => {
      if (!value) {
        return emptyCharacter;
      }
      return valueProvider.label(value);
    },
  },
});

const enumColumns = enumAllowedTypes.reduce((obj, type) => {
  const valueProvider = createEnumValueProvider(type);

  obj[type] = {
    filter: 'agSetColumnFilter',
    valueFormatter: ({ value, node }: ValueFormatterParams) =>
      valueProvider.label(value) || (node.group ? '' : emptyCharacter),
    filterParams: {
      values: valueProvider?.values(),
      valueFormatter: ({ value }: ValueFormatterParams) => valueProvider.label(value),
      comparator: valueComparator(valueProvider),
    },
  };
  return obj;
}, {} as { [key in enumDataTypes]: ColDef });

const tagColumns = Object.keys(enumColumns)
  .filter((type) => tagDataTypes.includes(type as enumDataTypes))
  .reduce((obj, type) => {
    const valueProvider = createEnumValueProvider(type as enumDataTypes);

    obj[type] = {
      ...enumColumns[type],
      cellRenderer: ({ value }: ValueFormatterParams) => {
        if (!value) {
          return emptyCharacter;
        }
        const className = `f4eTag f4eTag--full-width f4eTag--${type}`;
        return `<span class="${className}" data-value="${value}">${valueProvider.label(value)}</span>`;
      },
    };
    return obj;
  }, {} as Partial<{ [key in enumDataTypes]: ColDef }>);

const getColumnTypes = ({ association, serviceCenter, user }: ICache): { [key in DataType]: ColDef } => ({
  ...enumColumns,
  ...tagColumns,

  [DataType.Text]: {
    filter: 'agTextColumnFilter',
    valueFormatter: ({ value, node }: ValueFormatterParams) => {
      const formattedValue = Array.isArray(value) ? value.map(({ name }) => name).join(', ') : value;

      return formattedValue || (node?.group ? '' : emptyCharacter);
    },
  },
  [DataType.Number]: {
    filter: 'agNumberColumnFilter',
    valueFormatter: ({ value, node }: ValueFormatterParams) => {
      if (isFinite(value)) {
        return value;
      }
      return node.group ? '' : emptyCharacter;
    },
  },
  [DataType.Amount]: {
    filter: 'agNumberColumnFilter',
    cellStyle: { textAlign: 'right' },
    valueFormatter: ({ value, node }: ValueFormatterParams) => {
      if (!isFinite(value) || value === null) {
        return !node.group ? emptyCharacter : '';
      }
      return formatCurrency(value);
    },
  },
  [DataType.AccountingMonth]: {
    filter: 'agNumberColumnFilter',
    valueFormatter: ({ value, node }: ValueFormatterParams) => {
      if (isFinite(value)) {
        return accountingMonthValueProvider.label(value.toString());
      }
      return node.group ? '' : emptyCharacter;
    },
  },
  [DataType.Boolean]: {
    filter: 'agSetColumnFilter',

    cellRenderer: ({ value }: ValueFormatterParams) => {
      if (value === undefined) {
        return '';
      }

      const className = classNames('f4eTag f4eTag--full-width', {
        'f4eTag--dark': value,
        'f4eTag--darker': !value,
      });
      const labelKey = value ? 'general.yes' : 'general.no';

      return `<span class="${className}">${i18next.t(labelKey)}</span>`;
    },

    valueFormatter: ({ value }: ValueFormatterParams) => {
      return value === '1' || value?.toString() === 'true'
        ? i18next.t('general.yes')
        : i18next.t('general.no');
    },
    filterParams: {
      valueFormatter: ({ value }: ValueFormatterParams) => {
        return value === '1' || value?.toString() === 'true'
          ? i18next.t('general.yes')
          : i18next.t('general.no');
      },
      values: [0, 1],
    },
  },

  [DataType.PostalCode]: {
    filter: 'agTextColumnFilter',
    valueFormatter: ({ value }: ValueFormatterParams) => {
      if (!value) {
        return emptyCharacter;
      }
      return formatPostalCode(value.toString());
    },
    floatingFilterComponent: 'maskFloatingFilter',
    floatingFilterComponentParams: {
      mask: Mask.PostalCode,
      normalize: (d) => d.replace(/[\s.,\/#!$%^&*;:{}=\-_`~()]/g, ''),
    },
  },
  [DataType.PhoneNumber]: {
    filter: 'agTextColumnFilter',
    valueFormatter: ({ value }: ValueFormatterParams) => {
      if (!value) {
        return emptyCharacter;
      }
      return formatPhoneNumber(value.toString());
    },
    floatingFilterComponent: 'maskFloatingFilter',
    floatingFilterComponentParams: {
      mask: Mask.PhoneNumber,
      normalize: (d) => d.replace(/\D/gm, ''),
    },
  },
  [DataType.Date]: {
    filter: 'agDateColumnFilter',
    valueFormatter: ({ value, node, colDef }: ValueFormatterParams) => {
      if (value) {
        return formatDate(value, colDef?.cellRendererParams?.format);
      }
      return node.group ? '' : emptyCharacter;
    },
  },

  [DataType.Gender]: {
    filter: 'agSetColumnFilter',
    valueFormatter: ({ value }: ValueFormatterParams) => (!value ? emptyCharacter : value.toUpperCase()),
    filterParams: {
      comparator: valueComparator(genderValueProvider),
      values: genderValueProvider?.values(),
      valueFormatter: ({ value }: ValueFormatterParams) => {
        if (!value) {
          return emptyCharacter;
        }
        return genderValueProvider.label(value);
      },
    },
  },

  [DataType.MembershipIssue]: {
    filter: 'agSetColumnFilter',
    valueFormatter: (params: ValueFormatterParams) => membershipIssueValueProvider.label(params.value),
    filterParams: {
      valueFormatter: (params: ValueFormatterParams) => membershipIssueValueProvider.label(params.value),
      values: membershipIssueValueProvider.values(),
    },

    cellRenderer: ({ value }: ValueFormatterParams) => {
      const issue = value;
      const title = issue !== MembershipIssue.None ? membershipIssueValueProvider.label(value) : '';

      return `<span title="${title}">${
        issue !== MembershipIssue.None
          ? '<span class="f4eIssueColumn__warning material-icons" style="transform: translateY(25%)">warning</span>'
          : emptyCharacter
      }</span>`;
    },
    cellClass: 'f4eIssueColumn',
  },
  [DataType.ServiceCenter]: getEntityColumn(serviceCenter?.valueProvider, (value: string) =>
    isFinite(+value),
  ),
  [DataType.User]: getEntityColumn(user?.valueProvider),
  [DataType.Association]: getEntityColumn(association?.valueProvider, (value: string) => isFinite(+value)),

  [DataType.LevelOfSchooling]: getEnumArrayColumn(levelOfSchoolingValueProvider),
  [DataType.OtherMemberStatus]: getEnumArrayColumn(otherMemberStatusProvider),
});

export default getColumnTypes;
