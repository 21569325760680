import { toast } from 'react-toastify';
import i18n from 'i18next';

import { IMemberChangesRequest } from '@common/models/member-changes-request';
import apiClient from '@modules/shared/api-client';
import { handleServiceException } from '@modules/shared/service-error-handler';

export function getRequest(requestId): Promise<IMemberChangesRequest> {
  return apiClient.get<IMemberChangesRequest>(`/member/changes/${requestId}`).catch(handleServiceException);
}

export function acceptRequest(requestId): Promise<void> {
  return apiClient.post<void>(`/member/changes/${requestId}`).catch((error) => {
    toast.error(i18n.t('memberChangesPage.error'));
    throw error;
  });
}

export function refuseRequest(requestId): Promise<void> {
  return apiClient.delete<void>(`/member/changes/${requestId}`).catch((error) => {
    toast.error(i18n.t('memberChangesPage.error'));
    throw error;
  });
}
