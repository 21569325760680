export enum Gender {
  Female = 'f',
  Male = 'm',
  Other = 'x',
  NoAnswer = 'n',
}

export enum Role {
  Assistant = 'assistant',
  Director = 'director',
}

export enum Schooling {
  Baccalaureate = 'baccalaureate',
  Dess = 'dess',
  Master = 'master',
  Doctorate = 'doctorate',
}

export enum Province {
  Alberta = 'ab',
  BritishColumbia = 'bc',
  Manitoba = 'mb',
  NewBrunswick = 'nb',
  Newfoundland = 'nl',
  NorthwestTerritories = 'nt',
  NovaScotia = 'ns',
  Nunavut = 'nu',
  Ontario = 'on',
  Pei = 'pe',
  Quebec = 'qc',
  Saskatchewan = 'sk',
  Yukon = 'yt',
}

export enum ActiveState {
  Active = 'active',
  Inactive = 'inactive',
  Archived = 'archived',
}

export enum MemberStatus {
  InactiveCareerChange = 'InactiveCareerChange',
  InactiveBackToTeaching = 'InactiveBackToTeaching',
  InactiveRetirement = 'InactiveRetirement',
  InactiveDead = 'InactiveDead',
  InactiveAffiliationChanged = 'InactiveAffiliationChanged',
  InactiveContributionStopped = 'InactiveContributionStopped',

  ActiveTemporary = 'ActiveTemporary',
  ActiveExtendedLeave = 'ActiveExtendedLeave',
  ActivePendingMembership = 'ActivePendingMembership',
  ActiveWithContribution = 'ActiveWithContribution',
  ActiveWithoutContribution = 'ActiveWithoutContribution',
}

export enum JobType {
  Temporary = 'temporary',
  Regular = 'regular',
}

export enum Authorization {
  Accept = 'accept',
  Refuse = 'refuse',
}

export enum AGDelegateStatus {
  Official = 'official',
  Observer = 'observer',
}

export enum OtherMemberStatus {
  AssociationPresident = 'associationPresident',
  AssociationVicePresident = 'associationVicePresident',
  WorkAdvisor = 'workAdvisor',
  ImprovementAdvisor = 'improvementAdvisor',
  RacAdvisor = 'racAdvisor',
  AssociationTreasuries = 'associationTreasuries',
  AssociationSecretary = 'associationSecretary',
  ProfessionalAdvisor = 'professionalAdvisor',
}
