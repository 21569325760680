import { AssociationExceptionCode } from '@common/constants/association-exception-code';
import { IAssociation } from '@common/models/association';
import { IHistory } from '@common/models/history';

export interface IAssociationFinancialInfo {
  id?: string;
  exceptionCode: AssociationExceptionCode;
  contributionPercent: number;
  federationPercent: number;
  sharePercent: number;
  effectiveDate: Date;
  association: IAssociation;
  history?: IHistory;
}

export const getAssociationFinancialInfoValidationSchema = (Yup) =>
  Yup.object().shape({
    effectiveDate: Yup.date().required(),
    contributionPercent: Yup.number().min(0).required(),
    federationPercent: Yup.number().min(0).required(),
  });
