import { IOption } from '@novo-electronique/react-forms';

import IValueProvider from '@common/value-provider';

export const valueProviderToOptions = (valueProvider: IValueProvider): IOption[] => {
  return valueProvider.values().map((value) => ({
    value,
    key: value,
    label: valueProvider.label(value),
  }));
};
