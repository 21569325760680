import { IFileUploaded } from '@novo-electronique/react-dropzone';
import { ThunkDispatch } from 'redux-thunk';

import { AccountingMonth } from '@common/constants/accounting-month';
import { gricsBasePath, gricsFileNameRegex } from '@common/constants/global';
import { AccessLevel, PermissionScope } from '@common/constants/permission';
import { IAssociationContributionReport, IConciliationReport } from '@common/models/contribution-report';
import { IContributionsDue } from '@common/models/contributions-due';
import { IGricsFileUploaded, IGricsImport } from '@common/models/grics-import';
import { IPayment } from '@common/models/payment';
import { IPdfConfig } from '@common/models/pdf-config';
import { IPeriod } from '@common/models/period';
import { uuid } from '@common/utils/guid';
import { runSerial } from '@common/utils/promise';
import { paymentOptionValueProvider } from '@common/value-provider';
import { addOrUpdateBackgroundTask, removeBackgroundTask } from '@modules/app/redux/action';
import { selectBackgroundTasks, selectUser } from '@modules/app/redux/selectors';
import apiClient from '@modules/shared/api-client';
import { IBackgroundTask } from '@modules/shared/background-task';
import { valueProviderToOptions } from '@modules/shared/components/Form/utils';
import { hasPermission } from '@modules/shared/permission';
import { handleServiceException } from '@modules/shared/service-error-handler';
import { BackgroundTaskType } from '@src/constants';
import { IRootState } from '@src/redux/root.state';

export const getContributionDue = (contributionsDueId: string): Promise<IContributionsDue> => {
  return apiClient
    .get<IContributionsDue>(`/contribution/due/${contributionsDueId}`)
    .catch(handleServiceException);
};

export const savePayment = (contributionsDueId: string, payment: IPayment): Promise<IContributionsDue> => {
  const method = payment.id ? 'put' : 'post';

  return apiClient[method]<IContributionsDue>(
    `/contribution/due/${contributionsDueId}/payment`,
    payment,
  ).catch(handleServiceException);
};

export const deletePayment = (contributionsDueId: string): Promise<void> => {
  return apiClient
    .delete<void>(`/contribution/due/${contributionsDueId}/payment`)
    .catch(handleServiceException);
};

export const paymentMethodOptions = () => {
  return valueProviderToOptions(paymentOptionValueProvider);
};

export const updateAccountingMonth = (contributionsDueId: string, accountingMonth: AccountingMonth) => {
  return apiClient
    .put<IContributionsDue>(`/contribution/due/${contributionsDueId}/accounting-month`, { accountingMonth })
    .catch(handleServiceException);
};

export const uploadGricsFile = (file: IFileUploaded): Promise<string> => {
  const year = +file.name.split('-')[2];
  if (!file.name.match(gricsFileNameRegex) || isNaN(year)) {
    return Promise.reject('Invalid file name.');
  }

  return apiClient
    .uploadFiles(`/attachment?destination=${gricsBasePath}/${year}`, [file])
    .then((attachments) => attachments[0].id);
};

export function removeGricsFile(file: IFileUploaded): Promise<void> {
  return apiClient.delete(`/attachment/${file.id}`);
}

export const queueGricsFiles = (files: IGricsFileUploaded[]) => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    try {
      await apiClient.post(`/grics/import`, files);
      return dispatch(updateGricsBackgroundTaskFeedback());
    } catch (e) {
      return handleServiceException(e);
    }
  };
};

export function updateGricsBackgroundTaskFeedback(
  intervalDuration = 5000,
): (dispatch: ThunkDispatch<{}, {}, any>, getState: () => IRootState) => Promise<void> {
  return async (dispatch, getState) => {
    let interval = null;
    const task: IBackgroundTask = {
      id: BackgroundTaskType.UploadingGricsFiles,
      type: BackgroundTaskType.UploadingGricsFiles,
      cancellable: hasPermission(PermissionScope.Import, AccessLevel.Full, selectUser(getState())),
    };

    const updateFeedback = async (isFirstTime = false): Promise<boolean> => {
      const backgroundTasks = selectBackgroundTasks(getState());
      const count = +(await apiClient.get<number>(`/grics/import/pending`));

      const isTaskCleared = !isFirstTime && !backgroundTasks.some(({ id }) => task.id === id);

      if (count > 0 && !isTaskCleared) {
        dispatch(addOrUpdateBackgroundTask({ ...task, params: { count } }));
        return true;
      } else {
        if (interval) {
          clearInterval(interval);
        }
        if (isTaskCleared) {
          await clearPendingImports();
        }
        dispatch(removeBackgroundTask(task.id));
        return false;
      }
    };

    const isActive = await updateFeedback(true);
    if (isActive) {
      interval = setInterval(async () => await updateFeedback(), intervalDuration);
    }
  };
}

export const clearPendingImports = () => {
  return apiClient.delete(`/grics/import`).catch(handleServiceException);
};

export const getGricsImport = (id: string) => {
  return apiClient.get<IGricsImport>(`/grics/import/${id}`).catch(handleServiceException);
};

export const downloadAllContributionReports = (
  startYear: number,
  contributions: IAssociationContributionReport[],
) => {
  const contributionsToDownload = [...contributions];

  // Not particularly efficient, but simple. The node must be cloned, because some part of the page must be hidden.
  // Also, the page can change between the beginning and the end of the operation.
  const clonedDocument = document.querySelector('html').cloneNode(true) as HTMLElement;

  const task: IBackgroundTask = {
    id: uuid(),
    type: BackgroundTaskType.DownloadAllReports,
    cancellable: true,
  };

  return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => IRootState) => {
    await runSerial(
      contributionsToDownload.map((contribution, i) => () => {
        const backgroundTasks = selectBackgroundTasks(getState());

        // Validate if the task have been cancelled.
        if (i > 0 && !backgroundTasks.some(({ id }) => task.id === id)) {
          return Promise.resolve();
        }

        dispatch(
          addOrUpdateBackgroundTask({
            ...task,
            params: { count: i + 1, total: contributionsToDownload.length },
          }),
        );

        // At the moment, some part of the page are hidden (and are not removed). This could create big request size.
        (clonedDocument.querySelectorAll('.f4eReport[data-report-id]') as NodeListOf<HTMLElement>).forEach(
          (element) => {
            const isActive = contribution && element.dataset.reportId !== contribution.number.toString();

            element.classList.add('f4eReport--with-header');
            element.style.display = isActive ? 'none' : 'block';
          },
        );
        return downloadContributionReport(startYear, contribution, clonedDocument);
      }),
    ).finally(() => {
      dispatch(removeBackgroundTask(task.id));
    });
  };
};

export const downloadContributionReport = (
  startYear: number,
  contribution?: IAssociationContributionReport,
  element: HTMLElement = document.querySelector('html') as HTMLElement,
): Promise<void> => {
  const reportPrefix = contribution ? `${contribution.number.toString().padStart(4, '0')}_` : '';
  return downloadPdfReport(`${reportPrefix}soldes_detailles_${startYear}.pdf`, element.innerHTML);
};

export const downloadCumulativeReport = (month: string, period: IPeriod): Promise<void> => {
  return downloadPdfReport(
    `conciliation_cumulative_${month}_${period.start}-${period.end}.pdf`,
    document.querySelector('html').innerHTML,
  );
};

const downloadPdfReport = (fileName: string, html: string): Promise<void> => {
  return apiClient
    .download('pdf', fileName, { html, options: { landscape: true } } as IPdfConfig)
    .catch(handleServiceException);
};

export const getAssociationContributions = (
  associationId: string,
  period: IPeriod,
  accountingMonth: AccountingMonth,
): Promise<IAssociationContributionReport | IAssociationContributionReport[]> => {
  const endPoint =
    associationId === ''
      ? `reporting/contribution/association/${period.start}${period.end}/${accountingMonth}`
      : `reporting/contribution/association/${associationId}/${period.start}${period.end}/${accountingMonth}`;

  return apiClient
    .get<IAssociationContributionReport | IAssociationContributionReport[]>(endPoint)
    .then((data) => (Array.isArray(data) ? data.filter((d) => d.serviceCenters.length > 0) : data))
    .catch(handleServiceException);
};

export const getCumulativeConciliation = (
  period: IPeriod,
  month: number,
  date: Date,
): Promise<IConciliationReport> => {
  return apiClient
    .get<IConciliationReport>(
      `reporting/contribution/conciliation/${period.start}${period.end}/${month}?date=${date.toISOString()}`,
    )
    .then((report) => {
      report.date = new Date(report.date);
      return report;
    })
    .catch(handleServiceException);
};
