import React from 'react';
import { useTranslation } from 'react-i18next';

function MembershipUpdateCompleted() {
  const { t } = useTranslation();
  return (
    <>
      <h2 className="f4eSimpleLayout__panel__content__title">{t('membershipUpdatePage.completed.title')}</h2>
      <p className="f4eSimpleLayout__panel__content__text">
        {t('membershipUpdatePage.completed.description')}
      </p>
    </>
  );
}

export default MembershipUpdateCompleted;
