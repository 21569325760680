import React from 'react';

import { AppRoutePages } from '@constants/routes';
import ContributionReportPage from './ContributionReportPage';
import CumulativeConciliationReportPage from './CumulativeConciliationReportPage';
import GricsImportPage from './GricsImportPage';
import PaymentsPage from './PaymentsPage';

export default [
  { path: AppRoutePages.Payments, component: <PaymentsPage /> },
  { path: AppRoutePages.GricsImport, component: <GricsImportPage /> },
  { path: AppRoutePages.ContributionReport, component: <ContributionReportPage /> },
  { path: AppRoutePages.CumulativeConciliationReport, component: <CumulativeConciliationReportPage /> },
];
