import { IDataQuery, IDataQueryResponse } from '@novo-electronique/react-data-table';

import apiClient from '@modules/shared/api-client';

export async function getData(source: string, request: any): Promise<IDataQueryResponse> {
  return await apiClient.post<IDataQueryResponse>(source, request);
}

export async function getCsvFile(source: string, dataQuery: IDataQuery): Promise<void> {
  return await apiClient.download(`${source}/csv`, null, dataQuery);
}

export async function getXlsxFile(source: string, dataQuery: IDataQuery): Promise<void> {
  return await apiClient.download(`${source}/xlsx`, null, dataQuery);
}
