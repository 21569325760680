import './ContributionReport.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { formatCurrency } from '@common/utils/currency';
import { accountingMonthValueProvider } from '@common/value-provider';
import { ContributionPeriods } from './constants/contribution-periods';
import { IContributionReport } from './contribution-report';
import { IContributionReportDetail } from './contribution-report-detail';
import { periodTotal } from './service';

interface IProps {
  condensed?: boolean;
  report: IContributionReport;
  noHeader?: boolean;
  noTotal?: boolean;
}

function ContributionReport({ report, condensed, noHeader, noTotal }: IProps) {
  const { title, summaryTitle, period, accountingMonth, details, contributorsCount } = report;
  const { t } = useTranslation();

  const renderAmountCell = (
    amount: number,
    {
      isTotal = false,
      isYearlyTotal = false,
      showSymbol = false,
    }: Partial<{
      isTotal: boolean;
      isYearlyTotal: boolean;
      showSymbol: boolean;
    }> = {},
  ) => {
    const className = classNames(
      'f4eContributionReport__table__cell f4eContributionReport__table__cell--amount',
      {
        'f4eContributionReport__table__cell--total': isTotal,
        'f4eContributionReport__table__cell--period-total': isYearlyTotal,
      },
    );

    return <td className={className}>{formatCurrency(amount || 0, { showSymbol })}</td>;
  };

  const renderSummary = (contributionDetails: IContributionReportDetail[]) => {
    if (noTotal || contributionDetails.length <= 1) return null; // no total if there's only one member!

    return (
      <tr>
        {!condensed && (
          <td className="f4eContributionReport__table__cell f4eContributionReport__table__cell--summary">
            {summaryTitle || ''}
          </td>
        )}

        {renderAmountCell(periodTotal(details, ContributionPeriods.Month7), { isTotal: true })}
        {renderAmountCell(periodTotal(details, ContributionPeriods.Month8), { isTotal: true })}
        {renderAmountCell(periodTotal(details, ContributionPeriods.Month9), { isTotal: true })}
        {renderAmountCell(periodTotal(details, ContributionPeriods.Month10), { isTotal: true })}
        {renderAmountCell(periodTotal(details, ContributionPeriods.Month11), { isTotal: true })}
        {renderAmountCell(periodTotal(details, ContributionPeriods.Month12), { isTotal: true })}

        <td>{/* period separator */}</td>

        {renderAmountCell(periodTotal(details, ContributionPeriods.Month1), { isTotal: true })}
        {renderAmountCell(periodTotal(details, ContributionPeriods.Month2), { isTotal: true })}
        {renderAmountCell(periodTotal(details, ContributionPeriods.Month3), { isTotal: true })}
        {renderAmountCell(periodTotal(details, ContributionPeriods.Month4), { isTotal: true })}
        {renderAmountCell(periodTotal(details, ContributionPeriods.Month5), { isTotal: true })}
        {renderAmountCell(periodTotal(details, ContributionPeriods.Month6), { isTotal: true })}

        {renderAmountCell(periodTotal(details, ContributionPeriods.YearTotal), {
          isTotal: true,
          isYearlyTotal: true,
          showSymbol: true,
        })}
      </tr>
    );
  };

  const toContributionsLine = (contributionDetail: IContributionReportDetail) => {
    return (
      <tr key={contributionDetail.id}>
        {!condensed && (
          <td className="f4eContributionReport__table__cell f4eContributionReport__table__cell--description">
            {contributionDetail.description}
          </td>
        )}

        {renderAmountCell(contributionDetail.month7)}
        {renderAmountCell(contributionDetail.month8)}
        {renderAmountCell(contributionDetail.month9)}
        {renderAmountCell(contributionDetail.month10)}
        {renderAmountCell(contributionDetail.month11)}
        {renderAmountCell(contributionDetail.month12)}

        <td className="f4eContributionReport__table__cell f4eContributionReport__table__cell--period-separator">
          {/* period separator */}
        </td>

        {renderAmountCell(contributionDetail.month1)}
        {renderAmountCell(contributionDetail.month2)}
        {renderAmountCell(contributionDetail.month3)}
        {renderAmountCell(contributionDetail.month4)}
        {renderAmountCell(contributionDetail.month5)}
        {renderAmountCell(contributionDetail.month6)}

        {renderAmountCell(contributionDetail.yearTotal, { isYearlyTotal: true, showSymbol: true })}
      </tr>
    );
  };

  const tableClassNames = classNames('f4eContributionReport__table', {
    'f4eContributionReport__table--condensed': condensed,
  });
  return (
    <article className="f4eContributionReport">
      {title && <h1 className="f4eContributionReport__title">{title}</h1>}
      {contributorsCount !== undefined && (
        <p className="f4eContributionReport__active-contributors-count">
          {t('reports.contributions.contributorsCount', {
            count: contributorsCount,
            accountingMonth: accountingMonthValueProvider.label(accountingMonth.toString()).toLowerCase(),
          })}
        </p>
      )}

      <table className={tableClassNames}>
        {!noHeader && (
          <thead className="f4eContributionReport__table__header">
            <tr>
              {!condensed && (
                <th className="f4eContributionReport__table__cell f4eContributionReport__table__cell--description">
                  &nbsp;
                </th>
              )}
              <th
                className="f4eContributionReport__table__cell f4eContributionReport__table__cell--period"
                colSpan={6}
              >
                {period.start}
              </th>
              <th
                className="f4eContributionReport__table__cell f4eContributionReport__table__cell--period-separator"
                rowSpan={2}
              >
                <div>&nbsp;</div>
              </th>
              <th
                className="f4eContributionReport__table__cell f4eContributionReport__table__cell--period"
                colSpan={6}
              >
                {period.end}
              </th>
              <th
                className="f4eContributionReport__table__cell f4eContributionReport__table__cell--period-total"
                rowSpan={2}
              >
                {t('reports.contributions.yearlyTotal')}
              </th>
            </tr>

            <tr className="f4eContributionReport__table__header">
              {!condensed && (
                <th className="f4eContributionReport__table__cell f4eContributionReport__table__cell--description" />
              )}

              <th className="f4eContributionReport__table__cell">J</th>
              <th className="f4eContributionReport__table__cell">A</th>
              <th className="f4eContributionReport__table__cell">S</th>
              <th className="f4eContributionReport__table__cell">O</th>
              <th className="f4eContributionReport__table__cell">N</th>
              <th className="f4eContributionReport__table__cell">D</th>

              {/* separator has rowspan of 2 */}

              <th className="f4eContributionReport__table__cell">J</th>
              <th className="f4eContributionReport__table__cell">F</th>
              <th className="f4eContributionReport__table__cell">M</th>
              <th className="f4eContributionReport__table__cell">A</th>
              <th className="f4eContributionReport__table__cell">M</th>
              <th className="f4eContributionReport__table__cell">J</th>

              {/* total has rowspan of 2 */}
            </tr>
          </thead>
        )}
        <tbody>
          {details.map(toContributionsLine)}
          {renderSummary(details)}
        </tbody>
      </table>
    </article>
  );
}

export default ContributionReport;
