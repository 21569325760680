import './MemberContributions.scss';

import React, { useEffect, useReducer, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { AccessLevel, PermissionScope } from '@common/constants/permission';
import { IContribution } from '@common/models/contribution';
import { IMember } from '@common/models/member';
import { formatCurrency } from '@common/utils/currency';
import { formatDate } from '@common/utils/date';
import { getLatestBudgetYear } from '@common/utils/period';
import { selectUser } from '@modules/app/redux/selectors';
import MemberContributionEditionModal from '@modules/members/components/MemberContributionEditionModal';
import PeriodSelector from '@modules/shared/components/PeriodSelector';
import Toolbar, { ToolbarActionType } from '@modules/shared/components/Toolbar';
import { hasPermission } from '@modules/shared/permission';
import { getMemberContributions } from '../services/members';
import reducer, {
  contributionsReceived,
  contributionsUpdated,
  IServiceCenterContributions,
  selectBudgetYear,
  toggleServiceCenterDetails,
} from './member-contributions-reducer';

interface IProps {
  member: IMember;
  isForcingUpdate?: boolean;
}

function MemberContributions({ member, isForcingUpdate = false }: IProps) {
  const { t } = useTranslation();
  const currentUser = useSelector(selectUser);
  const contributionRef = useRef(null);
  const [isModalVisible, setModalVisible] = useState(null);
  const [state, dispatch] = useReducer(reducer, {
    contributionsByServiceCenter: null,
    selectedServiceCenterIds: {},
    selectedBudgetYear: getLatestBudgetYear(),
  });

  const hasFullAccess = hasPermission(PermissionScope.Finance, AccessLevel.Full, currentUser);

  const effect = (action: typeof contributionsReceived | typeof contributionsUpdated) => {
    return () => {
      let isCancelled = false;
      getMemberContributions(member.id, state.selectedBudgetYear).then((contributions) => {
        if (!isCancelled) {
          dispatch(action(contributions));
        }
      });

      return () => {
        isCancelled = true;
      };
    };
  };

  useEffect(effect(contributionsReceived), [member.id, state.selectedBudgetYear]);

  useEffect(() => {
    if (isForcingUpdate) {
      return effect(contributionsReceived)();
    }
  }, [isForcingUpdate]);

  useEffect(() => {
    if (isModalVisible === false) {
      return effect(contributionsUpdated)();
    }
  }, [isModalVisible]);

  const toggleServiceCenter = (serviceCenterID: string) => {
    dispatch(toggleServiceCenterDetails(serviceCenterID));
  };

  const toServiceCenterDetailRow = (contribution: IContribution) => {
    const className = classNames({ 'f4eTable__row--selectable': hasFullAccess });
    return (
      <tr
        title={t('general.actions.edit')}
        key={contribution.id}
        className={className}
        onClick={
          (hasFullAccess &&
            (() => {
              contributionRef.current = contribution;
              setModalVisible(true);
            })) ||
          undefined
        }
      >
        <td className="f4eTable__row__cell--period">{contribution.periodNumber}</td>
        <td>{formatDate(new Date(contribution.date), 'PPP')}</td>
        <td className="f4eTable__row__cell--amount">{formatCurrency(contribution.regularContribution)}</td>
        <td className="f4eTable__row__cell--amount">{formatCurrency(contribution.socialContribution)}</td>
        <td className="f4eTable__row__cell--amount">{formatCurrency(contribution.specialContribution)}</td>
      </tr>
    );
  };

  const renderServiceCenterContributionsDetail = (
    serviceCenterContributions: IServiceCenterContributions,
  ) => {
    return (
      <table className="f4eTable">
        <thead>
          <tr>
            <th className="f4eTable__row__cell--period">{t('memberContributions.headers.period')}</th>
            <th>{t('memberContributions.headers.date')}</th>
            <th className="f4eTable__row__cell--amount">{t('memberContributions.headers.regular')}</th>
            <th className="f4eTable__row__cell--amount">{t('memberContributions.headers.social')}</th>
            <th className="f4eTable__row__cell--amount">{t('memberContributions.headers.special')}</th>
          </tr>
        </thead>
        <tbody>{serviceCenterContributions.contributions.map(toServiceCenterDetailRow)}</tbody>
      </table>
    );
  };

  const toServiceCenterHeaderRow = (serviceCenterContributions: IServiceCenterContributions) => {
    const isSelected = state.selectedServiceCenterIds[serviceCenterContributions.serviceCenterId];
    const className = classNames('f4eTable__row--selectable', { 'f4eTable__row--selected': isSelected });
    return (
      <React.Fragment key={serviceCenterContributions.serviceCenterId}>
        <tr
          className={className}
          onClick={() => {
            toggleServiceCenter(serviceCenterContributions.serviceCenterId);
          }}
        >
          <td>{serviceCenterContributions.serviceCenterLabel}</td>
          <td className="f4eTable__row__cell--amount">
            {formatCurrency(serviceCenterContributions.regularContributionTotal)}
          </td>
          <td className="f4eTable__row__cell--amount">
            {formatCurrency(serviceCenterContributions.socialContributionTotal)}
          </td>
          <td className="f4eTable__row__cell--amount">
            {formatCurrency(serviceCenterContributions.specialContributionTotal)}
          </td>
        </tr>
        {isSelected && (
          <tr className="f4eTable__row--detail">
            <td colSpan={4}>{renderServiceCenterContributionsDetail(serviceCenterContributions)}</td>
          </tr>
        )}
      </React.Fragment>
    );
  };

  const onPeriodChange = (budgetYear: string) => {
    dispatch(selectBudgetYear(budgetYear));
  };

  return (
    <section className="f4eMemberContributions">
      <header className="f4eMemberContributions__header">
        <PeriodSelector onChange={onPeriodChange} small />
        {hasFullAccess && (
          <Toolbar
            actions={{
              [ToolbarActionType.Add]: () => {
                contributionRef.current = null;
                setModalVisible(true);
              },
            }}
          />
        )}
      </header>

      <table className="f4eTable">
        <thead>
          <tr>
            <th>{t('memberContributions.headers.serviceCenter')}</th>
            <th className="f4eTable__row__cell--amount">{t('memberContributions.headers.regular')}</th>
            <th className="f4eTable__row__cell--amount">{t('memberContributions.headers.social')}</th>
            <th className="f4eTable__row__cell--amount">{t('memberContributions.headers.special')}</th>
          </tr>
        </thead>
        <tbody>
          {!state.contributionsByServiceCenter && (
            <tr>
              <td className="f4eTable__row__cell--empty-notification" colSpan={4}>
                {t('memberContributions.noContributionInformation')}
              </td>
            </tr>
          )}

          {state.contributionsByServiceCenter &&
            Object.values(state.contributionsByServiceCenter).map(toServiceCenterHeaderRow)}
        </tbody>
      </table>
      {isModalVisible && (
        <MemberContributionEditionModal
          member={member}
          contribution={contributionRef.current}
          onClose={() => setModalVisible(false)}
        />
      )}
    </section>
  );
}

export default MemberContributions;
