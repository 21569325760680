import React, { ReactNode, ReactNodeArray, useState } from 'react';

interface IProps {
  children: ReactNode | ReactNodeArray;
  onChange?: (value: string | number) => void;
}

export const AccordionContext = React.createContext({ value: null, onChange: null });

function Accordion({ children, onChange }: IProps) {
  const [value, setValue] = useState<string | number>(null);

  const onChangeHandler = (valueToSet: string | number) => {
    valueToSet = value === valueToSet ? null : valueToSet;

    setValue(valueToSet);
    onChange && onChange(valueToSet);
  };

  return (
    <AccordionContext.Provider value={{ value, onChange: onChangeHandler }}>
      <div className="f4eAccordion">{children}</div>
    </AccordionContext.Provider>
  );
}

export default Accordion;
