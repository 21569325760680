import i18n from 'i18next';

import { IEntityValueLabels } from './entity-value-labels';
import IValueProvider from './value-provider';

const checkIfIsStringEnum = (values: any[]): boolean => values.every((value) => isNaN(+value));

export default class EnumValueProvider implements IValueProvider {
  protected valueLabels: IEntityValueLabels;

  constructor(
    private readonly enumeration,
    private readonly localizationKey: string,
    private readonly excludedValues = [],
  ) {}

  private populateValueLabels = () => {
    if (this.valueLabels) return;
    this.valueLabels = enumToValueLabelMap(this.enumeration, this.localizationKey);
  };

  public label(value: string): string {
    this.populateValueLabels();
    return this.valueLabels[value] || value;
  }

  public values(): string[] {
    const values = Object.values(this.enumeration);
    const isStringEnum = checkIfIsStringEnum(values);

    return values.filter(
      (value) => !this.excludedValues.includes(value) && (isStringEnum || isFinite(+value)),
    ) as string[];
  }
}

export const enumToValueLabelMap = (
  enumeration: any,
  localizationKey: string,
  excludedValues = [],
): { [key: string]: string } => {
  const keys = Object.keys(enumeration);
  const isStringEnum = checkIfIsStringEnum(keys);

  return keys
    .filter((key) => isStringEnum || isFinite(+key))
    .reduce((acc: IEntityValueLabels, key) => {
      const value = isStringEnum ? enumeration[key].toString() : key.toString();

      if (!excludedValues.includes(value)) {
        acc[value] = i18n.t(localizationKey, { context: value });
      }
      return acc;
    }, {});
};
