import './SignInMicrosoftButton.scss';

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FormDataContext } from '@novo-electronique/react-forms';

import { AuthProvider } from '@common/constants/auth-provider';
import { IAuthAccount } from '@common/models/auth-account';

interface IProps {
  onClick: (values: IAuthAccount, setErrors) => void;
}

export default function SignInMicrosoftButton({ onClick }: IProps) {
  const { t } = useTranslation();
  const formContext = useContext(FormDataContext);

  return (
    <button
      type="button"
      className="f4eSignInMicrosoftButton"
      onClick={() =>
        onClick(
          {
            ...(formContext.formik.values as IAuthAccount),
            provider: AuthProvider.Microsoft,
          },
          formContext.formik.setErrors,
        )
      }
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
        <title>MS-SymbolLockup</title>
        <rect x="1" y="1" width="9" height="9" fill="#f25022" />
        <rect x="1" y="11" width="9" height="9" fill="#00a4ef" />
        <rect x="11" y="1" width="9" height="9" fill="#7fba00" />
        <rect x="11" y="11" width="9" height="9" fill="#ffb900" />
      </svg>

      <span>{t('signInMicrosoftButton.buttonLabel')}</span>
    </button>
  );
}
