import React from 'react';

import { IServiceCenterContributionReport } from '@common/models/contribution-report';
import ServiceCenterContributions from './ServiceCenterContributions';

interface IProps {
  serviceCenters: IServiceCenterContributionReport[];
}

function ServiceCentersContributions({ serviceCenters }: IProps) {
  const toServiceCenterContributionsReport = (
    serviceCenterContributions: IServiceCenterContributionReport,
  ) => (
    <ServiceCenterContributions
      key={serviceCenterContributions.name}
      serviceCenterContributions={serviceCenterContributions}
    />
  );

  return <>{serviceCenters.map(toServiceCenterContributionsReport)}</>;
}

export default ServiceCentersContributions;
