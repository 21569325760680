import { IOption } from '@novo-electronique/react-forms';
import { ThunkDispatch } from 'redux-thunk';

import { IAssociation } from '@common/models/association';
import { IAssociationFinancialInfo } from '@common/models/association-financial-info';
import { IEntityValue } from '@common/value-provider/entity-value';
import { CachedEntity } from '@modules/app/redux/state';
import { refreshCache } from '@modules/app/service';
import apiClient from '@modules/shared/api-client';
import { handleServiceException } from '@modules/shared/service-error-handler';

export function getAssociations(): Promise<IAssociation[]> {
  return apiClient
    .get<IAssociation[]>('/association/all')
    .then((associations: IAssociation[]) => associations)
    .catch(() => {
      return [];
    });
}

export function saveAssociation(
  association: IAssociation,
): (dispatch: ThunkDispatch<{}, {}, any>) => Promise<void> {
  return (dispatch) => {
    return apiClient
      .post('/association', association)
      .then(() => dispatch(refreshCache([CachedEntity.Association], true)))
      .catch(handleServiceException);
  };
}

export function getAssociation(id: string): Promise<IAssociation> {
  return apiClient.get<IAssociation>(`/association/${id}`).catch(handleServiceException);
}

export function getAssociationFinancialInformation(id: string): Promise<IAssociationFinancialInfo[]> {
  return apiClient
    .get<IAssociationFinancialInfo[]>(`/association/${id}/financial-information`)
    .catch(handleServiceException);
}

export function addAssociationFinancialInformation(
  id: string,
  financialInformation: Partial<IAssociationFinancialInfo>,
): Promise<IAssociationFinancialInfo> {
  return apiClient
    .post<IAssociationFinancialInfo>(`/association/${id}/financial-information`, financialInformation)
    .catch(handleServiceException);
}

export function deleteAssociationFinancialInformation(id: string, financialInformationId: string) {
  return apiClient
    .delete<void>(`/association/${id}/financial-information/${financialInformationId}`)
    .catch(handleServiceException);
}

export function formatAssociation(
  association: IAssociation,
  key: keyof IAssociation = 'id',
): IEntityValue | IOption {
  return {
    value: association[key],
    label: `${association.number} – ${association.name}`,
  };
}

export function toOptions(associations: IAssociation[]): IOption[] {
  return associations.map((association) => formatAssociation(association));
}
