import './GricsImportForm.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, FormActionButton, FormActionButtonType, FormContext } from '@novo-electronique/react-forms';
import {
  SidePanel,
  SidePanelContent,
  SidePanelFooter,
  SidePanelHeader,
} from '@novo-electronique/react-side-panel';
import * as Yup from 'yup';

import { gricsFilesToUploadMaxCount } from '@common/constants/global';
import { getGricsFilesUploadedValidationSchema } from '@common/models/grics-import';
import GricsFilesField from '@modules/shared/components/GricsFilesField';
import { useThunkDispatch } from '@src/redux/useThunkDispatch';
import { queueGricsFiles, removeGricsFile, uploadGricsFile } from '../service';

interface IProps {
  closeHandler: (filesUploaded?: boolean) => void;
}

function GricsImportForm({ closeHandler }: IProps) {
  const { t } = useTranslation();
  const dispatch = useThunkDispatch();

  const onSubmit = ({ files }) => {
    return dispatch(queueGricsFiles(files)).then(() => {
      closeHandler(true);
    });
  };

  const validationSchema = Yup.object({ files: getGricsFilesUploadedValidationSchema(Yup) });
  return (
    <div className="f4eImportForm">
      <SidePanel closeHandler={closeHandler}>
        <SidePanelHeader displayCloseButton={true}>{t('importForm.title')}</SidePanelHeader>
        <FormContext initialValues={{ files: [] }} validationSchema={validationSchema} onSubmit={onSubmit}>
          <Form>
            <SidePanelContent>
              <GricsFilesField
                name="files"
                maxFiles={gricsFilesToUploadMaxCount}
                upload={uploadGricsFile}
                remove={removeGricsFile}
                width={100}
              />
            </SidePanelContent>

            <SidePanelFooter>
              <FormActionButton type={FormActionButtonType.Submit} small>
                {t('importForm.import')}
              </FormActionButton>
            </SidePanelFooter>
          </Form>
        </FormContext>
      </SidePanel>
    </div>
  );
}

export default GricsImportForm;
