import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, ButtonSize, ButtonStyle } from '@novo-electronique/react-button';
import {
  Form,
  FormActionButton,
  FormActionButtonType,
  FormAmountField,
  FormContext,
  FormDateField,
  FormFields,
  FormSelectField,
  FormSelectFieldOptions,
  FormTextField,
  IFormErrors,
} from '@novo-electronique/react-forms';
import {
  SidePanelContent,
  SidePanelFooter,
  SidePanelFooterActions,
  SidePanelHeader,
} from '@novo-electronique/react-side-panel';
import * as Yup from 'yup';

import { AccountingMonth } from '@common/constants/accounting-month';
import { IContributionsDue } from '@common/models/contributions-due';
import { getPaymentValidationSchema, IPayment } from '@common/models/payment';
import { accountingMonthValueProvider } from '@common/value-provider';
import { setAppIsLoading } from '@modules/app/redux/action';
import {
  selectAssociationValueProvider,
  selectServiceCenters,
  selectServiceCenterValueProvider,
} from '@modules/app/redux/selectors';
import DuePeriodFormModal from '@modules/finances/components/DuePeriodFormModal';
import { IconType } from '@modules/shared/components/Icon/Icon';
import SidePanel from '@modules/shared/components/SidePanel';
import Toolbar, { ToolbarActionType } from '@modules/shared/components/Toolbar';
import { useThunkDispatch } from '@src/redux/useThunkDispatch';
import { getContributionDue, paymentMethodOptions } from '../service';

interface IProps {
  contributionsDueId: string;
  readonly: boolean;
  submitHandler: (values: IContributionsDue, setErrors?: (errors: IFormErrors) => void) => Promise<void>;
  cancelHandler: () => void;
  deleteHandler: (contributionDue: IContributionsDue) => void;
  refreshHandler: () => void;
}

function PaymentEditionForm({
  contributionsDueId,
  readonly,
  submitHandler,
  cancelHandler,
  deleteHandler,
  refreshHandler,
}: IProps) {
  const { t } = useTranslation();
  const dispatch = useThunkDispatch();
  const serviceCenters = useSelector(selectServiceCenters);
  const serviceCenterValueProvider = useSelector(selectServiceCenterValueProvider);
  const associationValueProvider = useSelector(selectAssociationValueProvider);

  const [contributionsDue, setContributionsDue] = useState<IContributionsDue>(null);
  const [isShowingEditionModal, setIsShowingEditionModal] = useState(false);
  const [currentAccountingMonth, setCurrentAccountingMonth] = useState<AccountingMonth>();

  useEffect(() => {
    dispatch(setAppIsLoading(true));
    getContributionDue(contributionsDueId)
      .then((contributionsDue) => {
        setCurrentAccountingMonth(contributionsDue.accountingMonth);
        setContributionsDue(contributionsDue);
      })
      .finally(() => dispatch(setAppIsLoading(false)));
  }, [contributionsDueId]);

  const deleteContributionPayment = () => {
    if (confirm(t('paymentsPage.confirm'))) {
      deleteHandler(contributionsDue);
    }
  };

  const onSubmit = (values: IPayment): Promise<void> => {
    contributionsDue.payment = values;
    return submitHandler(contributionsDue);
  };

  if (!contributionsDue) {
    return null;
  }

  const hasPayment = Boolean(contributionsDue.payment);
  const initialValues =
    contributionsDue.payment ||
    ({
      amount: contributionsDue.contributionsAmount || 0,
      method: null,
      transactionNumber: '',
      date: null,
    } as IPayment);

  const serviceCenter = serviceCenters.find(
    (serviceCenter) => serviceCenter.id === contributionsDue.serviceCenter.id,
  );
  return (
    <>
      <FormContext
        initialValues={initialValues}
        validationSchema={getPaymentValidationSchema(Yup)}
        onSubmit={onSubmit}
      >
        <Form readonly={readonly}>
          <SidePanel closeHandler={cancelHandler}>
            <SidePanelHeader
              subTitle={`${associationValueProvider.label(
                serviceCenter.association.id,
              )} • ${accountingMonthValueProvider.label(currentAccountingMonth.toString()).toUpperCase()}`}
              displayCloseButton={true}
            >
              {serviceCenterValueProvider.label(serviceCenter.id)} : {t('paymentsPage.period')}
              {'\u00a0'}
              {contributionsDue.periodNumber}
              {!readonly && (
                <Toolbar
                  actions={{
                    [ToolbarActionType.Edit]: {
                      action: () => setIsShowingEditionModal(true),
                      label: t('paymentsPage.period'),
                      icon: IconType.EditCalendar,
                    },
                  }}
                />
              )}
            </SidePanelHeader>

            <SidePanelContent>
              <FormFields>
                <FormDateField name="date" label={t('models.payment.date')} />
                <FormSelectField name="method" label={t('models.payment.method')}>
                  <FormSelectFieldOptions options={paymentMethodOptions()} />
                </FormSelectField>
                <FormTextField name="transactionNumber" label={t('models.payment.transactionNumber')} />
                <FormAmountField name="amount" label={t('models.payment.amount')} />
              </FormFields>
            </SidePanelContent>

            {!readonly && (
              <SidePanelFooter>
                <SidePanelFooterActions>
                  <FormActionButton type={FormActionButtonType.Submit} small={true}>
                    {t('general.forms.save')}
                  </FormActionButton>
                  {hasPayment && (
                    <Button
                      size={ButtonSize.Small}
                      style={ButtonStyle.Danger}
                      onClick={deleteContributionPayment}
                    >
                      {t('general.actions.delete')}
                    </Button>
                  )}
                </SidePanelFooterActions>
                <SidePanelFooterActions isSecondary={true}>
                  <FormActionButton type={FormActionButtonType.Reset} small={true} />
                </SidePanelFooterActions>
              </SidePanelFooter>
            )}
          </SidePanel>
        </Form>
      </FormContext>
      {isShowingEditionModal && (
        <DuePeriodFormModal
          due={{ ...contributionsDue, accountingMonth: currentAccountingMonth }}
          onChange={({ accountingMonth }) => {
            setCurrentAccountingMonth(accountingMonth);
            refreshHandler();
          }}
          onClose={() => setIsShowingEditionModal(false)}
        />
      )}
    </>
  );
}

export default PaymentEditionForm;
