export function flat<T>(arrays: T[][]): T[] {
  return [].concat(...arrays);
}

export function chunk<T>(array: T[], chunkSize: number): T[][] {
  if (chunkSize <= 0) {
    throw new RangeError('Invalid chunk size specified');
  }
  return Array(Math.ceil(array.length / chunkSize))
    .fill(undefined)
    .map((_, i) => array.slice(i * chunkSize, i * chunkSize + chunkSize));
}

export function merge<T extends { id?: string }>(list1: T[], list2: T[]): T[] {
  const map = {};
  [...list1, ...list2].forEach((element) => {
    if (!map[element.id]) {
      map[element.id] = element;
    }
  });
  return Object.values(map);
}

export function range(length: number): number[] {
  return Array.from({ length }, (x, i) => i);
}

export function union<T>(a: T[], b: T[]): T[] {
  return Array.from(new Set([...a, ...b]));
}

export function intersection<T>(a: T[], b: T[]): T[] {
  return a.filter((value) => b.includes(value));
}

export function distinct<T>(array: T[], keys: (keyof T)[] = []): T[] {
  const valuesMap: { [key: string]: T } = {};

  array.forEach((value) => {
    const key = keys.length === 0 ? value.toString() : keys.map((key) => value[key].toString()).join('-');
    if (!valuesMap[key]) {
      valuesMap[key] = value;
    }
  });

  return Object.values(valuesMap);
}
