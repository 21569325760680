import './SignInForm.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Form,
  FormActionButton,
  FormActionButtonStyle,
  FormActionButtonType,
  FormContext,
  FormPasswordField,
  FormTextField,
} from '@novo-electronique/react-forms';
import * as Yup from 'yup';

import { AuthProvider } from '@common/constants/auth-provider';
import { IAuthAccount } from '@common/models/auth-account';
import { AppRoutePages } from '@constants/routes';
import { selectIsAppLoading } from '@modules/app/redux/selectors';
import SignInMicrosoftButton from '@modules/auth/components/SignInMicrosoftButton';
import { signIn, signInMicrosoft, validateIdentity } from '@modules/auth/service';
import { useThunkDispatch } from '@src/redux/useThunkDispatch';
import { resetIdentity } from '../redux/action';
import { getIdentity } from '../redux/selectors';

export default function SignInForm() {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useThunkDispatch();
  const identity = useSelector(getIdentity);
  const isLoading = useSelector(selectIsAppLoading);

  const isIdentityValidated = !!(identity && identity.email);
  const initialValues = { email: '', password: '', provider: AuthProvider.EmailPassword };

  const getSignInSchema = () =>
    Yup.object().shape({
      email: Yup.string().email().required(),
      password: isIdentityValidated ? Yup.string().required() : Yup.string(),
    });

  const submitIdentityForm = (values, setErrors): Promise<void> => {
    return dispatch(validateIdentity(values.email)).catch(setErrors);
  };

  const submitLoginForm = (values, setErrors): Promise<void> => {
    return dispatch(signIn(values.email, values.password))
      .then(() => history.push(AppRoutePages.Home))
      .catch(setErrors);
  };

  const loginWithMicrosoft = async (values: IAuthAccount, setErrors) => {
    dispatch(signInMicrosoft(values.email))
      .then(() => history.push(AppRoutePages.Home))
      .catch(setErrors);
  };

  const backButtonClicked = () => dispatch(resetIdentity());

  return (
    <div className="f4eSignInForm">
      <FormContext
        initialValues={initialValues}
        validationSchema={getSignInSchema()}
        onSubmit={isIdentityValidated ? submitLoginForm : submitIdentityForm}
      >
        <Form>
          {/* Email */}
          <FormTextField
            name="email"
            type="email"
            label={isIdentityValidated ? '' : t('signInPage.email')}
            disabled={isIdentityValidated}
            width={100}
            autoFocus={true}
          />

          {/* Password */}
          {isIdentityValidated && identity.provider === AuthProvider.EmailPassword && (
            <FormPasswordField
              label={t('signInPage.password')}
              name="password"
              width={100}
              autoFocus={true}
            />
          )}

          {/* Sign in with Microsoft */}
          {isIdentityValidated && identity.provider === AuthProvider.Microsoft && (
            <SignInMicrosoftButton onClick={loginWithMicrosoft} />
          )}

          {/* Footer */}
          <footer className="f4eSignInForm__footer">
            <div className="f4eSignInForm__footer__col">
              {/* Submit button */}
              {identity.provider !== AuthProvider.Microsoft && (
                <FormActionButton
                  type={FormActionButtonType.Submit}
                  suppressErrorTooltip={true}
                  disabled={(formik) =>
                    isLoading ||
                    !formik.isValid ||
                    (!isIdentityValidated && !Object.values(formik.values).some((d) => d)) ||
                    (isIdentityValidated && !Object.values(formik.values).every((d) => d))
                  }
                >
                  {t('signInPage.next')}
                </FormActionButton>
              )}
            </div>

            {isIdentityValidated && (
              <div className="f4eSignInForm__footer__col f4eSignInForm__footer__col--back">
                <FormActionButton
                  type={FormActionButtonType.Reset}
                  style={FormActionButtonStyle.Link}
                  callback={() => backButtonClicked()}
                >
                  <span className="material-icons">arrow_back_ios</span>
                  {t('signInPage.back')}
                </FormActionButton>
              </div>
            )}
          </footer>
        </Form>
      </FormContext>
    </div>
  );
}
