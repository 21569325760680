import React from 'react';

interface IProps {
  title: string;
  children?: any;
}

function LayoutHeader({ title, children }: IProps) {
  return (
    <header className="f4eLayout__header">
      <h1 className="f4eLayout__header__title">{title}</h1>
      {children && <div className="f4eLayout__header__secondary">{children}</div>}
    </header>
  );
}

export default LayoutHeader;
