import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Form,
  FormActionButton,
  FormActionButtonType,
  FormCheckboxField,
  FormContext,
  FormEmailField,
  FormFields,
  FormSelectField,
  FormSelectFieldOptions,
  FormTextField,
  IFormErrors,
} from '@novo-electronique/react-forms';
import {
  SidePanelContent,
  SidePanelFooter,
  SidePanelFooterActions,
  SidePanelHeader,
  SidePanelHeaderNav,
} from '@novo-electronique/react-side-panel';
import { Tab, TabContext, TabList, TabPanel } from '@novo-electronique/react-tabs';
import * as Yup from 'yup';

import { getUserInitialValues, getUserValidationSchema, IUser } from '@common/models/user';
import { accessLevelValueProvider, permissionScopeValueProvider } from '@common/value-provider';
import { setAppIsLoading } from '@modules/app/redux/action';
import { selectUser as getCurrentUser } from '@modules/app/redux/selectors';
import { DataTableSource } from '@modules/data-table/data-table-source';
import History from '@modules/history/History';
import SidePanel from '@modules/shared/components/SidePanel';
import { getUser, roleOptions } from '../service';

interface IProps {
  userId: string;
  readonly: boolean;
  submitHandler: (values: IUser, setErrors?: (errors: IFormErrors) => void) => Promise<void>;
  cancelHandler: () => void;
}

enum HeaderTabs {
  Infos = 'infos',
  Permissions = 'permission',
  History = 'history',
}

function UserEditionForm({ userId, readonly, submitHandler, cancelHandler }: IProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);

  const [initialValues, setInitialValues] = useState<IUser>(null);
  const [selectedTab, setSelectedTab] = useState<HeaderTabs>(HeaderTabs.Infos);

  useEffect(() => {
    if (userId) {
      dispatch(setAppIsLoading(true));
      getUser(userId)
        .then((user) => {
          setInitialValues(user);
        })
        .finally(() => dispatch(setAppIsLoading(false)));
    } else {
      setInitialValues(getUserInitialValues());
    }
  }, [userId]);

  if (!initialValues) {
    return null;
  }

  const onRoleChange = (
    value: string,
    setFieldValue: (field: string, value: any, shouldValidate: boolean) => void,
  ) => {
    setFieldValue('role', value, false);
  };

  const isCurrentUser = currentUser && currentUser.id === userId;
  return (
    <FormContext
      initialValues={initialValues}
      validationSchema={getUserValidationSchema(Yup)}
      onSubmit={submitHandler}
    >
      <SidePanel closeHandler={cancelHandler}>
        <TabContext onChange={(value) => setSelectedTab(value as HeaderTabs)}>
          <SidePanelHeader displayCloseButton={true}>
            {userId ? `${initialValues.firstName} ${initialValues.lastName}` : t('usersPage.addUser')}
          </SidePanelHeader>
          {userId && (
            <SidePanelHeaderNav isHost>
              <TabList>
                <Tab value={HeaderTabs.Infos} active>
                  {t('usersPage.tabs.infos')}
                </Tab>
                {initialValues?.permissions && (
                  <Tab value={HeaderTabs.Permissions}>{t('usersPage.tabs.permissions')}</Tab>
                )}
                <Tab value={HeaderTabs.History}>{t('usersPage.tabs.history')}</Tab>
              </TabList>
            </SidePanelHeaderNav>
          )}

          <Form readonly={readonly}>
            <SidePanelContent>
              <TabPanel value={HeaderTabs.Infos} visible={!userId}>
                <FormFields>
                  <FormTextField name="firstName" label={t('models.user.firstName')} />
                  <FormTextField name="lastName" label={t('models.user.lastName')} />
                  <FormEmailField domain="fqde.qc.ca" name="email" label={t('models.user.email')} />
                  <FormSelectField
                    name="role"
                    label={t('models.user.role')}
                    onChange={onRoleChange}
                    disabled={isCurrentUser}
                  >
                    <FormSelectFieldOptions addEmptyOption={true} options={roleOptions()} />
                  </FormSelectField>
                  <FormCheckboxField
                    name="enabled"
                    label={t('models.user.enabled')}
                    disabled={isCurrentUser}
                  />
                </FormFields>
              </TabPanel>

              {userId && initialValues?.permissions && (
                <TabPanel value={HeaderTabs.Permissions}>
                  <dl className="f4eDefinitions">
                    {Object.keys(initialValues.permissions).map((scope) => (
                      <React.Fragment key={scope}>
                        <dt className="f4eDefinitions__part f4eDefinitions__part--term">
                          {permissionScopeValueProvider.label(scope)}
                        </dt>
                        <dd className="f4eDefinitions__part">
                          {accessLevelValueProvider.label(initialValues.permissions[scope])}
                        </dd>
                      </React.Fragment>
                    ))}
                  </dl>
                </TabPanel>
              )}

              {userId && (
                <TabPanel value={HeaderTabs.History}>
                  <History
                    id={initialValues.history?.id}
                    datasource={DataTableSource.Users}
                    hidden={selectedTab !== HeaderTabs.History}
                  />
                </TabPanel>
              )}
            </SidePanelContent>

            {!readonly && selectedTab === HeaderTabs.Infos && (
              <SidePanelFooter>
                <SidePanelFooterActions>
                  <FormActionButton type={FormActionButtonType.Submit} small={true}>
                    {t('general.forms.save')}
                  </FormActionButton>
                </SidePanelFooterActions>
                <FormActionButton type={FormActionButtonType.Reset} small={true} />
              </SidePanelFooter>
            )}
          </Form>
        </TabContext>
      </SidePanel>
    </FormContext>
  );
}

export default UserEditionForm;
