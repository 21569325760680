import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ChangesRecordAction } from '@common/constants/changes-record-action';
import { IChange } from '@common/models/change';
import { IChangesRecord } from '@common/models/changes-record';
import { formatDate } from '@common/utils/date';
import { selectCache } from '@modules/app/redux/selectors';
import { DataTableSource } from '@modules/data-table/data-table-source';
import { formatValues, ValueFormatter } from '@modules/shared/value-formatter';

interface IProps {
  datasource: DataTableSource;
  records: IChangesRecord[];

  label?: string;
  excludedProperties?: string[];
}

function ChangeRecords({ datasource, records, label, excludedProperties = [] }: IProps) {
  const { t } = useTranslation();
  const cache = useSelector(selectCache);

  const entityLabel = label || t('history.defaultEntityLabel');
  const localeNamespace = getLocaleNamespaceFromDatasource(datasource);

  const valueFormatter = new ValueFormatter(datasource, cache);

  const localizeProperty = (change: IChange<string, any>) => {
    if (!localeNamespace) return change.property;
    const localeKey = `${localeNamespace}.${change.property.toString()}`;
    const localizedName = t(localeKey);

    return localizedName === localeKey ? change.property : localizedName;
  };

  const renderByAuthor = (author: string) => {
    return t('history.byAuthor', { context: author });
  };

  const toEditChangeComponent = (change: IChange<string, any>) => {
    const changeFormattedValues = formatValues(valueFormatter, change);
    const type = changeFormattedValues.current ? 'whatWasChanged' : 'whatWasCreated';
    const isNewValueDefined = Boolean(changeFormattedValues.new || '');

    return (
      <p key={change.property.toString()}>
        <Trans
          i18nKey={`history.${type}`}
          values={{
            context: change.property,
            property: localizeProperty(change),
            current: changeFormattedValues.current || '',
            new: changeFormattedValues.new || '',
            count: isNewValueDefined ? Number.MAX_SAFE_INTEGER : 0, // Use to detect if the new value is defined.
          }}
        >
          <strong />
        </Trans>
      </p>
    );
  };

  const renderChanges = (record: IChangesRecord) => {
    switch (record.action) {
      case ChangesRecordAction.Creation:
        return t('history.entityCreated', { entity: entityLabel });
      case ChangesRecordAction.Edition:
        return record.changes
          .filter((change) => !excludedProperties.includes(change.property))
          .map(toEditChangeComponent);
    }
  };

  const toChangesRecordComponent = (record: IChangesRecord) => (
    <li className="f4eHistory__record" key={record.id}>
      <p className="f4eHistory__record__by">
        <span className="f4eHistory__record__by__date">
          {formatDate(record.date, 'yyyy-MM-dd — HH:mm:SS')}
        </span>{' '}
        <span className="f4eHistory__record__by__author">{renderByAuthor(record.author)}</span>
      </p>
      <div className="f4eHistory__record__changes">{renderChanges(record)}</div>
    </li>
  );

  return <ol className="f4eHistory">{records.map(toChangesRecordComponent)}</ol>;
}

function getLocaleNamespaceFromDatasource(datasource: DataTableSource): string {
  switch (datasource) {
    case DataTableSource.Members:
      return 'models.member';
    case DataTableSource.Memberships:
      return 'models.membership';
    case DataTableSource.Users:
      return 'models.user';
    case DataTableSource.Associations:
      return 'models.association';
    case DataTableSource.Schools:
      return 'models.school';
    case DataTableSource.ServiceCenters:
      return 'models.serviceCenter';
    default:
      return '';
  }
}

export default ChangeRecords;
