import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Form,
  FormActionButton,
  FormActionButtonType,
  FormContext,
  FormDateField,
  FormFields,
  FormSelectField,
  FormSelectFieldOptions,
  IOption,
} from '@novo-electronique/react-forms';
import * as Yup from 'yup';

import { periodNumbersCount } from '@common/constants/global';
import { IContributionsDue } from '@common/models/contributions-due';
import { range } from '@common/utils/array';
import { accountingMonthValueProvider } from '@common/value-provider';
import { updateAccountingMonth } from '@modules/finances/service';
import { valueProviderToOptions } from '@modules/shared/components/Form/utils';
import { FormModal, ModalSize } from '@modules/shared/components/Modal';

interface IProps {
  due: IContributionsDue;
  onChange?: (due: IContributionsDue) => void;
  onClose: () => void;
}

function DuePeriodFormModal({ due, onChange, onClose }: IProps) {
  const { t } = useTranslation();
  const formikRef = useRef(null);

  const periodOptions = range(periodNumbersCount).map((d) => {
    const value = (d + 1).toString();
    return {
      value,
      label: value,
    };
  }) as IOption[];

  const validationSchema = Yup.object({
    date: Yup.date().required(),
    periodNumber: Yup.number().required(),
    accountingMonth: Yup.number().required(),
  });

  const onSubmit = ({ accountingMonth }) => {
    return updateAccountingMonth(due.id, accountingMonth)
      .then((due) => onChange && onChange(due))
      .finally(() => onClose());
  };

  return (
    <FormModal
      title={t('paymentsPage.periodEdition')}
      size={ModalSize.Small}
      onClose={onClose}
      formikRef={formikRef}
    >
      <FormContext
        validationSchema={validationSchema}
        initialValues={due}
        onSubmit={onSubmit}
        formikRef={formikRef}
      >
        <Form>
          <FormFields>
            <FormDateField name="date" label={t('models.contributionDue.date')} width={33} disabled />
            <FormSelectField
              name="periodNumber"
              label={t('models.contributionDue.periodNumber')}
              width={33}
              disabled
            >
              <FormSelectFieldOptions options={periodOptions} keepOriginalOrder />
            </FormSelectField>
            <FormSelectField
              name="accountingMonth"
              label={t('models.contributionDue.accountingMonth')}
              tooltip={t('models.contributionDue.accountingMonthTooltip')}
              width={33}
            >
              <FormSelectFieldOptions
                options={valueProviderToOptions(accountingMonthValueProvider)}
                addEmptyOption={false}
                keepOriginalOrder
              />
            </FormSelectField>
          </FormFields>
          <FormActionButton type={FormActionButtonType.Submit} small>
            {t('general.actions.edit')}
          </FormActionButton>
        </Form>
      </FormContext>
    </FormModal>
  );
}

export default DuePeriodFormModal;
