import './MemberChangesPage.scss';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Button, ButtonStyle } from '@novo-electronique/react-button';

import { MemberChangesStatus } from '@common/constants/member-changes-status';
import { IMemberChangesRequest } from '@common/models/member-changes-request';
import { MemberChangesList } from '@modules/changes/components/MemberChangesList';
import { acceptRequest, getRequest, refuseRequest } from '@modules/changes/service';
import Initializing from '@modules/shared/components/Initializing';
import { SimpleLayout } from '@modules/shared/components/Layout';

enum MemberChangesPageStates {
  Loading = 'Loading',
  Default = 'Default',
  Accepted = 'accepted',
  Refused = 'refused',
  Processed = 'Processed',
}

export default function MemberChangesPage() {
  const { t } = useTranslation();
  const [request, setRequest] = useState<IMemberChangesRequest>(undefined);
  const [pageState, setPageState] = useState<MemberChangesPageStates>(MemberChangesPageStates.Loading);
  const [isAcceptingRequest, setIsAcceptingRequest] = useState<boolean>(false);
  const [isRefusingRequest, setIsRefusingRequest] = useState<boolean>(false);

  const queryParams = new URLSearchParams(useLocation().search);
  useEffect(() => {
    if (queryParams.has('id')) {
      getRequest(queryParams.get('id')).then((request) => {
        if (request.status === MemberChangesStatus.Pending) {
          setRequest(request);
          setPageState(MemberChangesPageStates.Default);
        } else {
          setPageState(MemberChangesPageStates.Processed);
        }
      });
    }
  }, []);

  const accept = (): Promise<void> => {
    setIsAcceptingRequest(true);
    return acceptRequest(request.id)
      .then(() => setPageState(MemberChangesPageStates.Accepted))
      .finally(() => setIsAcceptingRequest(false));
  };

  const refuse = (): Promise<void> => {
    setIsRefusingRequest(true);
    return refuseRequest(request.id)
      .then(() => setPageState(MemberChangesPageStates.Refused))
      .finally(() => setIsRefusingRequest(false));
  };

  const renderDefault = () => {
    return (
      <div className="f4eMemberChanges">
        <h2 className="f4eSimpleLayout__panel__content__title">{t('memberChangesPage.title')}</h2>
        <MemberChangesList changes={request.changes} />
        <div className="f4eMemberChanges__action-group">
          <p className="f4eMemberChanges__action-group__message">{t('memberChangesPage.confirmMessage')}</p>
          <Button
            style={ButtonStyle.Primary}
            onClick={accept}
            isLoading={isAcceptingRequest}
            disabled={isRefusingRequest}
          >
            {t('memberChangesPage.confirmAction')}
          </Button>
        </div>
        <div className="f4eMemberChanges__action-group">
          <p className="f4eMemberChanges__action-group__message">{t('memberChangesPage.refuseMessage')}</p>
          <Button
            style={ButtonStyle.Primary}
            onClick={refuse}
            isLoading={isRefusingRequest}
            disabled={isAcceptingRequest}
          >
            {t('memberChangesPage.refuseAction')}
          </Button>
        </div>
        <address className="f4eMemberChanges__contact">
          <a
            className="f4eMemberChanges__contact__link f4eMemberChanges__contact__link--email"
            href="mailto:membres@fqde.qc.ca"
          >
            membres@fqde.qc.ca
          </a>
          <br />
          <a className="f4eMemberChanges__contact__link" href="tel:+5143537511">
            514&nbsp;353-7511
          </a>
          <br />
          <a className="f4eMemberChanges__contact__link" href="tel:+18003614258">
            1 800 361-4258
          </a>
        </address>
      </div>
    );
  };

  const renderContent = () => {
    switch (pageState) {
      case MemberChangesPageStates.Loading:
        return <Initializing />;
      case MemberChangesPageStates.Default:
        return renderDefault();
      case MemberChangesPageStates.Accepted:
      case MemberChangesPageStates.Refused:
        return (
          <p className="f4eSimpleLayout__panel__content__text">
            {t('memberChangesPage.confirmation', { context: pageState })}
          </p>
        );
      default:
        return (
          <p className="f4eSimpleLayout__panel__content__text">{t('memberChangesPage.alreadyProcessed')}</p>
        );
    }
  };

  return <SimpleLayout width="medium">{renderContent()}</SimpleLayout>;
}
