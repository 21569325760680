import { ColDef } from '@ag-grid-community/core';
import i18n from 'i18next';

import { DataType } from '@common/constants/data-type';

// Note, use underscore to represent a joined field. e.g.: {field: 'joinedTable_name'}
// will translate to select joinedTable.name

export const membersColumnDefinition = (): ColDef[] => {
  return [
    {
      field: 'identificationNumber',
      headerName: i18n.t('models.member.identificationNumber'),
      type: DataType.Text,
      initialWidth: 135,
      flex: undefined,
    },
    {
      field: 'firstName',
      headerName: i18n.t('models.member.firstName'),
      type: DataType.Text,
      sort: 'asc',
      sortIndex: 2,
    },
    {
      field: 'lastName',
      headerName: i18n.t('models.member.lastName'),
      type: DataType.Text,
      sort: 'asc',
      sortIndex: 1,
    },
    {
      field: 'activeState',
      headerName: i18n.t('models.member.activeState'),
      type: DataType.ActiveState,
      initialWidth: 135,
      flex: undefined,
    },
    {
      field: 'status',
      headerName: i18n.t('models.member.status'),
      type: DataType.MemberStatus,
      hide: true,
    },
    {
      field: 'gender',
      headerName: i18n.t('models.member.gender'),
      type: DataType.Gender,
      initialWidth: 50,
      flex: undefined,
      cellStyle: { textAlign: 'center' },
    },
    {
      field: 'dob',
      headerName: i18n.t('models.member.dob'),
      type: DataType.Date,
      hide: true,
      filter: false,
      sortable: false,
      enableRowGroup: false,
    },
    {
      field: 'associationNumber',
      headerName: 'Association',
      type: DataType.Association,
    },
    {
      field: 'serviceCenterNumber',
      headerName: i18n.t('models.member.serviceCenter'),
      type: DataType.ServiceCenter,
      hide: true,
    },
    {
      field: 'unifiedAddr',
      headerName: 'Adresse',
      type: DataType.Text,
      filter: false,
      sortable: false,
      enableRowGroup: false,
    },
    {
      field: 'addrApp',
      headerName: i18n.t('models.member.addrApp'),
      type: DataType.Text,
      hide: true,
      initialWidth: 100,
      flex: undefined,
      filter: false,
      sortable: false,
      enableRowGroup: false,
    },
    {
      field: 'addrPostalCode',
      headerName: i18n.t('models.member.addrPostalCode'),
      type: DataType.PostalCode,
      hide: true,
      filter: false,
      sortable: false,
      enableRowGroup: false,
    },
    {
      field: 'addrProvince',
      headerName: i18n.t('models.member.addrProvince'),
      type: DataType.Province,
      hide: true,
    },
    { field: 'unifiedCity', headerName: i18n.t('models.member.addrCity'), type: DataType.Text },
    {
      field: 'personalEmail',
      headerName: i18n.t('models.member.personalEmail'),
      type: DataType.Text,
      hide: true,
    },
    {
      field: 'personalPhoneNumber',
      headerName: i18n.t('models.member.personalPhoneNumber'),
      type: DataType.PhoneNumber,
      hide: true,
      filter: false,
      sortable: false,
      enableRowGroup: false,
    },
    {
      field: 'schooling',
      headerName: i18n.t('models.member.schooling'),
      type: DataType.Schooling,
      hide: true,
      filter: false,
      sortable: false,
      enableRowGroup: false,
    },
    {
      field: 'role',
      headerName: i18n.t('models.member.role'),
      type: DataType.Role,
      hide: true,
    },
    {
      field: 'levelsOfSchooling',
      headerName: i18n.t('models.member.levelsOfSchooling'),
      type: DataType.LevelOfSchooling,
      hide: true,
    },
    {
      field: 'jobClassification',
      headerName: i18n.t('models.member.jobClassification'),
      type: DataType.Text,
      hide: true,
    },
    {
      field: 'professionalPhoneNumber',
      headerName: i18n.t('models.member.professionalPhoneNumber'),
      type: DataType.PhoneNumber,
      hide: true,
    },
    {
      field: 'professionalPhoneNumberExt',
      headerName: i18n.t('models.member.professionalPhoneNumberExt'),
      type: DataType.Text,
      hide: true,
      initialWidth: 100,
      flex: undefined,
    },
    {
      field: 'professionalEmail',
      headerName: i18n.t('models.member.professionalEmail'),
      type: DataType.Text,
    },
    {
      field: 'cellPhoneNumber',
      headerName: i18n.t('models.member.cellPhoneNumber'),
      type: DataType.PhoneNumber,
      hide: true,
      filter: false,
      sortable: false,
      enableRowGroup: false,
    },
    {
      field: 'schools',
      headerName: i18n.t('models.member.schools'),
      initialWidth: 250,
      flex: undefined,
      type: DataType.Text,
      hide: true,
    },
    {
      field: 'startDate',
      headerName: i18n.t('models.member.startDate'),
      type: DataType.Date,
      hide: true,
    },
    {
      field: 'jobType',
      headerName: i18n.t('models.member.jobType'),
      type: DataType.JobType,
      hide: true,
    },
    {
      field: 'agDelegateStatus',
      headerName: i18n.t('models.member.agDelegateStatus'),
      type: DataType.AgDelegateStatus,
      hide: true,
    },
    {
      field: 'otherStatuses',
      headerName: i18n.t('models.member.otherStatuses'),
      type: DataType.OtherMemberStatus,
      hide: true,
    },
    {
      field: 'isContributor',
      headerName: i18n.t('models.member.isContributor'),
      type: DataType.Boolean,
      hide: true,
    },
    {
      field: 'lastContributionDate',
      headerName: i18n.t('models.member.lastContributionDate'),
      type: DataType.Date,
      hide: true,
    },
    {
      field: 'salary',
      headerName: i18n.t('models.member.salary'),
      type: DataType.Amount,
      hide: true,
    },
    {
      field: 'ackAuthorizePersonalInfo',
      headerName: i18n.t('models.member.ackAuthorizePersonalInfo'),
      type: DataType.Authorization,
      hide: true,
    },
    {
      field: 'lastUpdateDate',
      headerName: i18n.t('models.member.lastUpdateDate'),
      type: DataType.Date,
      hide: true,
      cellRendererParams: {
        format: 'yyyy-MM-dd (HH:mm)',
      },
    },
    {
      field: 'lastServiceCenterChangeDate',
      headerName: i18n.t('models.member.lastServiceCenterChangeDate'),
      type: DataType.Date,
      hide: true,
      cellRendererParams: {
        format: 'yyyy-MM-dd (HH:mm)',
      },
    },
  ];
};

export const associationsColumnDefinition = (): ColDef[] => [
  {
    field: 'number',
    headerName: i18n.t('models.association.number'),
    type: DataType.Number,
    sort: 'asc',
    initialWidth: 130,
    flex: undefined,
  },
  { field: 'name', headerName: i18n.t('models.association.name'), type: DataType.Text, flex: 2 },
  { field: 'president', headerName: i18n.t('models.association.president'), type: DataType.Text },
  {
    field: 'dateOfFounding',
    headerName: i18n.t('models.association.dateOfFounding'),
    type: DataType.Date,
  },
  {
    field: 'type',
    headerName: i18n.t('models.association.type'),
    type: DataType.AssociationType,
  },
  {
    field: 'contactName',
    headerName: i18n.t('models.association.contactName'),
    type: DataType.Text,
    hide: true,
  },
  {
    field: 'contactEmail',
    headerName: i18n.t('models.association.contactEmail'),
    type: DataType.Text,
    hide: true,
  },
];

export const serviceCentersColumnDefinition = (): ColDef[] => [
  {
    field: 'number',
    headerName: i18n.t('models.serviceCenter.number'),
    type: DataType.Number,
    sort: 'asc',
    initialWidth: 130,
    flex: undefined,
  },
  { field: 'name', headerName: i18n.t('models.serviceCenter.name'), type: DataType.Text },
  { field: 'address', headerName: i18n.t('models.serviceCenter.address'), type: DataType.Text, hide: true },
  { field: 'city', headerName: i18n.t('models.serviceCenter.city'), type: DataType.Text },
  {
    field: 'postalCode',
    headerName: i18n.t('models.serviceCenter.postalCode'),
    type: DataType.PostalCode,
    hide: true,
  },
  {
    field: 'phoneNumber',
    headerName: i18n.t('models.serviceCenter.phoneNumber'),
    type: DataType.PhoneNumber,
    initialWidth: 190,
    flex: undefined,
  },
  {
    field: 'domain',
    headerName: i18n.t('models.serviceCenter.domain'),
    type: DataType.Text,
    hide: true,
  },
  {
    field: 'dateOfIntegration',
    headerName: i18n.t('models.serviceCenter.dateOfIntegration'),
    type: DataType.Date,
    hide: true,
  },
  {
    field: 'associationNumber',
    headerName: i18n.t('models.serviceCenter.association'),
    type: DataType.Association,
  },
];

export const membershipsColumnDefinition = (): ColDef[] => [
  {
    field: 'issue',
    headerName: i18n.t('models.membership.issue'),
    type: DataType.MembershipIssue,
    cellStyle: { textAlign: 'center' },
    initialWidth: 120,
    flex: undefined,
  },
  {
    field: 'identificationNumber',
    headerName: i18n.t('models.member.identificationNumber'),
    type: DataType.Text,
    hide: true,
  },
  {
    field: 'firstName',
    headerName: i18n.t('models.member.firstName'),
    type: DataType.Text,
  },
  {
    field: 'lastName',
    headerName: i18n.t('models.member.lastName'),
    type: DataType.Text,
  },
  {
    field: 'personalEmail',
    headerName: i18n.t('models.member.personalEmail'),
    type: DataType.Text,
    hide: true,
  },
  {
    field: 'professionalEmail',
    headerName: i18n.t('models.member.professionalEmail'),
    type: DataType.Text,
    hide: true,
  },
  {
    field: 'associationNumber',
    headerName: i18n.t('models.member.association'),
    type: DataType.Association,
  },
  {
    field: 'serviceCenterNumber',
    headerName: i18n.t('models.member.serviceCenter'),
    type: DataType.ServiceCenter,
  },
  {
    field: 'formCompletedDate',
    headerName: i18n.t('models.membership.formCompletedDate'),
    type: DataType.Date,
    hide: true,
  },
  {
    field: 'formConfirmedDate',
    headerName: i18n.t('models.membership.formConfirmedDate'),
    type: DataType.Date,
    hide: true,
  },
  {
    field: 'firstContributionDate',
    headerName: i18n.t('models.membership.firstContributionDate'),
    type: DataType.Date,
    hide: true,
  },
  {
    field: 'approvingDate',
    headerName: i18n.t('models.membership.approvingDate'),
    type: DataType.Date,
    hide: true,
  },
  {
    field: 'approverId',
    headerName: i18n.t('models.membership.approver'),
    type: DataType.User,
    hide: true,
  },
  {
    field: 'created',
    headerName: i18n.t('models.membership.created'),
    type: DataType.Date,
    initialWidth: 200,
    flex: undefined,
    sort: 'desc',
    cellRendererParams: {
      format: 'yyyy-MM-dd (HH:mm)',
    },
  },
  {
    field: 'status',
    headerName: i18n.t('models.membership.status'),
    type: DataType.MembershipStatus,
    initialWidth: 175,
    flex: undefined,
  },
  {
    field: 'processState',
    headerName: i18n.t('models.membership.processState'),
    type: DataType.MembershipProcessState,
    initialWidth: 220,
    flex: undefined,
  },
  {
    field: 'externalAccountCreated',
    headerName: i18n.t('models.membership.externalAccountCreated'),
    type: DataType.Boolean,
    initialWidth: 115,
    flex: undefined,
  },
  {
    field: 'notes',
    headerName: i18n.t('models.membership.notes'),
    type: DataType.Text,
  },
];

export const gricsImportsColumnDefinition = (): ColDef[] => [
  { field: 'fileName', headerName: i18n.t('models.gricsImport.fileName'), type: DataType.Text, flex: 2 },
  {
    field: 'serviceCenterNumber',
    headerName: i18n.t('models.gricsImport.serviceCenter'),
    type: DataType.ServiceCenter,
  },
  { field: 'importedById', headerName: i18n.t('models.gricsImport.importedBy'), type: DataType.User },
  {
    field: 'lastUpdatedDate',
    headerName: i18n.t('models.gricsImport.completedDate'),
    type: DataType.Date,
    sort: 'desc',
    initialWidth: 200,
    flex: undefined,
    cellRendererParams: {
      format: 'yyyy-MM-dd (HH:mm)',
    },
  },
  {
    field: 'status',
    headerName: i18n.t('models.gricsImport.status'),
    type: DataType.GricsImportStatus,
    flex: undefined,
    initialWidth: 170,
  },
  {
    field: 'budgetYear',
    headerName: i18n.t('models.contributionDue.budgetYear'),
    type: DataType.Text,
    hide: true,
  },
];

export const gricsImportsChangesColumnDefinition = (): ColDef[] => [
  {
    field: 'identificationNumber',
    headerName: i18n.t('models.member.identificationNumber'),
    type: DataType.Text,
  },
  { field: 'firstName', headerName: i18n.t('models.member.firstName'), type: DataType.Text },
  { field: 'lastName', headerName: i18n.t('models.member.lastName'), type: DataType.Text },
  {
    field: 'associationNumber',
    headerName: i18n.t('models.member.association'),
    type: DataType.Association,
  },
  {
    field: 'serviceCenterNumber',
    headerName: i18n.t('models.member.serviceCenter'),
    type: DataType.ServiceCenter,
  },
  {
    field: 'date',
    headerName: i18n.t('models.memberChangeRequest.date'),
    type: DataType.Date,
    sort: 'desc',
    flex: undefined,
    initialWidth: 200,
    cellRendererParams: {
      format: 'yyyy-MM-dd (HH:mm)',
    },
  },
  {
    field: 'status',
    headerName: i18n.t('models.memberChangeRequest.status'),
    type: DataType.GricsImportChangeStatus,
    flex: undefined,
    initialWidth: 170,
  },
];

export const usersColumnDefinition = (): ColDef[] => [
  { field: 'fullName', headerName: i18n.t('models.user.name'), type: DataType.Text, sort: 'asc' },
  { field: 'email', headerName: i18n.t('models.user.email'), type: DataType.Text },
  {
    field: 'role',
    headerName: i18n.t('models.user.role'),
    type: DataType.UserRole,
    flex: undefined,
    initialWidth: 220,
  },
  {
    field: 'enabled',
    headerName: i18n.t('models.user.enabled'),
    type: DataType.Boolean,
    flex: undefined,
    initialWidth: 150,
  },
];

export const contributionsDueColumnDefinition = (): ColDef[] => [
  {
    field: 'associationNumber',
    headerName: i18n.t('models.contributionDue.association'),
    type: DataType.Association,
    rowGroup: true,
    rowGroupIndex: 2,
    sort: 'asc',
    sortIndex: 1,
    hide: true,
  },
  {
    field: 'serviceCenterNumber',
    headerName: i18n.t('models.contributionDue.serviceCenter'),
    type: DataType.ServiceCenter,
    rowGroup: true,
    rowGroupIndex: 3,
    sort: 'asc',
    sortIndex: 2,
    hide: true,
  },
  {
    field: 'budgetYear',
    headerName: i18n.t('models.contributionDue.budgetYear'),
    type: DataType.Text,
    hide: true,
  },
  {
    field: 'periodNumber',
    headerName: i18n.t('models.contributionDue.periodNumber'),
    type: DataType.Text,
    sort: 'asc',
    sortIndex: 3,
    sortable: false,
    initialWidth: 100,
    flex: undefined,
  },
  {
    field: 'accountingMonth',
    headerName: i18n.t('models.contributionDue.accountingMonth'),
    type: DataType.AccountingMonth,
    sortable: false,
    initialWidth: 120,
    flex: undefined,
    headerClass: 'f4eNoFilterIcon',
  },
  {
    field: 'date',
    headerName: i18n.t('models.contributionDue.date'),
    type: DataType.Date,
    sortable: false,
    initialWidth: 120,
    flex: undefined,
  },
  {
    field: 'contributionsAmount',
    headerName: i18n.t('models.contributionDue.contributionsAmount'),
    type: DataType.Amount,
    sortable: false,
  },
  {
    field: 'paymentTransactionNumber',
    headerName: i18n.t('models.payment.transactionNumber'),
    type: DataType.Text,
    sortable: false,
  },
  {
    field: 'paymentDate',
    headerName: i18n.t('models.payment.date'),
    type: DataType.Date,
    sortable: false,
  },
  {
    field: 'paymentMethod',
    headerName: i18n.t('models.payment.method'),
    type: DataType.PaymentMethod,
    sortable: false,
  },
  {
    field: 'paymentAmount',
    headerName: i18n.t('models.payment.amount'),
    type: DataType.Amount,
    sortable: false,
  },
  {
    field: 'balance',
    type: DataType.Amount,
    headerName: i18n.t('models.contributionDue.balance'),
    aggFunc: 'sum',
    cellStyle: ({ node }) => ({ fontWeight: node.group ? 'bold' : 'normal', textAlign: 'right' }),
    sortable: false,
  },
];

export const schoolsColumnDefinition = (): ColDef[] => [
  {
    field: 'number',
    headerName: i18n.t('models.school.number'),
    type: DataType.Text,
    sort: 'asc',
    initialWidth: 130,
    flex: undefined,
  },
  { field: 'name', headerName: i18n.t('models.school.name'), type: DataType.Text },
  {
    field: 'levelsOfSchooling',
    headerName: i18n.t('models.school.levelsOfSchooling'),
    type: DataType.LevelOfSchooling,
  },
  { field: 'address', headerName: i18n.t('models.school.address'), type: DataType.Text, hide: true },
  { field: 'city', headerName: i18n.t('models.school.city'), type: DataType.Text },
  {
    field: 'postalCode',
    headerName: i18n.t('models.school.postalCode'),
    type: DataType.PostalCode,
    hide: true,
  },
  { field: 'email', headerName: i18n.t('models.school.email'), type: DataType.Text, hide: true },
  {
    field: 'phoneNumber',
    headerName: i18n.t('models.school.phoneNumber'),
    type: DataType.PhoneNumber,
    initialWidth: 190,
    flex: undefined,
  },
  {
    field: 'phoneNumberExt',
    headerName: i18n.t('models.school.phoneNumberExt'),
    type: DataType.Text,
    initialWidth: 100,
    flex: undefined,
  },
  {
    field: 'faxNumber',
    headerName: i18n.t('models.school.faxNumber'),
    type: DataType.PhoneNumber,
    hide: true,
  },
  { field: 'website', headerName: i18n.t('models.school.website'), type: DataType.Text, hide: true },
  {
    field: 'serviceCenterNumber',
    headerName: i18n.t('models.school.serviceCenter'),
    type: DataType.ServiceCenter,
  },
];
