import React, { ReactNode } from 'react';
import classNames from 'classnames';

import ErrorBoundary from '@modules/shared/components/ErrorBoundary/ErrorBoundary';

interface IProps {
  children: ReactNode;
  noGrid?: boolean;
}

function LayoutContent({ children, noGrid }: IProps) {
  const classes = classNames('f4eLayout__content', {
    'f4eLayout__content--no-grid': noGrid,
  });

  return (
    <div className={classes}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </div>
  );
}

export default LayoutContent;
