import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Logo } from '@assets/logo.svg';

interface IProps {
  title: string;
  subTitle: string;
  mention?: string;
  includeReportHeader?: boolean;
}

function ReportHeader({ title, subTitle, mention, includeReportHeader = true }: IProps) {
  const { t } = useTranslation();

  return (
    <header className="f4eReport__header">
      {includeReportHeader && (
        <section className="f4eReport__header__print">
          <Logo className="f4eReport__header__print__logo" />
          <address className="f4eReport__header__print__addr">
            <p>{t('reports.general.addr1')}</p>
            <p>{t('reports.general.addr2')}</p>
          </address>
        </section>
      )}
      <h1 className="f4eReport__header__title">{title}</h1>
      <p className="f4eReport__header__sub-title">{subTitle}</p>
      {mention && <p className="f4eReport__header__mention">{mention}</p>}
    </header>
  );
}

export default ReportHeader;
