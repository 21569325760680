import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DisplayStyles,
  Form,
  FormActionButton,
  FormActionButtonType,
  FormContext,
  FormDateField,
  FormFields,
  FormSelectField,
  FormSelectFieldOptions,
} from '@novo-electronique/react-forms';
import * as Yup from 'yup';

import { IConciliationReport } from '@common/models/contribution-report';
import { getLatestBudgetYear, getLatestPeriods } from '@common/utils/period';
import { accountingMonthValueProvider } from '@common/value-provider';
import { valueProviderToOptions } from '@modules/shared/components/Form/utils';
import Initializing from '@modules/shared/components/Initializing';
import Layout, { LayoutContent, LayoutHeader } from '@modules/shared/components/Layout/';
import PeriodSelector from '@modules/shared/components/PeriodSelector';
import Toolbar, { ToolbarActionType } from '@modules/shared/components/Toolbar';
import CumulativeConciliationReport from '../reports/CumulativeConciliationReport';
import { downloadCumulativeReport, getCumulativeConciliation } from './service';

const initialValues = { accountingMonth: undefined, date: new Date() };
const validationSchema = Yup.object({
  accountingMonth: Yup.number().required(),
  date: Yup.date().required(),
});

function CumulativeConciliationReportPage() {
  const { t } = useTranslation();
  const budgetYearRef = useRef(getLatestBudgetYear());
  const accountingMonthRef = useRef();

  const [reportData, setReportData] = useState<IConciliationReport>(null);
  const [reportTitle, setReportTitle] = useState<string>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const periods = getLatestPeriods();

  const downloadReport = (): Promise<void> => {
    return downloadCumulativeReport(
      accountingMonthValueProvider.label(accountingMonthRef.current),
      periods[budgetYearRef.current],
    );
  };

  const onSubmit = ({ accountingMonth, date }: typeof initialValues): Promise<void> => {
    if (accountingMonth === '') return;

    accountingMonthRef.current = accountingMonth;
    setReportData(null);
    setIsLoading(true);

    const selectedPeriod = periods[budgetYearRef.current];
    return getCumulativeConciliation(selectedPeriod, accountingMonth, date)
      .then(setReportData)
      .then(() => {
        setReportTitle(
          t('cumulativeConciliationReportPage.reportTitle', {
            start: selectedPeriod.start,
            end: selectedPeriod.end,
            month: accountingMonthValueProvider.label(accountingMonthRef.current),
          }),
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Layout title={reportTitle}>
      <LayoutHeader title={t('cumulativeConciliationReportPage.title')}>
        <PeriodSelector onChange={(value) => (budgetYearRef.current = value)} small />
        <Toolbar
          actions={{
            [ToolbarActionType.Print]: {
              action: window.print,
              disabled: reportData === null,
            },
            [ToolbarActionType.Download]: {
              action: downloadReport,
              disabled: reportData === null,
            },
          }}
        />
      </LayoutHeader>
      <LayoutContent>
        <FormContext validationSchema={validationSchema} initialValues={initialValues} onSubmit={onSubmit}>
          <div className="f4eForm f4eForm--no-required-symbol f4eForm--no-error">
            <Form displayStyle={DisplayStyles.Inline}>
              <FormFields>
                <FormSelectField
                  name="accountingMonth"
                  label={t('reports.cumulativeConciliation.params.accountingMonth')}
                >
                  <FormSelectFieldOptions
                    options={valueProviderToOptions(accountingMonthValueProvider)}
                    keepOriginalOrder={true}
                    addEmptyOption={true}
                  />
                </FormSelectField>
                <FormDateField
                  name="date"
                  maxDate={new Date()}
                  label={t('reports.cumulativeConciliation.params.date')}
                />
                <FormActionButton type={FormActionButtonType.Submit} suppressErrorTooltip>
                  {t('general.actions.generate')}
                </FormActionButton>
              </FormFields>
            </Form>
          </div>
        </FormContext>

        {isLoading && <Initializing />}
        <CumulativeConciliationReport data={reportData} title={reportTitle} />
      </LayoutContent>
    </Layout>
  );
}

export default CumulativeConciliationReportPage;
