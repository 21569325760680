import { LevelOfSchooling } from '../constants/level-of-schooling';
import {
  getNumberValidationSchema,
  getPhoneValidationSchema,
  getWebsiteValidationSchema,
} from '../utils/validation-schema';
import { IHistory } from './history';
import { IMember } from './member';
import { IServiceCenter } from './service-center';

export interface ISchool {
  id?: string;

  number: number;
  name: string;
  levelsOfSchooling: LevelOfSchooling[];
  address: string;
  city: string;
  postalCode: string;

  phoneNumber: string;
  phoneNumberExt?: string;
  faxNumber: string;
  email: string;
  website: string;

  serviceCenter?: Partial<IServiceCenter>;
  members?: IMember[];
  history?: IHistory;
}

export const getSchoolValidationSchema = (Yup) =>
  Yup.object({
    number: getNumberValidationSchema(Yup, 1),
    name: Yup.string().max(250).required(),
    levelsOfSchooling: Yup.array().of(Yup.string()),
    address: Yup.string().max(250),
    city: Yup.string().max(250),
    postalCode: Yup.string().max(250),
    serviceCenter: Yup.object({
      id: Yup.string().required(),
    }),

    phoneNumber: getPhoneValidationSchema(Yup, false),
    faxNumber: getPhoneValidationSchema(Yup, false),
    email: Yup.string().email(),
    website: getWebsiteValidationSchema(Yup),
  });

export const getSchoolInitialValues = (): ISchool => ({
  number: null,
  name: '',
  levelsOfSchooling: [],
  address: '',
  city: '',
  postalCode: '',
  phoneNumber: '',
  phoneNumberExt: '',
  faxNumber: '',
  email: '',
  website: '',
  serviceCenter: {
    id: '',
  },
});
