import React, { MutableRefObject, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

import Modal, { IModalProps } from '@modules/shared/components/Modal/Modal';

interface IProps extends IModalProps {
  formikRef: MutableRefObject<ReturnType<typeof useFormik>>;
  onCloseHandlerRef?: MutableRefObject<() => void>;
}

function FormModal({ title, children, onClose, isHost, size, formikRef, onCloseHandlerRef }: IProps) {
  const { t } = useTranslation();

  const onCloseHandler = () => {
    if (
      !formikRef.current?.dirty ||
      formikRef.current?.isSubmitting ||
      confirm(t('general.confirmations.confirmDirtyFormModalClose'))
    ) {
      onClose();
    }
  };

  useEffect(() => {
    if (onCloseHandlerRef) {
      onCloseHandlerRef.current = onCloseHandler;
    }
  }, [onCloseHandlerRef]);

  return (
    <Modal title={title} onClose={onCloseHandler} isHost={isHost} size={size}>
      {children}
    </Modal>
  );
}

export default FormModal;
