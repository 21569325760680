import './CurrentUserMenu.scss';

import React, { MouseEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { selectUser } from '@modules/app/redux/selectors';
import { signOut } from '@modules/auth/service';
import { AppRoutePages } from '@src/constants';
import { useThunkDispatch } from '@src/redux/useThunkDispatch';

export default function CurrentUserMenu() {
  const { t } = useTranslation();
  const dispatch = useThunkDispatch();
  const history = useHistory();

  const currentUser = useSelector(selectUser);
  const [isShowingActions, setShowingActions] = useState(false);

  useEffect(() => {
    const closeMenu = () => setShowingActions(false);
    window.addEventListener('click', closeMenu);
    return () => {
      window.removeEventListener('click', closeMenu);
    };
  }, []);

  const toggleMenu = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setShowingActions(!isShowingActions);
  };

  return (
    <article className="f4eCurrentUserMenu">
      <button className="f4eCurrentUserMenu__userInfo" onClick={toggleMenu}>
        <span className="material-icons f4eCurrentUserMenu__userInfo__profile">account_circle</span>
        {currentUser.firstName && currentUser.lastName
          ? currentUser.firstName + ' ' + currentUser.lastName
          : currentUser.email}

        <span className="material-icons f4eCurrentUserMenu__userInfo__chevronDown">expand_more</span>
      </button>
      {isShowingActions && (
        <ul role="menu" className="f4eCurrentUserMenu__actions">
          <li className="f4eCurrentUserMenu__actions__item">
            <button
              className="f4eCurrentUserMenu__actions__item__button"
              onClick={() => dispatch(signOut()).then(() => history.push(AppRoutePages.Home))}
            >
              {t(`currentUserMenu.signOut`)}
            </button>
          </li>
        </ul>
      )}
    </article>
  );
}
