import { ITokenPayload } from '@common/models/token-payload';

export function getTokenPayload(token: string): ITokenPayload {
  if (!token || !token.includes('.')) {
    return undefined;
  }

  try {
    return JSON.parse(atob(token.split('.')[1])) as ITokenPayload;
  } catch (e) {
    console.error(e);
    return undefined;
  }
}
