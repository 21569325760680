import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { AppRoutePages } from '@constants/routes';
import { selectUser } from '@modules/app/redux/selectors';

interface IProps extends RouteProps {
  defaultRoute: AppRoutePages;
}

export default function AppUnAuthorizedRoute({ children, defaultRoute, ...rest }: IProps) {
  const currentUser = useSelector(selectUser);

  const isAuthenticated = () => {
    return currentUser !== null && currentUser !== undefined;
  };

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated() === false ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: defaultRoute,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
