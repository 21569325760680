import './MergeSelectionTableRow.scss';

import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useFormField } from '@novo-electronique/react-forms';
import classNames from 'classnames';

import { IMember } from '@common/models/member';
import { selectCache } from '@modules/app/redux/selectors';
import { DataTableSource } from '@modules/data-table/data-table-source';
import { ValueFormatter } from '@modules/shared/value-formatter';

const defaultValue = '-';

interface IProps {
  property: keyof IMember;
  original: IMember;
  source: IMember;
  onConflictResolved: () => void;
}

function MergeMembersTableRow({ property, original, source, onConflictResolved }: IProps) {
  const { t } = useTranslation();
  const { value, setFieldValue } = useFormField(property);
  const cache = useSelector(selectCache);

  const valueFormatter = useMemo(() => new ValueFormatter(DataTableSource.Members, cache), [cache]);
  const originalValue = valueFormatter.format(property, original[property]) || defaultValue;
  const sourceValue = valueFormatter.format(property, source[property]) || defaultValue;

  const noConflict = originalValue === sourceValue;
  const [hasConflicts, setHasConflicts] = useState(originalValue !== sourceValue);

  useEffect(() => {
    if (noConflict) {
      setFieldValue(property, original[property]);
    }
  }, []);

  useEffect(() => {
    if (!hasConflicts) {
      onConflictResolved();
    }
  }, [hasConflicts]);

  const renderCheckbox = (formattedValue: string, value: any) => {
    return (
      <label className="f4eMergeMembersTableRowInput">
        <input
          type="radio"
          name={`${property}.value`}
          onChange={() => {
            setFieldValue(property, value);
            setHasConflicts(false);
          }}
          disabled={noConflict}
          className="f4eMergeMembersTableRowInput__input"
        />
        <span className="f4eMergeMembersTableRowInput__label">{formattedValue.toString()}</span>
      </label>
    );
  };

  const className = classNames('f4eMergeMembersTableRow', {
    'f4eMergeMembersTableRow--conflicts': hasConflicts,
    'f4eMergeMembersTableRow--no-conflict': noConflict,
  });
  return (
    <tr className={className}>
      <td className="f4eMergeMembersTableRow__cell f4eMergeMembersTableRow__cell--name">
        {t(`models.member.${property}`)}
      </td>
      <td className="f4eMergeMembersTableRow__cell">{renderCheckbox(originalValue, original?.[property])}</td>
      <td className="f4eMergeMembersTableRow__cell">{renderCheckbox(sourceValue, source?.[property])}</td>
      <td className="f4eMergeMembersTableRow__cell f4eMergeMembersTableRow__cell--result">
        {noConflict ? originalValue : valueFormatter.format(property, value)}
      </td>
    </tr>
  );
}

export default MergeMembersTableRow;
