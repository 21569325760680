import { AccountingMonth } from '../constants/accounting-month';
import { IHistory } from './history';
import { IMember } from './member';
import { IServiceCenter } from './service-center';

export interface IContribution {
  id?: string;

  budgetYear?: string;
  periodNumber: number;
  date: Date;

  regularContribution: number;
  socialContribution: number;
  specialContribution: number;

  flatRateNumber?: number;
  flatRateType?: string;
  jobReference?: string;
  lumpSum?: number;

  isManuallyEdited?: boolean;

  member?: Partial<IMember>;
  serviceCenter?: Partial<IServiceCenter>;
  history?: IHistory;
}

export interface ICumulativeContribution {
  budgetYear: string;
  periodNumber: number;
  accountingMonth: AccountingMonth;
  date: Date;

  regularContribution: number;
  socialContribution: number;
  specialContribution: number;

  associationId: string;
}

export const getContributionValidationSchema = (Yup) =>
  Yup.object({
    periodNumber: Yup.number().required(),
    date: Yup.date().required(),
    regularContribution: Yup.number().required(),
    socialContribution: Yup.number().required(),
    specialContribution: Yup.number().required(),
    member: Yup.object({ id: Yup.string() }),
    isManuallyEdited: Yup.boolean(),
    serviceCenter: Yup.object({ id: Yup.string().required() }),
  });
