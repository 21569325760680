import { IAssociationFinancialInfo } from '@common/models/association-financial-info';
import { formatDate } from '@common/utils/date';

const sortFinancialInformation = (info: IAssociationFinancialInfo[]): IAssociationFinancialInfo[] => {
  return info.sort((info1: IAssociationFinancialInfo, info2: IAssociationFinancialInfo) => {
    const date1 = formatDate(info1.effectiveDate);
    const date2 = formatDate(info2.effectiveDate);
    return date2.localeCompare(date1);
  });
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'RECEIVED':
      return sortFinancialInformation(action.payload);

    case 'REMOVE':
      return sortFinancialInformation(
        state.filter((financialInfo: IAssociationFinancialInfo) => financialInfo.id !== action.payload),
      );

    case 'ADD':
      return sortFinancialInformation([...state, action.payload]);

    default:
      return state;
  }
};

export const financialInfoReceivedAction = (financialInfo: IAssociationFinancialInfo[]) => ({
  type: 'RECEIVED',
  payload: financialInfo,
});

export const removeFinancialInfoAction = (id: string) => ({
  type: 'REMOVE',
  payload: id,
});

export const addFinancialInfoAction = (financialInfo: IAssociationFinancialInfo) => ({
  type: 'ADD',
  payload: financialInfo,
});

export default reducer;
