import Exception, { InvalidDataException } from '@common/models/exceptions';
import { getStatusMessage, getStatusName } from './http-status';

/**
 * Throws an exception if IsSuccessStatusCode(response.status) is false.
 */
export async function ensureSuccessStatusCode(statusCode: number, content: any) {
  if (!isSuccessStatusCode(statusCode)) {
    const message = content?.errorMessages?.join(', ') || getStatusMessage(statusCode);
    throw new Exception(getStatusName(statusCode), `${statusCode}: ${message}`, content);
  }
}

/**
 * Throws an exception if IsSuccessStatusCode(response.status) is false.
 */
export async function ensureSuccessResponse(
  response: Response,
  expectedFormat?: string,
  statusCode?: number,
) {
  if (!isSuccessStatusCode(statusCode || response.status)) {
    if (expectedFormat && !response.headers.get('content-type').includes(expectedFormat)) {
      throw new InvalidDataException(`The format of the response must be ${expectedFormat}`);
    }

    const body: any = response.json ? await response.json() : '';
    if (isErrorResponse(body)) {
      throw Exception.new(body.error as Exception);
    } else {
      const exception = new Exception(
        getStatusName(statusCode || response.status),
        body.message || `${statusCode}: ${getStatusMessage(response.status)}`,
        body,
      );
      exception.stack = body.stack || '';
      throw exception;
    }
  }
}

/**
 * A value that indicates if the HTTP response was successful ->
 * true if status in the Successful range (200-299); otherwise false.
 */
function isSuccessStatusCode(status: number) {
  return status >= 200 && status <= 299;
}

function isErrorResponse(body: any) {
  // TODO:NG find a better way...
  return body.error && body.error.code;
}
