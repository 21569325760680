import './MergeFormModal.scss';

import React, { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { DataTable, useDataTable } from '@novo-electronique/react-data-table';
import { Form, FormContext } from '@novo-electronique/react-forms';
import * as Yup from 'yup';

import { DataType } from '@common/constants/data-type';
import { getMemberValidationSchema, IMember } from '@common/models/member';
import { setAppIsLoading } from '@modules/app/redux/action';
import { generateColumnDefinition } from '@modules/data-table/column-definition-factory';
import { DataTableSource } from '@modules/data-table/data-table-source';
import MemberInfoSections from '@modules/members/components/MemberInfoSections';
import { getMember } from '@modules/members/services/members';
import MergeSelectionTable from '@modules/merge/components/MergeSelectionTable';
import { getConflictsResolutions, getMemberToMerge, mergeMember } from '@modules/merge/service';
import { FormModal, ModalSize } from '@modules/shared/components/Modal';
import { Wizard, WizardStep } from '@modules/shared/components/Wizard';

const properties: (keyof IMember)[] = [
  'firstName',
  'lastName',
  'identificationNumber',
  'dob',
  'gender',
  'personalEmail',
  'personalPhoneNumber',
  'schooling',
  'addrCivicNumber',
  'addrStreet',
  'addrApp',
  'addrCity',
  'addrProvince',
  'addrPostalCode',
  'role',
  'startDate',
  'jobClassification',
  'levelsOfSchooling',
  'schools',
  'serviceCenter',
  'jobType',
  'professionalPhoneNumber',
  'professionalPhoneNumberExt',
  'cellPhoneNumber',
  'professionalEmail',
  'salary',
];

interface IProps {
  member: IMember;
  onClose: () => void;
  onMerge?: (mergeMember: IMember) => void;
}

enum Steps {
  Member,
  Information,
  Confirmation,
}

function MergeFormModal({ member, onClose, onMerge }: IProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [source, setSource] = useState<IMember>();
  const [isEditing, setIsEditing] = useState(false);
  const [hasConflicts, setHasConflicts] = useState(true);

  const formikRef = useRef(null);
  const onCloseHandlerRef = useRef<() => void>();
  const initialValuesRef = useRef(getMemberToMerge(member, properties));

  const onTableSelectionChanged = ({ id }: Partial<IMember>) => {
    dispatch(setAppIsLoading(true));
    getMember(id)
      .then((source) => setSource(source))
      .finally(() => dispatch(setAppIsLoading(false)));
  };

  const columns = [
    { field: 'id', type: DataType.Text, hide: true },
    ...generateColumnDefinition(DataTableSource.Members),
  ];
  const { isReady, gridProperties, gridApi } = useDataTable(
    DataTableSource.Members,
    columns,
    onTableSelectionChanged,
    { options: { getRowNodeId: (data) => data.id, showSidebar: false } },
  );

  useEffect(() => {
    if (isReady) {
      gridApi.setFilterModel(null);
      gridApi.getFilterInstance('id').setModel({ type: 'notEqual', filter: member.id });
      gridApi.onFilterChanged();
    }
  }, [isReady]);

  const onSubmit = async (updatedMember: IMember) => {
    const mergedMember = await mergeMember({
      source,
      original: member,
      conflictsResolutions: getConflictsResolutions(member, updatedMember, properties),
    });

    if (onMerge) {
      onMerge(mergedMember);
    }

    onClose();
  };

  const isNextEnabled = (activeIndex: number): boolean => {
    switch (activeIndex) {
      case Steps.Member:
        return Boolean(source);
      case Steps.Information:
        return !hasConflicts;
      default:
        return true;
    }
  };

  const validationSchema = getMemberValidationSchema(Yup);
  const directive = (
    <Trans
      i18nKey="memberMergePage.confirmMessage"
      components={{
        button: (
          <button className="f4eMergeFormModalLinkButton" type="button" onClick={() => setIsEditing(true)} />
        ),
      }}
    />
  );

  return (
    <FormModal
      title={t('memberMergePage.title')}
      size={ModalSize.Maximize}
      onClose={onClose}
      formikRef={formikRef}
      onCloseHandlerRef={onCloseHandlerRef}
      isHost
    >
      <FormContext
        initialValues={initialValuesRef.current}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        formikRef={formikRef}
      >
        <Form>
          <Wizard isNextEnabled={isNextEnabled} onCancel={() => onCloseHandlerRef.current()}>
            <WizardStep
              title={t('memberMergePage.steps.members')}
              directive={t('memberMergePage.membersMessage')}
            >
              <DataTable {...gridProperties} />
            </WizardStep>
            <WizardStep
              title={t('memberMergePage.steps.information')}
              directive={t('memberMergePage.informationMessage')}
            >
              <MergeSelectionTable
                original={member}
                source={source}
                properties={properties}
                onConflictsResolved={() => setHasConflicts(false)}
              />
            </WizardStep>
            <WizardStep title={t('memberMergePage.steps.confirmation')} directive={directive}>
              <div className="f4eMergeFormModalConfirmSection">
                <MemberInfoSections disabled={!isEditing} />
              </div>
            </WizardStep>
          </Wizard>
        </Form>
      </FormContext>
    </FormModal>
  );
}

export default MergeFormModal;
