import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button, ButtonSize, ButtonStyle } from '@novo-electronique/react-button';
import {
  SidePanel,
  SidePanelContent,
  SidePanelFooter,
  SidePanelFooterActions,
  SidePanelHeader,
} from '@novo-electronique/react-side-panel';

import { MemberChangesStatus } from '@common/constants/member-changes-status';
import { IMemberChangesRequest } from '@common/models/member-changes-request';
import { setAppIsLoading } from '@modules/app/redux/action';
import { MemberChangesList } from '@modules/changes/components/MemberChangesList';
import { acceptRequest, getRequest, refuseRequest } from '@modules/changes/service';

interface IProps {
  changeRequestId: string;
  readonly: boolean;
  closeHandler: (actionTaken?: boolean) => void;
}

function ChangeRequestDetails({ closeHandler, changeRequestId, readonly }: IProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [changeRequest, setChangeRequest] = useState<IMemberChangesRequest>(undefined);

  useEffect(() => {
    dispatch(setAppIsLoading(true));
    getRequest(changeRequestId)
      .then((changeRequest) => {
        setChangeRequest(changeRequest);
      })
      .finally(() => dispatch(setAppIsLoading(false)));
  }, [changeRequestId]);

  const accept = () => {
    if (confirm(t('changesRequestsPage.confirmation_accept'))) {
      return acceptRequest(changeRequestId).then(() => closeHandler(true));
    }
  };

  const refuse = () => {
    if (confirm(t('changesRequestsPage.confirmation_refuse'))) {
      return refuseRequest(changeRequestId).then(() => closeHandler(true));
    }
  };

  if (!changeRequest) {
    return null;
  }
  return (
    <SidePanel closeHandler={closeHandler}>
      <SidePanelHeader
        displayCloseButton
        subTitle={
          <span className="f4eTag f4eTag--gricsImportChangeStatus" data-value={changeRequest.status}>
            {t('models.memberChangeRequest.statusOptions', {
              context: changeRequest.status,
            })}
          </span>
        }
      >{`${changeRequest.member.firstName} ${changeRequest.member.lastName} (${changeRequest.member.identificationNumber})`}</SidePanelHeader>
      <SidePanelContent>
        <MemberChangesList changes={changeRequest.changes} />
      </SidePanelContent>

      {!readonly && changeRequest.status === MemberChangesStatus.Pending && (
        <SidePanelFooter>
          <SidePanelFooterActions isSingle>
            <Button style={ButtonStyle.Primary} size={ButtonSize.Small} onClick={accept}>
              {t('memberChangesPage.confirmAction')}
            </Button>
            <Button style={ButtonStyle.Primary} size={ButtonSize.Small} onClick={refuse}>
              {t('memberChangesPage.refuseAction')}
            </Button>
          </SidePanelFooterActions>
        </SidePanelFooter>
      )}
    </SidePanel>
  );
}

export default ChangeRequestDetails;
