import React from 'react';
import { useTranslation } from 'react-i18next';

import { IAssociationContributionReport } from '@common/models/contribution-report';
import { getPeriodFromBudgetYear } from '@common/utils/period';
import { accountingMonthValueProvider } from '@common/value-provider';
import ContributionsSummaryReport from '@modules/finances/components/ContributionReport/ContributionsSummaryReport';
import Report from '@modules/shared/components/Report/Report';
import ServiceCentersContributions from './ServiceCentersContributions';
import ServiceCentersContributionsSummary from './ServiceCentersContributionsSummary';
interface IProps {
  associationContributions: IAssociationContributionReport | IAssociationContributionReport[];
}

function AssociationsContributionReport({ associationContributions }: IProps) {
  const { t } = useTranslation();

  if (!associationContributions) return null;

  const contributions: IAssociationContributionReport[] = Array.isArray(associationContributions)
    ? associationContributions
    : [associationContributions];

  if (contributions.length === 0) return null;

  const period = getPeriodFromBudgetYear(contributions[0].budgetYear);

  const toReport = (contributionReport: IAssociationContributionReport) => {
    const contributorsCount = contributionReport.serviceCenters.reduce(
      (total, serviceCenter) => total + serviceCenter.activeContributorsCount,
      0,
    );

    return (
      <Report
        id={contributionReport.number}
        key={contributionReport.id}
        title={t('reports.contributions.byAssociationTitle', {
          name: contributionReport.name,
        })}
        subTitle={t('reports.contributions.byAssociationSubTitle', { period })}
        mention={t('reports.contributions.contributorsCount', {
          count: contributorsCount,
          accountingMonth: accountingMonthValueProvider
            .label(contributionReport.accountingMonth.toString())
            .toLowerCase(),
        })}
      >
        <ServiceCentersContributions serviceCenters={contributionReport.serviceCenters} />
        <ServiceCentersContributionsSummary serviceCenters={contributionReport.serviceCenters} />
      </Report>
    );
  };

  return (
    <>
      {contributions.map(toReport)}
      {contributions.length > 1 && <ContributionsSummaryReport associationContributions={contributions} />}
    </>
  );
}

export default AssociationsContributionReport;
