import React from 'react';
import { useTranslation } from 'react-i18next';

import { AppRoutePages, pagesPermissionScopes } from '@constants/routes';
import Menu, { MenuItem, MenuStyles } from '@modules/shared/components/Menu';

function MembersSubMenu() {
  const { t } = useTranslation();

  return (
    <Menu style={MenuStyles.SubMenu}>
      <MenuItem to={AppRoutePages.Members} scopes={pagesPermissionScopes[AppRoutePages.Members]}>
        {t('membersPage.menu')}
      </MenuItem>
      <MenuItem to={AppRoutePages.Schools} scopes={pagesPermissionScopes[AppRoutePages.Schools]}>
        {t('schoolsPage.menu')}
      </MenuItem>
      <MenuItem
        to={AppRoutePages.ServiceCenters}
        scopes={pagesPermissionScopes[AppRoutePages.ServiceCenters]}
      >
        {t('serviceCentersPage.menu')}
      </MenuItem>
      <MenuItem to={AppRoutePages.Associations} scopes={pagesPermissionScopes[AppRoutePages.Associations]}>
        {t('associationsPage.menu')}
      </MenuItem>
      <MenuItem to={AppRoutePages.Memberships} scopes={pagesPermissionScopes[AppRoutePages.Memberships]}>
        {t('membershipsPage.menu')}
      </MenuItem>
      <MenuItem
        to={AppRoutePages.ChangesRequests}
        scopes={pagesPermissionScopes[AppRoutePages.ChangesRequests]}
      >
        {t('changesRequestsPage.menu')}
      </MenuItem>
    </Menu>
  );
}

export default MembersSubMenu;
