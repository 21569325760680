import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DataTable, useDataTable } from '@novo-electronique/react-data-table';

import { AccessLevel, PermissionScope } from '@common/constants/permission';
import { IServiceCenter } from '@common/models/service-center';
import { selectUser } from '@modules/app/redux/selectors';
import { generateColumnDefinition } from '@modules/data-table/column-definition-factory';
import { DataTableSource } from '@modules/data-table/data-table-source';
import { saveServiceCenter } from '@modules/members/services/service-centers';
import Layout, { LayoutContent, LayoutHeader } from '@modules/shared/components/Layout/';
import Toolbar, { ToolbarActionType } from '@modules/shared/components/Toolbar';
import { hasPermission } from '@modules/shared/permission';
import { useQuery } from '@modules/shared/useQuery';
import { AppRoutePages } from '@src/constants';
import { useThunkDispatch } from '@src/redux/useThunkDispatch';
import ServiceCenterEditionForm from './components/ServiceCenterEditionForm';

function ServiceCentersPage() {
  const { t } = useTranslation();
  const currentUser = useSelector(selectUser);
  const dispatch = useThunkDispatch();
  const query = useQuery();
  const history = useHistory();

  const serviceCenterId = query.get('id');

  const onTableSelectionChanged = ({ id }: Partial<IServiceCenter>) => {
    history.push(`${AppRoutePages.ServiceCenters}?id=${id}`);
  };

  const { gridApi, gridProperties, exportToCsv, exportToXlsx, resetFilters, refresh } = useDataTable(
    DataTableSource.ServiceCenters,
    generateColumnDefinition(DataTableSource.ServiceCenters),
    onTableSelectionChanged,
    { options: { getRowNodeId: (data) => data.id } },
  );

  useEffect(() => {
    if (serviceCenterId) {
      gridApi?.getRowNode(serviceCenterId)?.setSelected(true);
    }
  }, [serviceCenterId]);

  const addServiceCenter = () => {
    history.push(`${AppRoutePages.ServiceCenters}?id`);
  };

  const onSubmit = (values: IServiceCenter) => {
    return dispatch(saveServiceCenter(values)).then(() => {
      history.push(AppRoutePages.ServiceCenters);
      refresh({ purge: true });
    });
  };

  const onCancel = () => {
    history.push(AppRoutePages.ServiceCenters);
  };

  const hasFullAccess = hasPermission(PermissionScope.ServiceCenter, AccessLevel.Full, currentUser);
  return (
    <Layout>
      <LayoutHeader title={t('serviceCentersPage.title')}>
        <Toolbar
          actions={{
            [ToolbarActionType.Add]: hasFullAccess && addServiceCenter,
            [ToolbarActionType.Export]: [
              { action: exportToCsv, label: 'CSV' },
              { action: exportToXlsx, label: 'XLSX' },
            ],
            [ToolbarActionType.FilterOff]: resetFilters,
            [ToolbarActionType.Refresh]: () => refresh({ purge: true }),
          }}
        />
      </LayoutHeader>

      <LayoutContent>
        <DataTable {...gridProperties} />

        {serviceCenterId !== null && (
          <ServiceCenterEditionForm
            serviceCenterId={serviceCenterId}
            readonly={!hasFullAccess}
            submitHandler={onSubmit}
            cancelHandler={onCancel}
          />
        )}
      </LayoutContent>
    </Layout>
  );
}

export default ServiceCentersPage;
