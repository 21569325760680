export enum AccessLevel {
  None = 0,
  Read,
  Full,
}

export enum PermissionScope {
  Association = 'association',
  ServiceCenter = 'service-center',
  School = 'school',
  Member = 'member',
  Membership = 'membership',
  Finance = 'finance',
  Import = 'import',
  User = 'user',
  System = 'system',
}
