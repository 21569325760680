import { DataType } from '@common/constants/data-type';
import EntityValueProvider from './entity-value-provider';
import EnumValueProvider from './enum-value-provider';
import createEnumValueProvider, { enumAllowedTypes, enumDataTypes } from './enum-value-provider-factory';
import IValueProvider from './value-provider';

type entityDataTypes = DataType.Association | DataType.ServiceCenter | DataType.User;
const entityAllowedTypes: entityDataTypes[] = [DataType.Association, DataType.ServiceCenter, DataType.User];

const jobTypeValueProvider = createEnumValueProvider(DataType.JobType);
const schoolingValueProvider = createEnumValueProvider(DataType.Schooling);
const genderValueProvider = createEnumValueProvider(DataType.Gender);
const activeStateValueProvider = createEnumValueProvider(DataType.ActiveState);
const memberStatusValueProvider = createEnumValueProvider(DataType.MemberStatus);
const roleValueProvider = createEnumValueProvider(DataType.Role);
const userRoleValueProvider = createEnumValueProvider(DataType.UserRole);
const associationTypeValueProvider = createEnumValueProvider(DataType.AssociationType);
const levelOfSchoolingValueProvider = createEnumValueProvider(DataType.LevelOfSchooling);
const membershipIssueValueProvider = createEnumValueProvider(DataType.MembershipIssue);
const membershipStatusValueProvider = createEnumValueProvider(DataType.MembershipStatus);
const membershipProcessStateValueProvider = createEnumValueProvider(DataType.MembershipProcessState);
const gricsSectionTypeValueProvider = createEnumValueProvider(DataType.GricsSectionType);
const gricsEntryImportStatusValueProvider = createEnumValueProvider(DataType.GricsEntryImportStatus);
const gricsImportStatusValueProvider = createEnumValueProvider(DataType.GricsImportStatus);
const gricsImportChangeStatusValueProvider = createEnumValueProvider(DataType.GricsImportChangeStatus);
const provinceValueProvider = createEnumValueProvider(DataType.Province);
const paymentOptionValueProvider = createEnumValueProvider(DataType.PaymentMethod);
const agDelegateStatusProvider = createEnumValueProvider(DataType.AgDelegateStatus);
const otherMemberStatusProvider = createEnumValueProvider(DataType.OtherMemberStatus);
const authorizationValueProvider = createEnumValueProvider(DataType.Authorization);
const accessLevelValueProvider = createEnumValueProvider(DataType.PermissionAccessLevel);
const permissionScopeValueProvider = createEnumValueProvider(DataType.PermissionScope);
const accountingMonthValueProvider = createEnumValueProvider(DataType.AccountingMonth);

export default IValueProvider;

export {
  accessLevelValueProvider,
  accountingMonthValueProvider,
  activeStateValueProvider,
  agDelegateStatusProvider,
  associationTypeValueProvider,
  authorizationValueProvider,
  createEnumValueProvider,
  entityAllowedTypes,
  EntityValueProvider,
  enumAllowedTypes,
  genderValueProvider,
  gricsEntryImportStatusValueProvider,
  gricsImportChangeStatusValueProvider,
  gricsImportStatusValueProvider,
  gricsSectionTypeValueProvider,
  jobTypeValueProvider,
  levelOfSchoolingValueProvider,
  membershipIssueValueProvider,
  membershipProcessStateValueProvider,
  membershipStatusValueProvider,
  memberStatusValueProvider,
  otherMemberStatusProvider,
  paymentOptionValueProvider,
  permissionScopeValueProvider,
  provinceValueProvider,
  roleValueProvider,
  schoolingValueProvider,
  userRoleValueProvider,
};
export type { entityDataTypes, enumDataTypes, EnumValueProvider };
