import { TFunction } from 'react-i18next';

export interface IEmailFilter {
  filterOperation: string;
  filterString: string;
}

export enum FilterOperation {
  Includes = 'includes',
  Excludes = 'excludes',
}

export const getEmailFilterValidationSchema = (Yup) => {
  return Yup.object({
    filterOperation: Yup.string().max(20).required(),
    filterString: Yup.string().max(250).required(),
  });
};

export const getFilterOperationString = (t: TFunction<'translation'>, filter: IEmailFilter): string => {
  return filter.filterOperation === FilterOperation.Includes
    ? t('paramsPage.update.filterOperationIncludes')
    : t('paramsPage.update.filterOperationExcludes');
};

export const generateEmailFilterOptions = (t: TFunction<'translation'>) => {
  return [
    {
      key: FilterOperation.Includes,
      label: t('paramsPage.update.filterOperationIncludes'),
      value: FilterOperation.Includes,
    },
    {
      key: FilterOperation.Excludes,
      label: t('paramsPage.update.filterOperationExcludes'),
      value: FilterOperation.Excludes,
    },
  ];
};
