import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { AccessLevel, PermissionScope } from '@common/constants/permission';
import { AppRouteSections } from '@constants/routes';
import { selectUser } from '@modules/app/redux/selectors';
import { hasPermission } from '@modules/shared/permission';
import { sectionFromPathname } from '@modules/shared/url';

interface IProps {
  to: string;
  children: string;
  section?: AppRouteSections;
  scopes?: PermissionScope[];
}

function MenuItem({ to, children, section, scopes }: IProps) {
  const location = useLocation();
  const currentUser = useSelector(selectUser);

  if (!hasPermission(scopes, AccessLevel.Read, currentUser)) {
    return null;
  }

  const isSelected = () => {
    if (section) {
      return sectionFromPathname(location.pathname) === section;
    } else {
      return location.pathname === to;
    }
  };

  const className = classNames('f4eMenu__item', { 'f4eMenu__item--selected': isSelected() });

  return (
    <li role="menuitem" className={className}>
      <Link className="f4eMenu__item__link" to={to}>
        {children}
      </Link>
    </li>
  );
}

export default MenuItem;
