// import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import i18next, { i18n } from 'i18next';

import enTranslations from '@assets/i18n/en.json';
import frTranslations from '@assets/i18n/fr.json';
import enCommon from '@common/assets/i18n/en.json';
import frCommon from '@common/assets/i18n/fr.json';
import { environment, Environments } from '@config/env';

const languages = ['en', 'fr'];

export const configureI18n = (): i18n => {
  i18next
    // // detect user language
    // // learn more: https://github.com/i18next/i18next-browser-languageDetector
    // .use(LanguageDetector)

    // pass the i18n instance to the react-i18next components.
    // Alternative use the I18nextProvider: https://react.i18next.com/components/i18nextprovider
    .use(initReactI18next)

    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      lng: 'fr',
      fallbackLng: 'fr',
      supportedLngs: languages,
      debug: environment.env === Environments.Development,
      ns: ['translations'],
      defaultNS: 'translations',
      resources: {
        en: {
          translations: {
            ...enTranslations,
            ...enCommon,
          },
        },
        fr: {
          translations: {
            ...frTranslations,
            ...frCommon,
          },
        },
      },
      interpolation: {
        escapeValue: false, // set to false for react as it escapes by default
      },
      detection: {
        order: ['localStorage', 'navigator'],

        lookupLocalStorage: `${environment.storageKey}_lang`,
        lookupFromPathIndex: 0,
      },
    });

  return i18next;
};
