import './Menu.scss';

import React, { ReactNode } from 'react';
import classNames from 'classnames';

export enum MenuStyles {
  Default = 'default',
  SubMenu = 'sub-menu',
}

interface IProps {
  children: ReactNode;
  style?: MenuStyles;
}

function Menu({ children, style = MenuStyles.Default }: IProps) {
  const className = classNames('f4eMenu', { 'f4eMenu--sub-menu': style === MenuStyles.SubMenu });

  return (
    <ol role="menu" className={className}>
      {children}
    </ol>
  );
}

export default Menu;
