import './MergeSelectionTable.scss';

import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { IMember } from '@common/models/member';
import MergeSelectionTableRow from '@modules/merge/components/MergeSelectionTableRow';

interface IProps {
  original: IMember;
  source: IMember;
  properties: (keyof IMember)[];
  onConflictsResolved?: () => void;
}

function MergeMembersTable({ original, source, properties, onConflictsResolved }: IProps) {
  const { t } = useTranslation();
  const conflictsListRef = useRef([...properties]);

  const onConflictResolved = (property: keyof IMember) => {
    conflictsListRef.current = conflictsListRef.current.filter((value) => value !== property);

    if (conflictsListRef.current.length === 0 && onConflictsResolved) {
      onConflictsResolved();
    }
  };

  return (
    <table className="f4eMergeMembersTable">
      <thead>
        <tr className="f4eMergeMembersTable__header">
          <th className="f4eMergeMembersTable__header__cell" />
          <th className="f4eMergeMembersTable__header__cell">
            <div className="f4eMergeMembersTableHeaderContent">{t('memberMergePage.type.original')}</div>
          </th>
          <th className="f4eMergeMembersTable__header__cell">
            <div className="f4eMergeMembersTableHeaderContent">{t('memberMergePage.type.source')}</div>
          </th>
          <th className="f4eMergeMembersTable__header__cell">{t('memberMergePage.type.merged')}</th>
        </tr>
      </thead>
      <tbody>
        {properties.map((property) => (
          <MergeSelectionTableRow
            key={property}
            property={property}
            original={original}
            source={source}
            onConflictResolved={() => onConflictResolved(property)}
          />
        ))}
      </tbody>
    </table>
  );
}

export default MergeMembersTable;
