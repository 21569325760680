import './ErrorMessage.scss';

import React from 'react';
import { Button, ButtonStyle } from '@novo-electronique/react-button';
import classNames from 'classnames';

import { ReactComponent as Smiley } from '@assets/illustrations/smiley-wrong.svg';

interface IProps {
  title: string;
  subTitle: string;
  description: string;
  actionLabel?: string;
  actionHandler?: () => void;
  hosted?: boolean;
}

function ErrorMessage({ title, subTitle, description, actionHandler, actionLabel, hosted }: IProps) {
  return (
    <div className={classNames('f4eErrorMessage', { 'f4eErrorMessage--hosted': hosted })}>
      <h1 className="f4eErrorMessage__title">{title}</h1>
      <h2 className="f4eErrorMessage__sub-title">{subTitle}</h2>
      <p className="f4eErrorMessage__description">{description}</p>
      {actionHandler && (
        <Button style={ButtonStyle.Primary} onClick={actionHandler}>
          {actionLabel}
        </Button>
      )}
      <Smiley className="f4eErrorMessage__glyph" />
    </div>
  );
}

export default ErrorMessage;
