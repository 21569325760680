import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  DisplayStyles,
  Form,
  FormActionButton,
  FormActionButtonType,
  FormContext,
  FormFields,
  FormSelectField,
  FormSelectFieldOptions,
} from '@novo-electronique/react-forms';
import * as Yup from 'yup';

import { AssociationType } from '@common/constants/association-type';
import { IAssociationContributionReport } from '@common/models/contribution-report';
import { getLatestBudgetYear, getLatestPeriods } from '@common/utils/period';
import { accountingMonthValueProvider } from '@common/value-provider';
import { selectAssociations } from '@modules/app/redux/selectors';
import AssociationsContributionReport from '@modules/finances/components/ContributionReport';
import { toOptions } from '@modules/members/services/associations';
import { valueProviderToOptions } from '@modules/shared/components/Form/utils';
import Initializing from '@modules/shared/components/Initializing';
import Layout, { LayoutContent, LayoutHeader } from '@modules/shared/components/Layout/';
import PeriodSelector from '@modules/shared/components/PeriodSelector';
import Toolbar, { ToolbarActionType } from '@modules/shared/components/Toolbar';
import { useThunkDispatch } from '@src/redux/useThunkDispatch';
import {
  downloadAllContributionReports,
  downloadContributionReport,
  getAssociationContributions,
} from './service';

const allAssociationsValue = 'all';

function ContributionReportPage() {
  const { t } = useTranslation();
  const dispatch = useThunkDispatch();
  const formikRef = useRef(null);

  const associations = useSelector(selectAssociations);
  const budgetYearRef = useRef(getLatestBudgetYear());

  const [reportTitle, setReportTitle] = useState<string>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAssociationSelected, setIsAssociationSelected] = useState<boolean>(false);
  const [isAccountingMonthSelected, setIsAccountingMonthSelected] = useState<boolean>(false);
  const [contributions, setContributions] = useState<
    IAssociationContributionReport | IAssociationContributionReport[]
  >(null);

  const periods = getLatestPeriods();
  const associationsOptions = toOptions(
    associations.filter((association) => association.type === AssociationType.Normal),
  );

  const generatePageTitle = (association) => {
    let associationLabel;

    if (association) {
      const selectedAssociation = associationsOptions.find((assoc) => assoc.value === association);
      associationLabel = selectedAssociation.label;
    } else {
      associationLabel = t('contributionReportPage.allAssociations');
    }

    return t('contributionReportPage.reportTitle', {
      start: periods[budgetYearRef.current].start,
      end: periods[budgetYearRef.current].end,
      association: associationLabel,
    });
  };

  const downloadReport = (contribution?: IAssociationContributionReport): Promise<void> => {
    return downloadContributionReport(periods[budgetYearRef.current].start, contribution);
  };

  const downloadReports = async (contributions: IAssociationContributionReport[]): Promise<void> => {
    return dispatch(downloadAllContributionReports(periods[budgetYearRef.current].start, contributions));
  };

  const onSubmit = ({ association, accountingMonth }): Promise<void> => {
    const associationSelected = association === allAssociationsValue ? '' : association;

    setContributions(null);
    setIsLoading(true);

    return getAssociationContributions(associationSelected, periods[budgetYearRef.current], accountingMonth)
      .then(setContributions)
      .then(() => {
        setReportTitle(generatePageTitle(associationSelected));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Layout title={reportTitle}>
      <LayoutHeader title={t('contributionReportPage.title')}>
        <PeriodSelector onChange={(value) => (budgetYearRef.current = value)} small />
        <Toolbar
          actions={{
            [ToolbarActionType.Print]: {
              action: window.print,
              disabled: contributions === null,
            },
            ...(!Array.isArray(contributions) && {
              [ToolbarActionType.Download]: {
                action: () => downloadReport(contributions),
                disabled: contributions === null,
              },
            }),
            ...(Array.isArray(contributions) && {
              [ToolbarActionType.Download]: [
                {
                  action: () => downloadReport(),
                  label: t('reports.contributions.cumulative'),
                },
                {
                  action: () => downloadReports(contributions),
                  label: `${t('reports.contributions.associations')} (${contributions.length})`,
                },
              ],
            }),
          }}
        />
      </LayoutHeader>
      <LayoutContent>
        <FormContext
          validationSchema={Yup.object({
            association: Yup.string().required(),
            accountingMonth: Yup.number().required(),
          })}
          initialValues={{ association: '', accountingMonth: '' }}
          onSubmit={onSubmit}
          formikRef={formikRef}
        >
          <div className="f4eForm f4eForm--no-required-symbol f4eForm--no-error">
            <Form displayStyle={DisplayStyles.Inline}>
              <FormFields>
                <FormSelectField
                  name="association"
                  label={t('reports.contributions.params.association')}
                  onChange={(value) => setIsAssociationSelected(value !== '')}
                >
                  <FormSelectFieldOptions
                    options={[
                      { value: allAssociationsValue, label: t('reports.contributions.all').toUpperCase() },
                      ...associationsOptions,
                    ]}
                    addEmptyOption={true}
                    keepOriginalOrder
                  />
                </FormSelectField>
                <FormSelectField
                  name="accountingMonth"
                  label={t('reports.contributions.params.accountingMonth')}
                  onChange={(value) => setIsAccountingMonthSelected(value !== '')}
                >
                  <FormSelectFieldOptions
                    options={valueProviderToOptions(accountingMonthValueProvider)}
                    keepOriginalOrder
                  />
                </FormSelectField>
                <FormActionButton
                  type={FormActionButtonType.Submit}
                  disabled={!isAssociationSelected || !isAccountingMonthSelected}
                  suppressErrorTooltip
                >
                  {t('general.actions.generate')}
                </FormActionButton>
              </FormFields>
            </Form>
          </div>
        </FormContext>

        {isLoading && <Initializing />}
        <AssociationsContributionReport associationContributions={contributions} />
      </LayoutContent>
    </Layout>
  );
}

export default ContributionReportPage;
