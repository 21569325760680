import { IAssociation } from '@common/models/association';
import { IServiceCenter } from '@common/models/service-center';
import { IUser, User } from '@common/models/user';
import IValueProvider from '@common/value-provider';
import { IBackgroundTask } from '@modules/shared/background-task';
import packageInfo from '../../../../package.json';

export enum CachedEntity {
  Association = 'association',
  ServiceCenter = 'serviceCenter',
  User = 'user',
}

export interface ICacheEntity<T> {
  valueProvider: IValueProvider;
  data: T[];
}

export interface ICache {
  isLoaded: boolean;
  association: ICacheEntity<IAssociation>;
  serviceCenter: ICacheEntity<IServiceCenter>;
  user: ICacheEntity<IUser>;
}

export interface IAppState {
  isInitialized: boolean;
  isLoading: boolean;
  backgroundTasks: IBackgroundTask[];
  version: string;
  user: User;
  cache: ICache;
}

export const initialAppState: IAppState = {
  isInitialized: false,
  isLoading: false,
  backgroundTasks: [],
  version: packageInfo.version,
  user: null,
  cache: {
    isLoaded: false,
    association: {
      valueProvider: null,
      data: [],
    },
    serviceCenter: {
      valueProvider: null,
      data: [],
    },
    user: {
      valueProvider: null,
      data: [],
    },
  },
};
