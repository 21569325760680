import React from 'react';

import { AppRoutePages } from '@constants/routes';
import MembershipConfirmationPage from './MembershipConfirmationPage';
import MembershipPage from './MembershipPage';
import MembershipUpdatePage from './MembershipUpdatePage';

export default [
  { path: AppRoutePages.Membership, component: <MembershipPage /> },
  { path: AppRoutePages.MembershipConfirmation, component: <MembershipConfirmationPage /> },
  { path: AppRoutePages.MembershipUpdate, component: <MembershipUpdatePage /> },
];
