export enum NotificationCode {
  NewMemberForm = 'new-member-form',
  NewMemberConfirmation = 'new-member-confirmation',
  NewMemberConfirmed = 'new-member-confirmed',
  NewMemberNotification = 'new-member-notification',
  NewMemberAssociation = 'new-member-association',
  MemberChangesReview = 'member-changes-review',
  MemberUpdateRequest = 'member-update-request',
  MemberServiceCenterChanged = 'member-service-center-changed',
}

export type MemberNotificationCode =
  | NotificationCode.NewMemberForm
  | NotificationCode.NewMemberConfirmation
  | NotificationCode.NewMemberConfirmed
  | NotificationCode.NewMemberAssociation
  | NotificationCode.NewMemberNotification
  | NotificationCode.MemberUpdateRequest;
