import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, ButtonStyle } from '@novo-electronique/react-button';

import { AccessLevel, PermissionScope } from '@common/constants/permission';
import { IEmailFilter } from '@common/models/email-filter';
import { formatDate, subtractYears } from '@common/utils/date';
import EmailFiltersForm from '@modules/admin/components/EmailFiltersForm';
import {
  selectActiveMembersCount,
  selectInactiveMembersCount,
  selectLastMassUpdateSendingDate,
} from '@modules/admin/redux/selectors';
import {
  updateActiveMembersCount,
  updateInactiveMembersCount,
  updateLastMassUpdateSendingDate,
  updateMassUpdateBackgroundTaskFeedback,
} from '@modules/admin/service';
import { selectBackgroundTasks, selectUser as getUser } from '@modules/app/redux/selectors';
import { archiveMembers, sendUpdateRequests } from '@modules/members/services/members';
import Layout, { LayoutContent, LayoutContentBox, LayoutHeader } from '@modules/shared/components/Layout/';
import { hasPermission } from '@modules/shared/permission';
import { BackgroundTaskType } from '@src/constants';
import { useThunkDispatch } from '@src/redux/useThunkDispatch';

const dateFormat = 'd LLLL yyyy';
const yearsCountBeforeArchive = 7;

function ParamsPage() {
  const { t } = useTranslation();
  const dispatch = useThunkDispatch();

  const currentUser = useSelector(getUser);
  const backgroundTasks = useSelector(selectBackgroundTasks);
  const activeMembersCount = useSelector(selectActiveMembersCount);
  const inactiveMembersCount = useSelector(selectInactiveMembersCount);
  const lastMassUpdateSendingDate = useSelector(selectLastMassUpdateSendingDate);

  const [sendingUpdateRequests, setSendingUpdateRequests] = useState(false);
  const [sendingArchiveRequest, setSendingArchiveRequest] = useState(false);

  const initialEmptyFilterList: Array<IEmailFilter> = [];
  const [activeEmailFilters, setActiveEmailFilters] = useState(initialEmptyFilterList);

  const archiveDate = subtractYears(new Date(), yearsCountBeforeArchive);
  const isSendingMassUpdate = backgroundTasks.some(
    (task) => task.type === BackgroundTaskType.MassEmailUpdate,
  );

  useEffect(() => {
    dispatch(updateActiveMembersCount(false, activeEmailFilters));
    dispatch(updateLastMassUpdateSendingDate());
    dispatch(updateInactiveMembersCount());
  }, []);

  useEffect(() => {
    dispatch(updateActiveMembersCount(true, activeEmailFilters));
  }, [activeEmailFilters]);

  const onSendUpdateRequests = () => {
    if (confirm(t('paramsPage.update.confirm', { count: activeMembersCount }))) {
      setSendingUpdateRequests(true);

      sendUpdateRequests(activeMembersCount, activeEmailFilters)
        .then(() => {
          dispatch(updateMassUpdateBackgroundTaskFeedback());
        })
        .finally(() => {
          setSendingUpdateRequests(false);
        });
    }
  };

  const onSendArchiveRequest = () => {
    if (confirm(t('paramsPage.archive.confirm', { count: inactiveMembersCount }))) {
      setSendingArchiveRequest(true);

      archiveMembers().finally(() => {
        dispatch(updateInactiveMembersCount(true));
        setSendingArchiveRequest(false);
      });
    }
  };

  const hasFullAccess = hasPermission(
    [PermissionScope.System, PermissionScope.Member],
    AccessLevel.Full,
    currentUser,
  );

  return (
    <Layout>
      <LayoutHeader title={t('paramsPage.title')} />
      <LayoutContent noGrid>
        <LayoutContentBox title={t('paramsPage.update.title')}>
          <p className="f4eContentBox__text">
            {t('paramsPage.update.text')}
            <strong>
              {t('paramsPage.update.lastSend', {
                date: formatDate(lastMassUpdateSendingDate, dateFormat) || 'N/A',
              })}
            </strong>
          </p>
          <EmailFiltersForm emailFilters={activeEmailFilters} setEmailFilters={setActiveEmailFilters} />
          <Button
            style={ButtonStyle.Primary}
            onClick={onSendUpdateRequests}
            isLoading={sendingUpdateRequests}
            disabled={!hasFullAccess || activeMembersCount <= 0 || isSendingMassUpdate}
          >
            {t('paramsPage.update.action', { count: activeMembersCount })}
          </Button>
        </LayoutContentBox>
        <LayoutContentBox title={t('paramsPage.archive.title')}>
          <p className="f4eContentBox__text">
            {t('paramsPage.archive.text', { date: formatDate(archiveDate, dateFormat) })}
            <strong>{t('paramsPage.archive.warning')}</strong>
          </p>
          <Button
            style={ButtonStyle.Primary}
            onClick={onSendArchiveRequest}
            isLoading={sendingArchiveRequest}
            disabled={!hasFullAccess || inactiveMembersCount <= 0}
          >
            {t('paramsPage.archive.action', { count: inactiveMembersCount, year: archiveDate.getFullYear() })}
          </Button>
        </LayoutContentBox>
      </LayoutContent>
    </Layout>
  );
}

export default ParamsPage;
