import './GricsImportDetails.scss';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { SidePanelContent, SidePanelHeader } from '@novo-electronique/react-side-panel';
import classNames from 'classnames';

import { GricsImportStatus } from '@common/constants/grics-import-status';
import { IGricsImport, IGricsSectionImportMetadata } from '@common/models/grics-import';
import { gricsImportStatusValueProvider, gricsSectionTypeValueProvider } from '@common/value-provider';
import { setAppIsLoading } from '@modules/app/redux/action';
import { getAttachmentFile } from '@modules/app/service';
import GricsImportEntriesTable from '@modules/finances/components/GricsImportEntriesTable';
import { Accordion, AccordionItem } from '@modules/shared/components/Accordion';
import SidePanel from '@modules/shared/components/SidePanel';
import StatusBox, { Status } from '@modules/shared/components/StatusBox';
import Toolbar, { ToolbarActionType } from '@modules/shared/components/Toolbar';
import { getGricsImport } from '../service';

interface IProps {
  gricsImportId: string;
  closeHandler: () => void;
}

function GricsImportDetails({ gricsImportId, closeHandler }: IProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [gricsImportDetails, setGricsImportDetails] = useState<IGricsImport>(null);

  useEffect(() => {
    dispatch(setAppIsLoading(true));
    getGricsImport(gricsImportId)
      .then((gricsImport: IGricsImport) => {
        setGricsImportDetails(gricsImport);
      })
      .finally(() => dispatch(setAppIsLoading(false)));
  }, [gricsImportId]);

  const renderSection = (metadata: IGricsSectionImportMetadata, index: number) => {
    if (!metadata) {
      return null;
    }

    const title = (
      <div className="f4eImportSectionHeader">
        <div className="f4eImportSectionHeader__name">
          <span
            className={classNames('f4eImportSectionHeader__name__status material-icons', {
              'f4eImportSectionHeader__name__status--error': metadata.errorCount > 0,
            })}
          />
          {t('gricsImportPage.details.section', {
            name: gricsSectionTypeValueProvider.label(metadata.type),
          })}
        </div>
        <small className="f4eImportSectionHeader__count">
          {t('gricsImportPage.details.transactions', { count: metadata.totalCount })}
        </small>
      </div>
    );

    const hasEntries = Array.isArray(metadata.entries) && metadata.entries.length >= 1;
    return (
      <AccordionItem key={metadata.type} value={metadata.type} title={title} active={index === 0}>
        {!hasEntries && (
          <p className="f4eImportSectionNoDetails">{t('gricsImportPage.details.noDetailsAvailable')}</p>
        )}
        {hasEntries && <GricsImportEntriesTable entries={metadata.entries} />}
      </AccordionItem>
    );
  };

  if (!gricsImportDetails) {
    return null;
  }

  const hasError = gricsImportDetails.status === GricsImportStatus.Error;
  const isInterrupted = gricsImportDetails.status === GricsImportStatus.Interrupted;
  const isPending = [GricsImportStatus.Queued, GricsImportStatus.Processing].includes(
    gricsImportDetails.status,
  );
  return (
    <SidePanel closeHandler={closeHandler}>
      <SidePanelHeader
        subTitle={
          <span className="f4eTag f4eTag--gricsImportStatus" data-value={gricsImportDetails.status}>
            {gricsImportStatusValueProvider.label(gricsImportDetails.status)}
          </span>
        }
        displayCloseButton
      >
        {gricsImportDetails.attachment?.name}
        <Toolbar
          actions={{
            [ToolbarActionType.Download]: {
              action: () =>
                getAttachmentFile(gricsImportDetails.attachment.id, gricsImportDetails.attachment?.name),
            },
          }}
        />
      </SidePanelHeader>
      <SidePanelContent>
        {isPending && (
          <StatusBox
            status={Status.Info}
            title={t('gricsImportPage.details.pendingPanel.title', {
              status: gricsImportStatusValueProvider.label(gricsImportDetails.status).toLowerCase(),
            })}
          >
            {t('gricsImportPage.details.pendingPanel.description')}
          </StatusBox>
        )}
        {hasError && (
          <StatusBox status={Status.Error} title={t('gricsImportPage.details.errorPanel.title')}>
            {t('gricsImportPage.details.errorPanel.description')}
          </StatusBox>
        )}
        {isInterrupted && (
          <StatusBox status={Status.Warning} title={t('gricsImportPage.details.interruptPanel.title')}>
            {t('gricsImportPage.details.interruptPanel.description')}
          </StatusBox>
        )}
        {!isPending && !hasError && !isInterrupted && gricsImportDetails.metadata && (
          <Accordion>
            {gricsImportDetails.metadata.map((metadata, i) => renderSection(metadata, i))}
          </Accordion>
        )}
      </SidePanelContent>
    </SidePanel>
  );
}

export default GricsImportDetails;
