import './DropdownButton.scss';

import React, { MouseEvent, ReactNode, useEffect, useState } from 'react';
import { Button, ButtonSize, ButtonStyle } from '@novo-electronique/react-button';
import classNames from 'classnames';

interface IProps {
  children: ReactNode;
  style?: ButtonStyle;
  size?: ButtonSize;
  options?: {
    label?: string;
    action: () => void | Promise<void>;
    disabled?: boolean;
  }[];
}

export default function DropdownButton({ children, size, style, options = [] }: IProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [isShowingOptions, setShowingOptions] = useState(false);

  useEffect(() => {
    const closeOptions = () => setShowingOptions(false);
    window.addEventListener('click', closeOptions);
    return () => {
      window.removeEventListener('click', closeOptions);
    };
  }, []);

  const performAction = async (action?: () => void | Promise<void>) => {
    try {
      setIsLoading(true);
      await action?.();
    } finally {
      setIsLoading(false);
    }
  };

  const showOptions = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setShowingOptions(!isShowingOptions);
  };

  const renderOptions = () => {
    const itemsClassName = classNames('f4eDropdownButton__options', {
      [`f4eDropdownButton__options--${size}`]: size,
    });

    return (
      <ul className={itemsClassName}>
        {options.map((option, i) => (
          <li key={i} className="f4eDropdownButton__options__item">
            <button
              className="f4eDropdownButton__options__item__button"
              disabled={option.disabled}
              onClick={() => performAction(option.action)}
            >
              {option.label}
            </button>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="f4eDropdownButton">
      <Button size={size} style={style} onClick={showOptions} isLoading={isLoading}>
        {children}
      </Button>
      {isShowingOptions && !isLoading && renderOptions()}
    </div>
  );
}
