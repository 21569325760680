import { IAssociation } from '@common/models/association';
import { IServiceCenter } from '@common/models/service-center';
import { IUser, User } from '@common/models/user';
import IValueProvider from '@common/value-provider';
import { ICache } from '@modules/app/redux/state';
import { IBackgroundTask } from '@modules/shared/background-task';
import { IRootState } from '@src/redux/root.state';

export const selectIsAppInitialized = (state: IRootState): boolean => state.app.isInitialized;
export const selectIsAppLoading = (state: IRootState): boolean => state.app.isLoading;
export const selectBackgroundTasks = (state: IRootState): IBackgroundTask[] => state.app.backgroundTasks;
export const selectVersion = (state: IRootState): string => state.app.version;
export const selectUser = (state: IRootState): User => state.app.user;

export const selectIsCacheLoaded = (state: IRootState): boolean => state.app.cache.isLoaded;
export const selectCache = (state: IRootState): ICache => state.app.cache;

export const selectAssociations = (state: IRootState): IAssociation[] => state.app.cache.association.data;
export const selectUsers = (state: IRootState): IUser[] => state.app.cache.user.data;
export const selectServiceCenters = (state: IRootState): IServiceCenter[] =>
  state.app.cache.serviceCenter.data;

export const selectAssociationValueProvider = (state: IRootState): IValueProvider =>
  state.app.cache.association.valueProvider;
export const selectServiceCenterValueProvider = (state: IRootState): IValueProvider =>
  state.app.cache.serviceCenter.valueProvider;
export const selectUserValueProvider = (state: IRootState): IValueProvider =>
  state.app.cache.user.valueProvider;
