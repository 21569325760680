import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DataTable, useDataTable } from '@novo-electronique/react-data-table';

import { AccessLevel, PermissionScope } from '@common/constants/permission';
import { IUser } from '@common/models/user';
import { selectUser as getUser } from '@modules/app/redux/selectors';
import { generateColumnDefinition } from '@modules/data-table/column-definition-factory';
import { DataTableSource } from '@modules/data-table/data-table-source';
import Layout, { LayoutContent, LayoutHeader } from '@modules/shared/components/Layout/';
import Toolbar, { ToolbarActionType } from '@modules/shared/components/Toolbar';
import { hasPermission } from '@modules/shared/permission';
import { useQuery } from '@modules/shared/useQuery';
import { AppRoutePages } from '@src/constants';
import { useThunkDispatch } from '@src/redux/useThunkDispatch';
import UserEditionForm from './components/UserEditionForm';
import { saveUser } from './service';

function UsersPage() {
  const { t } = useTranslation();
  const currentUser = useSelector(getUser);
  const dispatch = useThunkDispatch();
  const query = useQuery();
  const history = useHistory();

  const userId = query.get('id');

  const onTableSelectionChanged = ({ id }: IUser) => {
    history.push(`${AppRoutePages.Users}?id=${id}`);
  };

  const { gridApi, gridProperties, resetFilters, refresh } = useDataTable(
    DataTableSource.Users,
    generateColumnDefinition(DataTableSource.Users),
    onTableSelectionChanged,
    { options: { getRowNodeId: (data) => data.id } },
  );

  useEffect(() => {
    if (userId) {
      gridApi?.getRowNode(userId)?.setSelected(true);
    }
  }, [userId]);

  const onSubmit = (user: IUser) => {
    return dispatch(saveUser(user)).then(() => {
      history.push(AppRoutePages.Users);
      refresh();
    });
  };

  const onCancel = () => {
    history.push(AppRoutePages.Users);
  };

  const onAddUser = () => {
    history.push(`${AppRoutePages.Users}?id`);
  };

  const hasFullAccess = hasPermission(PermissionScope.User, AccessLevel.Full, currentUser);
  return (
    <Layout>
      <LayoutHeader title={t('usersPage.title')}>
        <Toolbar
          actions={{
            [ToolbarActionType.Add]: hasFullAccess && onAddUser,
            [ToolbarActionType.FilterOff]: resetFilters,
            [ToolbarActionType.Refresh]: () => refresh({ purge: true }),
          }}
        />
      </LayoutHeader>
      <LayoutContent>
        <DataTable {...gridProperties} />

        {userId !== null && (
          <UserEditionForm
            userId={userId}
            readonly={!hasFullAccess}
            submitHandler={onSubmit}
            cancelHandler={onCancel}
          />
        )}
      </LayoutContent>
    </Layout>
  );
}

export default UsersPage;
