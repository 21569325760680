import './StatusBox.scss';

import React, { ReactNode } from 'react';

export enum Status {
  Info = 'info',
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
}

interface IProps {
  status: Status;
  title: string;
  children: string | ReactNode | ReactNode[];
}

const StatusBox = ({ status, title, children }: IProps) => {
  return (
    <div className={`f4eStatusBox f4eStatusBox--${status}`}>
      <div className="f4eStatusBox__icon material-icons" />
      <div className="f4eStatusBox__content">
        <h3 className="f4eStatusBox__title">{title}</h3>
        {typeof children === 'string' ? <p className="f4eStatusBox__description">{children}</p> : children}
      </div>
    </div>
  );
};

export default StatusBox;
