import { ColDef } from '@ag-grid-community/core';

import { DataType } from '@common/constants/data-type';
import { IChange } from '@common/models/change';
import { ICache } from '@modules/app/redux/state';
import { generateColumnDefinition } from '@modules/data-table/column-definition-factory';
import getColumnTypes from '@modules/data-table/column-types';
import { DataTableSource } from '@modules/data-table/data-table-source';

export class ValueFormatter {
  private readonly columnDefinitions: ColDef[];
  private readonly columnTypes: { [type in DataType]: ColDef };

  constructor(datasource: DataTableSource, cache: ICache) {
    this.columnDefinitions = generateColumnDefinition(datasource) || [];
    this.columnTypes = getColumnTypes(cache);
  }

  format(property: string, value: any): string {
    if (value === undefined || value === null || value === '') {
      return '';
    }

    const columnDefinition = this.columnDefinitions.find((columnDefinition) =>
      [property, `${property}Id`, `${property}Number`].includes(columnDefinition.field),
    );
    if (columnDefinition) {
      return this.formatTypedValue(value, columnDefinition.type as DataType);
    }
    return this.formatAnyValue(value);
  }

  private formatTypedValue(value: any, type: DataType): string {
    const valueFormatter = this.columnTypes[type].valueFormatter;

    if (valueFormatter && typeof valueFormatter === 'function') {
      value = typeof value === 'object' && !Array.isArray(value) ? value.id : value;

      return valueFormatter({ value } as any);
    } else {
      return value;
    }
  }

  private formatAnyValue(value: any): string {
    if (Array.isArray(value)) {
      return value.map((v) => this.formatAnyValue(v)).join(', ');
    }
    if (value instanceof Object && value.name) {
      return value.name;
    }

    value = JSON.stringify(value).replace(/^"(.*)"$/, '$1');
    return ValueFormatter.isBoolean(value) ? this.formatTypedValue(value, DataType.Boolean) : value;
  }

  private static isBoolean(value: string): boolean {
    return [true, false].some((e) => e.toString() === value);
  }
}

interface IFormattedValues {
  current: string;
  new: string;
}

export function formatValues<T = string>(
  valueFormatter: ValueFormatter,
  change: IChange<T, any>,
): IFormattedValues {
  const property = change.property.toString();

  return {
    current: valueFormatter.format(property, change.currentValue),
    new: valueFormatter.format(property, change.newValue),
  };
}
