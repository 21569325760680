import { isDate, parseIsoDateString } from './date';

export function parseDateString(_value, originalValue): Date {
  return isDate(originalValue) || originalValue === null ? originalValue : parseIsoDateString(originalValue);
}

export function getNumberValidationSchema(Yup, min = 0, max = 999999999) {
  return Yup.number()
    .transform((value) => (isNaN(value) ? undefined : +value))
    .required()
    .min(min)
    .max(max);
}

export function getWebsiteValidationSchema(Yup, isRequired = false) {
  return Yup.string()
    .trim()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      { message: 'website', excludeEmptyString: !isRequired },
    );
}

export function getPhoneValidationSchema(Yup, isRequired = true) {
  return Yup.string()
    .trim()
    .matches(/^\d{10}$/, { message: 'phone', excludeEmptyString: !isRequired });
}
