import 'firebase/auth';

import firebase from 'firebase/app';
import { ThunkDispatch } from 'redux-thunk';

import { AuthProvider } from '@common/constants/auth-provider';
import environment from '@config/env';
import {
  disconnectActiveUser,
  setActiveUser,
  setAppInitialized,
  setAppIsLoading,
} from '@modules/app/redux/action';
import { getCurrentUser } from '@modules/app/service';
import apiClient from '@modules/shared/api-client';
import { resetIdentity, setIdentity } from './redux/action';

export function validateIdentity(email: string) {
  return (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch(setAppIsLoading(true));

    return apiClient
      .post<Partial<IValidateIdentityResponse>>('/auth/identity-challenge', { email })
      .then((result) => {
        if (result.error) {
          throw { email: 'auth/user-not-found' };
        }

        dispatch(
          setIdentity({
            uid: result.uid,
            email: result.email,
            provider: result.provider,
          }),
        );
      })
      .catch(() => {
        throw { email: 'auth/user-not-found' };
      })
      .finally(() => {
        dispatch(setAppIsLoading(false));
      });
  };
}

export function signIn(email: string, password: string) {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch(setAppIsLoading(true));
    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);

      const user = await getCurrentUser();
      dispatch(setActiveUser(user));
      dispatch(resetIdentity());
    } catch (error: any) {
      if (error.code) {
        throw { password: error.code };
      } else {
        throw error.message;
      }
    } finally {
      dispatch(setAppIsLoading(false));
    }
  };
}

export function signInMicrosoft(email: string) {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch(setAppIsLoading(true));

    const provider = new firebase.auth.OAuthProvider('microsoft.com');

    // Ref: https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-oauth2-auth-code-flow
    provider.setCustomParameters({
      prompt: 'login',
      tenant: environment.microsoftAuth.tenant,
      login_hint: email,
    });

    try {
      const credentials = await firebase.auth().signInWithPopup(provider);

      await syncUserAccountWithProvider(credentials.user.uid, email);

      const user = await getCurrentUser();
      dispatch(setActiveUser(user));
      dispatch(resetIdentity());
    } catch (error: any) {
      console.error(`[signInMicrosoft]: `, error);

      await firebase.auth().signOut();

      if (!error) {
        throw { provider: 'auth/error' };
      } else if (error.code === 'auth/popup-closed-by-user' || error.code === 'auth/user-cancelled') {
        // Ignore those errors codes
        throw { provider: '' };
      } else {
        // List of provider errors: https://firebase.google.com/docs/reference/js/firebase.auth.Auth#signinwithpopup
        throw { provider: error.code };
      }
    } finally {
      dispatch(setAppIsLoading(false));
    }
  };
}

async function syncUserAccountWithProvider(accountUid: string, email: string): Promise<void> {
  try {
    await apiClient.post('/auth/sync-user-account-with-provider', {
      accountUid,
      email,
    });
  } catch (exception) {
    console.error(`[syncUserAccountWithProvider]: `, exception);

    throw {
      code: 'auth/unable-to-sync-account-with-provider',
    };
  }
}

export function signOut() {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch(setAppIsLoading(true));
    try {
      await firebase.auth().signOut();
      dispatch(disconnectActiveUser());
    } finally {
      dispatch(setAppIsLoading(false));
    }
  };
}

export function checkIfAuthenticated() {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    try {
      const token = await getAuthToken();
      if (token) {
        dispatch(setActiveUser(await getCurrentUser()));
      } else {
        dispatch(disconnectActiveUser());
      }
    } catch (e) {
      dispatch(disconnectActiveUser());
    } finally {
      dispatch(setAppInitialized());
    }
  };
}

export async function getAuthToken(oneTime = true): Promise<string | null> {
  return new Promise((resolve) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const authToken = await user.getIdToken();
        resolve(authToken);
      } else {
        resolve(null);
      }

      if (oneTime && unsubscribe) {
        unsubscribe();
      }
    });
  });
}

interface IValidateIdentityResponse {
  uid: string;
  email: string;
  provider: AuthProvider;
  error: string;
  message: string;
  statusCode: number;
}
