export const emptyCharacter = '—';
export const timeZone = 'America/New_York';
export const initialYear = 2021;
export const financialYearFirstMonth = 6; // July
export const federationContributionPercent = 0.6556;
export const periodNumbersCount = 27;

export const acceptedFormats = ['.csv'];

export const gricsBasePath = 'grics-import';
export const gricsFileNameRegex = /^FQDE-\d{6}-\d{4}-\d{2}-\d{2}/gi;
export const gricsFilesToUploadMaxCount = 100;
