import './MemberChangeList.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ReactComponent as RightArrow } from '@assets/icons/right-arrow.svg';
import { IChange } from '@common/models/change';
import { IMember } from '@common/models/member';
import { selectCache } from '@modules/app/redux/selectors';
import { DataTableSource } from '@modules/data-table/data-table-source';
import { formatValues, ValueFormatter } from '@modules/shared/value-formatter';

interface IProps {
  changes: IChange<IMember, any>[];
}

export function MemberChangesList({ changes }: IProps) {
  const { t } = useTranslation();
  const cache = useSelector(selectCache);
  const valueFormatter = new ValueFormatter(DataTableSource.Members, cache);

  return (
    <table className="f4eMemberChangesList">
      <thead className="f4eMemberChangesList__header">
        <tr>
          <th className="f4eMemberChangesList__header__cell">{t('memberChangesPage.currentValue')}</th>
          <th className="f4eMemberChangesList__header__cell f4eMemberChangesList__header__cell--separator" />
          <th className="f4eMemberChangesList__header__cell">{t('memberChangesPage.newValue')}</th>
        </tr>
      </thead>
      <tbody className="f4eMemberChangesList__content">
        {changes.map((change) => {
          const formattedValues = formatValues(valueFormatter, change);

          return (
            <React.Fragment key={change.property}>
              <tr className="f4eMemberChangesList__content__property">
                <td>{t(`models.member.${change.property}`)}</td>
                <td />
                <td>{t(`models.member.${change.property}`)}</td>
              </tr>
              <tr className="f4eMemberChangesList__content__value">
                <td className="f4eMemberChangesList__content__value__current">{formattedValues.current}</td>
                <td className="f4eMemberChangesList__content__value__separator">
                  <RightArrow />
                </td>
                <td className="f4eMemberChangesList__content__value__new">{formattedValues.new}</td>
              </tr>
            </React.Fragment>
          );
        })}
      </tbody>
    </table>
  );
}
