import React from 'react';
import { useTranslation } from 'react-i18next';

function MembershipCompleted() {
  const { t } = useTranslation();
  return (
    <div className="f4eMembershipCompleted">
      <h2 className="f4eSimpleLayout__panel__content__title">
        <span className="material-icons f4eMembershipCompleted__icon">check_circle</span>
        {t('membershipPage.completed.title')}
      </h2>
      <p className="f4eSimpleLayout__panel__content__text">{t('membershipPage.completed.whatsNext')}</p>
    </div>
  );
}

export default MembershipCompleted;
