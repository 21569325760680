import './LayoutContentBox.scss';

import React, { ReactNode } from 'react';

interface IProps {
  title: string;
  children: ReactNode;
}

function LayoutContentBox({ children, title }: IProps) {
  return (
    <div className="f4eContentBox">
      <h2 className="f4eContentBox__title">{title}</h2>
      {children}
    </div>
  );
}

export default LayoutContentBox;
