import { ThunkDispatch } from 'redux-thunk';

import { IAssociation } from '@common/models/association';
import { IServiceCenter } from '@common/models/service-center';
import { User } from '@common/models/user';
import { flat } from '@common/utils/array';
import IValueProvider, { EntityValueProvider } from '@common/value-provider';
import { IEntityValue } from '@common/value-provider/entity-value';
import { formatUser, getUsers } from '@modules/admin/service';
import {
  setAssociationEntity,
  setCacheLoaded,
  setServiceCenterEntity,
  setUserEntity,
} from '@modules/app/redux/action';
import { CachedEntity } from '@modules/app/redux/state';
import { formatAssociation, getAssociations } from '@modules/members/services/associations';
import { formatServiceCenter, getServiceCenters } from '@modules/members/services/service-centers';
import apiClient from '@modules/shared/api-client';
import { handleServiceException } from '@modules/shared/service-error-handler';

export function getCurrentUser(): Promise<User> {
  return apiClient.get<User>('/user/current');
}

export function getAttachmentFile(id: string, fileName: string): Promise<void> {
  return apiClient.download(`/attachment/${id}/file`, fileName).catch(handleServiceException);
}

export function refreshCache(entitiesToRefresh: CachedEntity[], isSilentRefresh = false) {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    if (!isSilentRefresh) {
      dispatch(setCacheLoaded(false));
    }

    const entitiesDataFn = {
      [CachedEntity.Association]: () =>
        getAssociations().then(async (associations) => {
          const valueProvider = await getAssociationValueProvider(associations);
          dispatch(
            setAssociationEntity({
              valueProvider,
              data: associations,
            }),
          );
        }),
      [CachedEntity.ServiceCenter]: () =>
        getServiceCenters().then(async (serviceCenters) => {
          const valueProvider = await getServiceCenterValueProvider(serviceCenters);
          dispatch(
            setServiceCenterEntity({
              valueProvider,
              data: serviceCenters,
            }),
          );
        }),
      [CachedEntity.User]: () =>
        getUsers().then(async (users) => {
          const valueProvider = await getValueProvider(users.map((user) => formatUser(user)));
          dispatch(
            setUserEntity({
              valueProvider,
              data: users,
            }),
          );
        }),
    };

    try {
      await Promise.all(
        Object.keys(entitiesDataFn)
          .filter((entity) => entitiesToRefresh.includes(entity as CachedEntity))
          .map((entity) => entitiesDataFn[entity]()),
      );

      dispatch(setCacheLoaded(true));
    } catch (e) {
      console.error(e);
    }
  };
}

async function getAssociationValueProvider(associations: IAssociation[]): Promise<IValueProvider> {
  return getValueProvider(
    flat(
      associations.map((association) => [
        formatAssociation(association),
        formatAssociation(association, 'number'),
      ]),
    ),
  );
}

async function getServiceCenterValueProvider(serviceCenters: IServiceCenter[]): Promise<IValueProvider> {
  return getValueProvider(
    flat(
      serviceCenters.map((serviceCenter) => [
        formatServiceCenter(serviceCenter),
        formatServiceCenter(serviceCenter, 'number'),
      ]),
    ),
  );
}

async function getValueProvider(values: IEntityValue[]): Promise<IValueProvider> {
  const valueProvider = new EntityValueProvider(() => values);
  await valueProvider.init();

  return valueProvider;
}
