export enum AccountingMonth {
  July,
  August,
  September,
  October,
  November,
  December,
  January,
  February,
  March,
  April,
  May,
  June,
}

export const getAccountingMonthValidationSchema = (Yup) => Yup.number().min(0).max(11);
