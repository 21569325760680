import { UserRole } from '@common/constants/user';
import { IAuthAccount, IAuthAccountId } from '@common/models/auth-account';
import { IHistory } from '@common/models/history';
import { PermissionMap } from '@common/models/permission';

export interface IUser {
  id?: string;
  firstName: string;
  lastName: string;
  role: UserRole;
  email: string;
  accountIds?: IAuthAccountId[];
  permissions?: PermissionMap;
  enabled: boolean;
  history?: IHistory;
}

const getUserBaseValidationSchema = (Yup) =>
  Yup.object({
    // personal
    firstName: Yup.string().max(150).required(),
    lastName: Yup.string().max(150).required(),
    email: Yup.string().email().required(),
    role: Yup.string().required(),
    enabled: Yup.boolean(),
  });

export const getUserValidationSchema = (Yup) => getUserBaseValidationSchema(Yup);

export const getUserInitialValues = (): IUser => ({
  firstName: '',
  lastName: '',
  email: '',
  role: null,
  enabled: true,
});

export type User = Partial<IUser> & IAuthAccount;
