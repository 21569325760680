import React, { useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, ButtonStyle } from '@novo-electronique/react-button';
import {
  DisplayStyles,
  Form,
  FormActionButton,
  FormActionButtonType,
  FormContext,
  FormDateField,
  FormFields,
  FormTextField,
} from '@novo-electronique/react-forms';
import * as Yup from 'yup';

import { federationContributionPercent } from '@common/constants/global';
import { AccessLevel, PermissionScope } from '@common/constants/permission';
import {
  getAssociationFinancialInfoValidationSchema,
  IAssociationFinancialInfo,
} from '@common/models/association-financial-info';
import { accountantRound } from '@common/utils/currency';
import { formatDate } from '@common/utils/date';
import { selectUser } from '@modules/app/redux/selectors';
import {
  addAssociationFinancialInformation,
  deleteAssociationFinancialInformation,
  getAssociationFinancialInformation,
} from '@modules/members/services/associations';
import { hasPermission } from '@modules/shared/permission';
import reducer, {
  addFinancialInfoAction,
  financialInfoReceivedAction,
  removeFinancialInfoAction,
} from './association-finance-form-reducer';

interface IProps {
  associationId: string;
}

const initialState: IAssociationFinancialInfo[] = [];

function AssociationFinanceForm({ associationId }: IProps) {
  const currentUser = useSelector(selectUser);
  const [state, dispatch] = useReducer(reducer, initialState); // state is not typed :(
  const { t } = useTranslation();

  const hasFullAccess =
    hasPermission(PermissionScope.Association, AccessLevel.Read, currentUser) &&
    hasPermission(PermissionScope.Finance, AccessLevel.Full, currentUser);

  const onAdd = (values: IAssociationFinancialInfo) => {
    return addAssociationFinancialInformation(associationId, values).then(
      (financialInformation: IAssociationFinancialInfo) => {
        dispatch(addFinancialInfoAction(financialInformation));
      },
    );
  };

  const onRemove = (financialInfoId: string) => {
    if (confirm(t('associationsPage.financialInfo.confirmDelete'))) {
      deleteAssociationFinancialInformation(associationId, financialInfoId).then(() => {
        dispatch(removeFinancialInfoAction(financialInfoId));
      });
    }
  };

  useEffect(() => {
    getAssociationFinancialInformation(associationId).then(
      (financialInformation: IAssociationFinancialInfo[]) => {
        return dispatch(financialInfoReceivedAction(financialInformation));
      },
    );
  }, [associationId]);

  const renderList = (associationFinancialInfo: IAssociationFinancialInfo[]) => {
    return (
      <table className="f4eTable f4eTable--centered">
        <thead>
          <tr>
            <th>{t('associationsPage.financialInfo.effectiveDate')}</th>
            <th>{t('associationsPage.financialInfo.contributionPercent')}</th>
            <th>{t('associationsPage.financialInfo.sharePercent')}</th>
            <th>{t('associationsPage.financialInfo.federationPercent')}</th>
            {hasFullAccess && <th />}
          </tr>
        </thead>
        <tbody>
          {associationFinancialInfo.length === 0 && (
            <tr>
              <td colSpan={hasFullAccess ? 5 : 4} className="f4eTable__row__cell--empty-notification">
                {t('associationsPage.financialInfo.noFinancialInfo')}
              </td>
            </tr>
          )}
          {associationFinancialInfo.map((financialInfo: IAssociationFinancialInfo) => (
            <tr key={financialInfo.id}>
              <td>{formatDate(financialInfo.effectiveDate)}</td>
              <td>{financialInfo.contributionPercent}</td>
              <td>{accountantRound(financialInfo.sharePercent * 100, 2)}%</td>
              <td>{financialInfo.federationPercent}</td>
              {hasFullAccess && (
                <td>
                  <Button style={ButtonStyle.Stealth} onClick={() => onRemove(financialInfo.id)}>
                    <span className="material-icons f4eSlideInPanel__header__actions__icon">close</span>
                  </Button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const renderForm = () => {
    return (
      <FormContext
        validationSchema={getAssociationFinancialInfoValidationSchema(Yup)}
        initialValues={{ effectiveDate: '', federationPercent: federationContributionPercent }}
        onSubmit={onAdd}
      >
        <Form displayStyle={DisplayStyles.Inline}>
          <FormFields>
            <FormTextField
              name="contributionPercent"
              label={t('associationsPage.financialInfo.contributionPercent')}
            />
            <FormTextField
              name="federationPercent"
              label={t('associationsPage.financialInfo.federationPercent')}
            />
            <FormDateField name="effectiveDate" label={t('associationsPage.financialInfo.effectiveDate')} />
            <FormActionButton type={FormActionButtonType.Submit}>{t('general.actions.add')}</FormActionButton>
          </FormFields>
        </Form>
      </FormContext>
    );
  };

  return (
    <div>
      {hasFullAccess && renderForm()}
      {renderList(state)}
    </div>
  );
}

export default AssociationFinanceForm;
