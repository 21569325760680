import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DataTable, useDataTable } from '@novo-electronique/react-data-table';

import { AccessLevel, PermissionScope } from '@common/constants/permission';
import { IMember } from '@common/models/member';
import { selectUser } from '@modules/app/redux/selectors';
import { generateColumnDefinition } from '@modules/data-table/column-definition-factory';
import { DataTableSource } from '@modules/data-table/data-table-source';
import Layout, { LayoutContent, LayoutHeader } from '@modules/shared/components/Layout/';
import Toolbar, { ToolbarActionType } from '@modules/shared/components/Toolbar';
import { hasPermission } from '@modules/shared/permission';
import { useQuery } from '@modules/shared/useQuery';
import { AppRoutePages } from '@src/constants';
import MemberEditionForm from './components/MemberEditionForm';
import { saveMember } from './services/members';

function MembersPage() {
  const { t } = useTranslation();
  const currentUser = useSelector(selectUser);
  const query = useQuery();
  const history = useHistory();

  const memberId = query.get('id');

  const onTableSelectionChanged = ({ id }: Partial<IMember>) => {
    history.push(`${AppRoutePages.Members}?id=${id}`);
  };

  const { gridApi, gridProperties, exportToCsv, exportToXlsx, resetFilters, refresh } = useDataTable(
    DataTableSource.Members,
    generateColumnDefinition(DataTableSource.Members),
    onTableSelectionChanged,
    { options: { enableGrouping: true } },
  );

  useEffect(() => {
    if (memberId) {
      gridApi?.getRowNode(memberId)?.setSelected(true);
    }
  }, [memberId]);

  const onSubmit = (values: IMember) => {
    return saveMember(values).then(() => {
      history.push(AppRoutePages.Members);
      refresh({ purge: true });
    });
  };

  const onCancel = () => {
    history.push(AppRoutePages.Members);
  };

  const hasFullAccess = hasPermission(PermissionScope.Member, AccessLevel.Full, currentUser);
  const hasFinanceAccess = hasPermission(PermissionScope.Finance, AccessLevel.Read, currentUser);

  return (
    <Layout>
      <LayoutHeader title={t('membersPage.title')}>
        <Toolbar
          actions={{
            [ToolbarActionType.Export]: [
              { action: exportToCsv, label: 'CSV' },
              { action: exportToXlsx, label: 'XLSX' },
            ],
            [ToolbarActionType.FilterOff]: resetFilters,
            [ToolbarActionType.Refresh]: () => refresh({ purge: true }),
          }}
        />
      </LayoutHeader>

      <LayoutContent>
        <DataTable {...gridProperties} />

        {memberId !== null && (
          <MemberEditionForm
            memberId={memberId}
            readonly={!hasFullAccess}
            isShowingContribution={hasFinanceAccess}
            submitHandler={onSubmit}
            cancelHandler={onCancel}
            refreshHandler={() => refresh({ purge: true })}
          />
        )}
      </LayoutContent>
    </Layout>
  );
}

export default MembersPage;
