import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

interface IProps {
  email: string;
}

function MembershipConfirmation({ email }: IProps) {
  const { t } = useTranslation();
  return (
    <>
      <h2 className="f4eSimpleLayout__panel__content__title">{t('membershipPage.confirmation.title')}</h2>
      <p className="f4eSimpleLayout__panel__content__text">
        <Trans i18nKey="membershipPage.confirmation.whatsNext" values={{ email }}>
          <br />
          <strong />
        </Trans>
      </p>
      <p className="f4eMembershipEmphasis">{t('membershipPage.confirmation.instructions')}</p>
      <p className="f4eSimpleLayout__panel__content__text">
        <Trans i18nKey="membershipPage.confirmation.checkSpam">
          <i />
        </Trans>
      </p>
    </>
  );
}

export default MembershipConfirmation;
