import './EmailFiltersForm.scss';

import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DisplayStyles,
  Form,
  FormActionButton,
  FormActionButtonType,
  FormContext,
  FormFields,
  FormSelectField,
  FormSelectFieldOptions,
  FormTextField,
} from '@novo-electronique/react-forms';
import classNames from 'classnames';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
  generateEmailFilterOptions,
  getEmailFilterValidationSchema,
  getFilterOperationString,
  IEmailFilter,
} from '@common/models/email-filter';

interface IProps {
  emailFilters: IEmailFilter[];
  setEmailFilters: Dispatch<SetStateAction<IEmailFilter[]>>;
}

function EmailFiltersForm({ emailFilters, setEmailFilters }: IProps) {
  const { t } = useTranslation();
  const formikRef = useRef<ReturnType<typeof useFormik>>();
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (isDisabled) {
      formikRef.current.resetForm();
      setEmailFilters([]);
    }
  }, [isDisabled]);

  const filterEmailsSubmit = (filter: IEmailFilter) => {
    return new Promise<void>((res) => {
      if (!emailFilters.some((elem) => elem === filter)) {
        setEmailFilters((oldFilters) => [...oldFilters, filter]);
      }
      res();
    });
  };

  const onRemoveActiveFilter = (filter: IEmailFilter) => {
    return new Promise<void>((res) => {
      setEmailFilters((oldFilterList) => {
        return oldFilterList.filter((elem) => {
          return elem !== filter;
        });
      });
      res();
    });
  };

  const renderBadge = (filter: IEmailFilter, key: number) => {
    const className = classNames('f4eEmailFilterBadge', {
      'f4eEmailFilterBadge--disabled': isDisabled,
    });
    return (
      <p key={key} className={className}>
        {getFilterOperationString(t, filter)}&nbsp;<b>{filter.filterString}</b>
        <button
          className="f4eEmailFilterBadge__button"
          onClick={!isDisabled && (() => onRemoveActiveFilter(filter))}
        >
          <span className="material-icons">close</span>
        </button>
      </p>
    );
  };

  return (
    <div className="f4eEmailFilters">
      <FormContext
        formikRef={formikRef}
        onSubmit={filterEmailsSubmit}
        initialValues={{}}
        validationSchema={getEmailFilterValidationSchema(Yup)}
      >
        <Form readonly={false} displayStyle={DisplayStyles.Inline}>
          <label className="f4eEmailFilters__label">
            <input
              className="f4eEmailFilters__label__checkbox"
              type="checkbox"
              onClick={() => setIsDisabled(!isDisabled)}
            />
            {t('paramsPage.update.filterOperationTitle')}
          </label>
          <FormFields disabled={isDisabled}>
            <div className="f4eEmailFilters__row">
              <FormSelectField
                name="filterOperation"
                label={t('paramsPage.update.filterOperationSelectorLabel')}
                width={25}
              >
                <FormSelectFieldOptions options={generateEmailFilterOptions(t)} keepOriginalOrder />
              </FormSelectField>
              <FormTextField
                name="filterString"
                label={t('paramsPage.update.filterOperationMatchLabel')}
                width={25}
              />
              <FormActionButton type={FormActionButtonType.Submit} suppressErrorTooltip disabled={isDisabled}>
                <span className="material-icons">search</span>
              </FormActionButton>
            </div>

            {emailFilters.length > 0 && (
              <div className="f4eEmailFilters__row f4eEmailFilters__row--badges">
                {emailFilters.map(renderBadge)}
              </div>
            )}
          </FormFields>
        </Form>
      </FormContext>
    </div>
  );
}

export default EmailFiltersForm;
