import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DataTable, useDataTable } from '@novo-electronique/react-data-table';

import { AccessLevel, PermissionScope } from '@common/constants/permission';
import { IMembership } from '@common/models/membership';
import { selectUser } from '@modules/app/redux/selectors';
import { generateColumnDefinition } from '@modules/data-table/column-definition-factory';
import { DataTableSource } from '@modules/data-table/data-table-source';
import { updateMembership } from '@modules/membership/service';
import Layout, { LayoutContent, LayoutHeader } from '@modules/shared/components/Layout/';
import Toolbar, { ToolbarActionType } from '@modules/shared/components/Toolbar';
import { hasPermission } from '@modules/shared/permission';
import { useQuery } from '@modules/shared/useQuery';
import { AppRoutePages } from '@src/constants';
import MembershipEditionForm from './components/MembershipEditionForm';

function MembershipsPage() {
  const { t } = useTranslation();
  const currentUser = useSelector(selectUser);
  const query = useQuery();
  const history = useHistory();

  const membershipId = query.get('id');

  const onTableSelectionChanged = ({ id }: Partial<IMembership>) => {
    history.push(`${AppRoutePages.Memberships}?id=${id}`);
  };

  const { gridApi, gridProperties, exportToCsv, exportToXlsx, resetFilters, refresh } = useDataTable(
    DataTableSource.Memberships,
    generateColumnDefinition(DataTableSource.Memberships),
    onTableSelectionChanged,
    { options: { getRowNodeId: (data) => data.id } },
  );

  useEffect(() => {
    if (membershipId) {
      gridApi?.getRowNode(membershipId)?.setSelected(true);
    }
  }, [membershipId]);

  const onSubmit = (values: IMembership) => {
    return updateMembership(values.id, values.status, values.externalAccountCreated, values.notes).then(
      () => {
        history.push(AppRoutePages.Memberships);
        refresh({ purge: true });
      },
    );
  };

  const onCancel = () => {
    history.push(AppRoutePages.Memberships);
  };

  const hasFullAccess = hasPermission(PermissionScope.Membership, AccessLevel.Full, currentUser);
  return (
    <Layout>
      <LayoutHeader title={t('membershipsPage.title')}>
        <Toolbar
          actions={{
            [ToolbarActionType.Export]: [
              { action: exportToCsv, label: 'CSV' },
              { action: exportToXlsx, label: 'XLSX' },
            ],
            [ToolbarActionType.FilterOff]: resetFilters,
            [ToolbarActionType.Refresh]: () => refresh({ purge: true }),
          }}
        />
      </LayoutHeader>

      <LayoutContent>
        <DataTable {...gridProperties} />
        {membershipId !== null && (
          <MembershipEditionForm
            membershipId={membershipId}
            readonly={!hasFullAccess}
            cancelHandler={onCancel}
            submitHandler={onSubmit}
          />
        )}
      </LayoutContent>
    </Layout>
  );
}

export default MembershipsPage;
