import './GricsImportEntriesTable.scss';

import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@novo-electronique/react-tooltip';
import classNames from 'classnames';

import { GricsEntryImportStatus } from '@common/constants/grics-entry-import-status';
import { GricsEntryImportMetadata } from '@common/models/grics-import';
import { formatCurrency } from '@common/utils/currency';
import { gricsEntryImportStatusValueProvider } from '@common/value-provider';
import { getStatusCodeAndMessageFromError } from '@modules/shared/service-error-handler';

interface IProps {
  entries: GricsEntryImportMetadata[];
}

const contributionPropertiesRegex = /contribution$/gi;
const statusProperty = 'status';
const excludedProperties = [statusProperty, 'sin', 'error'];

const getProperties = (entry: GricsEntryImportMetadata): (keyof GricsEntryImportMetadata)[] => {
  return [...Object.keys(entry).filter((key) => !excludedProperties.includes(key)), statusProperty];
};

function GricsImportEntriesTable({ entries }: IProps) {
  const { t } = useTranslation();

  const getCellClass = (property: keyof GricsEntryImportMetadata): string | undefined => {
    return classNames('f4eGricsImportEntriesTable__row__cell', {
      'f4eGricsImportEntriesTable__row__cell--status': property === statusProperty,
      'f4eGricsImportEntriesTable__row__cell--amount': (property as string).match(
        contributionPropertiesRegex,
      ),
    });
  };

  const getCellValue = (
    property: keyof GricsEntryImportMetadata,
    value: any,
    entry: GricsEntryImportMetadata,
  ) => {
    if ((property as string).match(contributionPropertiesRegex)) {
      return formatCurrency(value);
    } else if (property === statusProperty) {
      return <GricsEntryImportStatusCell entry={entry} />;
    } else {
      return value;
    }
  };

  const renderEntry = (entry: GricsEntryImportMetadata) => {
    const className = classNames('f4eGricsImportEntriesTable__row', {
      'f4eGricsImportEntriesTable__row--error': entry.status === GricsEntryImportStatus.Error,
    });
    return (
      <tr key={entry.id} className={className}>
        {getProperties(entry).map((property) => {
          const value = entry[property];
          return (
            <td
              key={property}
              className={getCellClass(property)}
              data-value={property === statusProperty ? value : undefined}
            >
              {getCellValue(property, value, entry)}
            </td>
          );
        })}
      </tr>
    );
  };

  if (!Array.isArray(entries) || entries.length <= 0) {
    return null;
  }
  return (
    <table className="f4eGricsImportEntriesTable">
      <thead>
        <tr>
          {getProperties(entries[0]).map((property) => (
            <th key={property} className={getCellClass(property)}>
              {t(`gricsImportPage.details.columns.${property}`)}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>{entries.map((entry) => renderEntry(entry))}</tbody>
    </table>
  );
}

function GricsEntryImportStatusCell({ entry }: { entry: GricsEntryImportMetadata }) {
  const ref = useRef();

  return (
    <React.Fragment>
      {gricsEntryImportStatusValueProvider.label(entry.status)}
      {entry.error && (
        <>
          <Tooltip targetRef={ref}>{getStatusCodeAndMessageFromError(entry.error).message}</Tooltip>
          <i className="f4eGricsImportErrorTooltip material-icons" ref={ref}>
            info
          </i>
        </>
      )}
    </React.Fragment>
  );
}

export default GricsImportEntriesTable;
