import React from 'react';

import { IServiceCenterContributionReport } from '@common/models/contribution-report';
import ContributionReport from '@modules/reports/ContributionReport';
import { serviceCentersContributionsToSummaryReport } from '@modules/reports/service';

interface IProps {
  serviceCenters: IServiceCenterContributionReport[];
}

function ServiceCentersContributionsSummary({ serviceCenters }: IProps) {
  if (serviceCenters.length < 1) return null;

  const report = serviceCentersContributionsToSummaryReport(serviceCenters);

  return (
    <section className="f4eReport__content f4eReport__content--no-break">
      <ContributionReport report={report} />
    </section>
  );
}

export default ServiceCentersContributionsSummary;
