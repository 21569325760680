import {
  getNumberValidationSchema,
  getPhoneValidationSchema,
  parseDateString,
} from '../utils/validation-schema';
import { IAssociation } from './association';
import { IHistory } from './history';
import { IMember } from './member';

export interface IServiceCenter {
  id?: string;
  number: number;
  name: string;
  address: string;
  city: string;
  postalCode: string;
  phoneNumber: string;
  dateOfIntegration: Date;
  domain: string;
  association?: Partial<IAssociation>;
  members?: IMember[];
  history?: IHistory;
}

export const getServiceCenterValidationSchema = (Yup) =>
  Yup.object({
    number: getNumberValidationSchema(Yup, 1),
    name: Yup.string().max(250).required(),
    address: Yup.string().max(250),
    city: Yup.string().max(250),
    postalCode: Yup.string().max(20),
    phoneNumber: getPhoneValidationSchema(Yup, false),
    dateOfIntegration: Yup.date().nullable().transform(parseDateString).max(new Date()),
    domain: Yup.string().max(250),
    association: Yup.object({ id: Yup.string().required() }).required(),
  });

export const getServiceCenterInitialValues = (): IServiceCenter => ({
  number: null,
  name: '',
  address: '',
  city: '',
  postalCode: '',
  phoneNumber: '',
  dateOfIntegration: null,
  domain: '',
  association: { id: null },
});
