import React from 'react';

import { AppRoutePages } from '@constants/routes';
import ParamsPage from './ParamsPage';
import UsersPage from './UsersPage';

export default [
  { path: AppRoutePages.Users, component: <UsersPage /> },
  { path: AppRoutePages.Params, component: <ParamsPage /> },
];
