import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonSize, ButtonStyle } from '@novo-electronique/react-button';
import {
  Form,
  FormActionButton,
  FormActionButtonType,
  FormContext,
  FormFields,
  FormMultiSelectField,
  FormSelectField,
  FormSelectFieldOptions,
  IFormErrors,
} from '@novo-electronique/react-forms';
import {
  SidePanelContent,
  SidePanelFooter,
  SidePanelFooterActions,
  SidePanelHeader,
  SidePanelHeaderNav,
} from '@novo-electronique/react-side-panel';
import { Tab, TabContext, TabList, TabPanel } from '@novo-electronique/react-tabs';
import * as Yup from 'yup';

import { MembershipProcessState, MembershipStatus } from '@common/constants/membership';
import { NotificationCode } from '@common/constants/notification';
import { getMemberValidationSchema, IMember } from '@common/models/member';
import { getAllowedCodes } from '@common/utils/notification';
import { authorizationValueProvider } from '@common/value-provider';
import { setAppIsLoading } from '@modules/app/redux/action';
import { selectServiceCenterValueProvider } from '@modules/app/redux/selectors';
import { DataTableSource } from '@modules/data-table/data-table-source';
import History from '@modules/history/History';
import MemberInfoSections from '@modules/members/components/MemberInfoSections';
import { statusOptions as membershipStatusOptions } from '@modules/membership/service';
import MergeFormModal from '@modules/merge/MergeFormModal';
import { valueProviderToOptions } from '@modules/shared/components/Form/utils';
import SidePanel from '@modules/shared/components/SidePanel';
import {
  delegateStatusOptions,
  getMember,
  otherMemberStatusOptions,
  sendNotification,
} from '../services/members';
import MemberContributions from './MemberContributions';

interface IProps {
  memberId: string;
  readonly: boolean;
  isShowingContribution: boolean;
  submitHandler: (values: IMember, setErrors?: (errors: IFormErrors) => void) => Promise<void>;
  cancelHandler: () => void;
  refreshHandler?: () => void;
}

enum HeaderTabs {
  Member = 'member',
  Membership = 'membership',
  Contributions = 'contributions',
  Others = 'other',
  History = 'history',
}

function MemberEditionForm({
  memberId,
  readonly,
  isShowingContribution,
  submitHandler,
  cancelHandler,
  refreshHandler,
}: IProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const serviceCenterValueProvider = useSelector(selectServiceCenterValueProvider);

  const [initialValues, setInitialValues] = useState<IMember>(null);
  const [selectedTab, setSelectedTab] = useState<HeaderTabs>();
  const [subTitle, setSubTitle] = useState(' ');

  const [sendingUpdateRequest, setSendingUpdateRequest] = useState<boolean>(false);
  const [isShowingMergeModal, setIsShowingMergeModal] = useState<boolean>(false);
  const [isForcingContributionsUpdate, setIsForcingContributionsUpdate] = useState<boolean>(false);

  useEffect(() => {
    dispatch(setAppIsLoading(true));
    getMember(memberId)
      .then((member) => {
        if (member.serviceCenter) {
          setSubTitle(serviceCenterValueProvider.label(member.serviceCenter.id));
        }
        setInitialValues({ ...member, serviceCenter: { id: member.serviceCenter?.id } });
      })
      .finally(() => dispatch(setAppIsLoading(false)));
  }, [memberId]);

  const onUpdateRequest = () => {
    if (confirm(t('membersPage.confirmUpdate'))) {
      setSendingUpdateRequest(true);

      const code =
        initialValues.membership?.processState === MembershipProcessState.PendingForm
          ? NotificationCode.NewMemberForm
          : NotificationCode.MemberUpdateRequest;

      return sendNotification(initialValues.id, code, {
        successKey: 'membersPage.updateRequestedSuccessfully',
        errorKey: 'membersPage.updateRequestFailed',
      }).finally(() => setSendingUpdateRequest(false));
    }
  };

  const getMembershipStatusOptionLabel = (status?: MembershipStatus) => {
    if (!status) return '';

    const membershipStatusOption = membershipStatusOptions().find(
      (option) => option.value === initialValues.membership?.status,
    );

    return membershipStatusOption ? membershipStatusOption.label : '';
  };

  if (!initialValues) {
    return null;
  }

  const canSendUpdateRequest =
    getAllowedCodes(initialValues).includes(NotificationCode.NewMemberForm) ||
    getAllowedCodes(initialValues).includes(NotificationCode.MemberUpdateRequest);

  return (
    <>
      <FormContext
        initialValues={initialValues}
        validationSchema={getMemberValidationSchema(Yup)}
        onSubmit={submitHandler}
        enableReinitialize
      >
        <Form readonly={readonly}>
          <SidePanel closeHandler={cancelHandler}>
            <TabContext onChange={(value) => setSelectedTab(value as HeaderTabs)}>
              <SidePanelHeader subTitle={subTitle} displayCloseButton={true}>
                {initialValues.firstName} {initialValues.lastName}
              </SidePanelHeader>
              <SidePanelHeaderNav isHost>
                <TabList>
                  <Tab value={HeaderTabs.Member} active>
                    {t('membersPage.tabs.member')}
                  </Tab>
                  <Tab value={HeaderTabs.Membership}>{t('membersPage.tabs.membership')}</Tab>
                  <Tab value={HeaderTabs.Contributions}>{t('membersPage.tabs.contributions')}</Tab>
                  <Tab value={HeaderTabs.Others}>{t('membersPage.tabs.others')}</Tab>
                  <Tab value={HeaderTabs.History}>{t('membersPage.tabs.history')}</Tab>
                </TabList>
              </SidePanelHeaderNav>
              <SidePanelContent>
                <TabPanel value={HeaderTabs.Member}>
                  <MemberInfoSections />
                </TabPanel>

                <TabPanel value={HeaderTabs.Membership}>
                  <dl className="f4eDefinitions">
                    <dt className="f4eDefinitions__part f4eDefinitions__part--term">
                      {t('models.membership.status')}
                    </dt>
                    <dd className="f4eDefinitions__part">
                      {getMembershipStatusOptionLabel(initialValues.membership?.status)}
                    </dd>

                    <dt className="f4eDefinitions__part f4eDefinitions__part--term">
                      {t('models.membership.externalAccountCreated')}
                    </dt>
                    <dd className="f4eDefinitions__part">
                      {initialValues.membership?.externalAccountCreated ? t('general.yes') : t('general.no')}
                    </dd>
                  </dl>
                </TabPanel>

                {isShowingContribution && (
                  <TabPanel value={HeaderTabs.Contributions}>
                    <MemberContributions
                      member={initialValues}
                      isForcingUpdate={isForcingContributionsUpdate}
                    />
                  </TabPanel>
                )}

                <TabPanel value={HeaderTabs.Others}>
                  <FormFields>
                    <FormSelectField name="agDelegateStatus" label={t('models.member.agDelegateStatus')}>
                      <FormSelectFieldOptions options={delegateStatusOptions()} />
                    </FormSelectField>

                    <FormMultiSelectField
                      name="otherStatuses"
                      label={t('models.member.otherStatuses')}
                      options={otherMemberStatusOptions()}
                      width={100}
                    />
                  </FormFields>
                  <FormFields>
                    <FormSelectField
                      name="ackAuthorizePersonalInfo"
                      label={t('models.member.ackAuthorizePersonalInfo')}
                    >
                      <FormSelectFieldOptions options={valueProviderToOptions(authorizationValueProvider)} />
                    </FormSelectField>
                  </FormFields>
                </TabPanel>

                <TabPanel value={HeaderTabs.History}>
                  <History
                    id={initialValues.history?.id}
                    datasource={DataTableSource.Members}
                    hidden={selectedTab !== HeaderTabs.History}
                    excludedProperties={['sin', 'updateRequestDate', 'lastServiceCenterChangeDate']}
                  />
                </TabPanel>
              </SidePanelContent>

              {!readonly && selectedTab !== HeaderTabs.History && (
                <SidePanelFooter>
                  <SidePanelFooterActions>
                    <FormActionButton type={FormActionButtonType.Submit} small={true}>
                      {t('general.forms.save')}
                    </FormActionButton>
                    {canSendUpdateRequest && (
                      <Button
                        style={ButtonStyle.Normal}
                        size={ButtonSize.Small}
                        onClick={onUpdateRequest}
                        isLoading={sendingUpdateRequest}
                      >
                        {t('general.actions.requestUpdate')}
                      </Button>
                    )}
                    <Button
                      style={ButtonStyle.Normal}
                      size={ButtonSize.Small}
                      onClick={() => setIsShowingMergeModal(true)}
                    >
                      {t('general.actions.merge')}
                    </Button>
                  </SidePanelFooterActions>
                  <SidePanelFooterActions isSecondary={true}>
                    <FormActionButton type={FormActionButtonType.Reset} small={true} />
                  </SidePanelFooterActions>
                </SidePanelFooter>
              )}
            </TabContext>
          </SidePanel>
        </Form>
      </FormContext>
      {isShowingMergeModal && (
        <MergeFormModal
          member={initialValues}
          onClose={() => setIsShowingMergeModal(false)}
          onMerge={(mergeMember) => {
            setInitialValues(mergeMember);
            setIsForcingContributionsUpdate(isForcingContributionsUpdate);
            refreshHandler && refreshHandler();
          }}
        />
      )}
    </>
  );
}

export default MemberEditionForm;
