import React from 'react';

import { IServiceCenterContributionReport } from '@common/models/contribution-report';
import ContributionReport from '@modules/reports/ContributionReport';
import { serviceCenterContributionsToReport } from '@modules/reports/service';

interface IProps {
  serviceCenterContributions: IServiceCenterContributionReport;
}

function ServiceCenterContributions({ serviceCenterContributions }: IProps) {
  if (!serviceCenterContributions) return null;

  const report = serviceCenterContributionsToReport(serviceCenterContributions);

  if (report.details.length === 0) return null;

  return (
    <section className="f4eReport__content">
      <ContributionReport report={report} />
    </section>
  );
}

export default ServiceCenterContributions;
