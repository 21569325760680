import { AccountingMonth } from '@common/constants/accounting-month';
import { AssociationType } from '@common/constants/association-type';
import { GricsEntryImportStatus } from '@common/constants/grics-entry-import-status';
import { GricsImportStatus } from '@common/constants/grics-import-status';
import { GricsSectionType } from '@common/constants/grics-section-type';
import { LevelOfSchooling } from '@common/constants/level-of-schooling';
import {
  ActiveState,
  AGDelegateStatus,
  Authorization,
  Gender,
  JobType,
  MemberStatus,
  OtherMemberStatus,
  Province,
  Role,
  Schooling,
} from '@common/constants/member';
import { MemberChangesStatus } from '@common/constants/member-changes-status';
import { MembershipIssue, MembershipProcessState, MembershipStatus } from '@common/constants/membership';
import { PaymentMethod } from '@common/constants/payment-method';
import { AccessLevel, PermissionScope } from '@common/constants/permission';
import { UserRole } from '@common/constants/user';
import EnumValueProvider from '@common/value-provider/enum-value-provider';
import IValueProvider from '@common/value-provider/value-provider';
import { DataType } from '../constants/data-type';

export type enumDataTypes =
  | DataType.Gender
  | DataType.ActiveState
  | DataType.MemberStatus
  | DataType.JobType
  | DataType.Schooling
  | DataType.Role
  | DataType.UserRole
  | DataType.AssociationType
  | DataType.LevelOfSchooling
  | DataType.MembershipIssue
  | DataType.MembershipStatus
  | DataType.GricsSectionType
  | DataType.GricsEntryImportStatus
  | DataType.GricsImportStatus
  | DataType.GricsImportChangeStatus
  | DataType.MembershipProcessState
  | DataType.Province
  | DataType.PaymentMethod
  | DataType.AgDelegateStatus
  | DataType.OtherMemberStatus
  | DataType.Authorization
  | DataType.PermissionAccessLevel
  | DataType.PermissionScope
  | DataType.AccountingMonth;

export const enumAllowedTypes: Array<enumDataTypes> = [
  DataType.Gender,
  DataType.ActiveState,
  DataType.MemberStatus,
  DataType.JobType,
  DataType.Schooling,
  DataType.Role,
  DataType.UserRole,
  DataType.AssociationType,
  DataType.LevelOfSchooling,
  DataType.MembershipIssue,
  DataType.MembershipStatus,
  DataType.GricsSectionType,
  DataType.GricsEntryImportStatus,
  DataType.GricsImportStatus,
  DataType.GricsImportChangeStatus,
  DataType.MembershipProcessState,
  DataType.Province,
  DataType.PaymentMethod,
  DataType.AgDelegateStatus,
  DataType.OtherMemberStatus,
  DataType.Authorization,
  DataType.PermissionAccessLevel,
  DataType.PermissionScope,
  DataType.AccountingMonth,
];

export const createEnumValueProvider = (dataType: enumDataTypes): IValueProvider => {
  let valueProvider;

  switch (dataType) {
    case DataType.Gender:
      valueProvider = new EnumValueProvider(Gender, 'member.gender');
      break;
    case DataType.ActiveState:
      valueProvider = new EnumValueProvider(ActiveState, 'models.member.activeStateOptions', [
        ActiveState.Archived,
      ]);
      break;
    case DataType.MemberStatus:
      valueProvider = new EnumValueProvider(MemberStatus, 'models.member.statusOptions');
      break;
    case DataType.JobType:
      valueProvider = new EnumValueProvider(JobType, 'member.jobType');
      break;
    case DataType.Schooling:
      valueProvider = new EnumValueProvider(Schooling, 'member.schooling');
      break;
    case DataType.Role:
      valueProvider = new EnumValueProvider(Role, 'member.role');
      break;
    case DataType.UserRole:
      valueProvider = new EnumValueProvider(UserRole, 'user.role');
      break;
    case DataType.AssociationType:
      valueProvider = new EnumValueProvider(AssociationType, 'association.type');
      break;
    case DataType.LevelOfSchooling:
      valueProvider = new EnumValueProvider(LevelOfSchooling, 'member.levelOfSchooling');
      break;
    case DataType.MembershipIssue:
      valueProvider = new EnumValueProvider(MembershipIssue, 'membership.issue');
      break;
    case DataType.MembershipStatus:
      valueProvider = new EnumValueProvider(MembershipStatus, 'membership.status');
      break;
    case DataType.GricsSectionType:
      valueProvider = new EnumValueProvider(GricsSectionType, 'grics.sectionType');
      break;
    case DataType.GricsEntryImportStatus:
      valueProvider = new EnumValueProvider(GricsEntryImportStatus, 'grics.entryImportStatus');
      break;
    case DataType.GricsImportStatus:
      valueProvider = new EnumValueProvider(GricsImportStatus, 'grics.importStatus');
      break;
    case DataType.GricsImportChangeStatus:
      valueProvider = new EnumValueProvider(MemberChangesStatus, 'models.memberChangeRequest.statusOptions');
      break;
    case DataType.MembershipProcessState:
      valueProvider = new EnumValueProvider(MembershipProcessState, 'membership.processState');
      break;
    case DataType.Province:
      valueProvider = new EnumValueProvider(Province, 'province');
      break;
    case DataType.PaymentMethod:
      valueProvider = new EnumValueProvider(PaymentMethod, 'models.payment.paymentMethod');
      break;
    case DataType.AgDelegateStatus:
      valueProvider = new EnumValueProvider(AGDelegateStatus, 'member.agDelegateStatus');
      break;
    case DataType.Authorization:
      valueProvider = new EnumValueProvider(Authorization, 'member.authorization');
      break;
    case DataType.OtherMemberStatus:
      valueProvider = new EnumValueProvider(OtherMemberStatus, 'member.otherStatus');
      break;
    case DataType.PermissionAccessLevel:
      valueProvider = new EnumValueProvider(AccessLevel, 'permission.accessLevel');
      break;
    case DataType.PermissionScope:
      valueProvider = new EnumValueProvider(PermissionScope, 'permission.permissionScope');
      break;
    case DataType.AccountingMonth:
      valueProvider = new EnumValueProvider(AccountingMonth, 'accountingMonth');
      break;
    default:
      throw new Error('Unhandled column type');
  }

  return valueProvider;
};

export default createEnumValueProvider;
