import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

const email = 'adhesions@fqde.qc.ca';

function MembershipUpdateInvalidRequest() {
  const { t } = useTranslation();
  return (
    <>
      <h2 className="f4eSimpleLayout__panel__content__title">
        {t('membershipUpdatePage.invalidRequest.title')}
      </h2>
      <p className="f4eSimpleLayout__panel__content__text">
        {
          <Trans
            i18nKey="membershipUpdatePage.invalidRequest.description"
            values={{ email }}
            components={{ emailLink: <a href={`mailto:${email}`} /> }}
          />
        }
      </p>
    </>
  );
}

export default MembershipUpdateInvalidRequest;
