import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';

import { AccessLevel } from '@common/constants/permission';
import { AppRouteSections, pagesPermissionScopes } from '@constants/routes';
import adminRoutes from '@modules/admin/routes';
import AppProtectedRoute from '@modules/app/components/AppProtectedRoute';
import AppUnAuthorizedRoute from '@modules/app/components/AppUnauthorizedRoute';
import { selectUser } from '@modules/app/redux/selectors';
import authRoutes from '@modules/auth/routes';
import changesRoutes from '@modules/changes/routes';
import PageNotFound from '@modules/errors/PageNotFound';
import financesRoutes from '@modules/finances/routes';
import membersRoutes from '@modules/members/routes';
import membershipRoutes from '@modules/membership/routes';
import { hasPermission } from '@modules/shared/permission';
import { AppRoutePages } from '@src/constants';
import { history } from '@src/redux/store';

type RouteConfig = { path: AppRoutePages; component: React.ReactNode };
const protectedRoutes = [...membersRoutes, ...financesRoutes, ...adminRoutes];

function AppRouter() {
  const currentUser = useSelector(selectUser);

  const validatePermission = (path: AppRoutePages) =>
    hasPermission(pagesPermissionScopes[path] || [], AccessLevel.Read, currentUser);

  const renderRoutes = (routes: RouteConfig[]) => {
    return routes.map(({ path, component }) => (
      <Route key={path} path={path} exact>
        {component}
      </Route>
    ));
  };

  const renderUnAuthorizedRoutes = (routes: RouteConfig[]) => {
    const enabledRoute = protectedRoutes.find(({ path }) => validatePermission(path));

    return routes.map(({ path, component }) => (
      <AppUnAuthorizedRoute
        key={path}
        path={path}
        defaultRoute={enabledRoute?.path || AppRoutePages.Home}
        exact
      >
        {component}
      </AppUnAuthorizedRoute>
    ));
  };

  const renderProtectedRoutes = (routes: RouteConfig[], sectionRoute: AppRouteSections) => {
    const enabledRoutes = routes.filter(({ path }) => validatePermission(path));

    return [...enabledRoutes, { path: sectionRoute, component: null }].map(({ path, component }) => {
      if (component) {
        return (
          <AppProtectedRoute key={path} path={path} exact>
            {component}
          </AppProtectedRoute>
        );
      }
      return <Redirect key={path} from={path} to={{ pathname: enabledRoutes[0]?.path || '' }} exact />;
    });
  };

  return (
    <ConnectedRouter history={history}>
      <Switch>
        {renderUnAuthorizedRoutes(authRoutes)}

        {renderRoutes(membershipRoutes)}
        {renderRoutes(changesRoutes)}

        {renderProtectedRoutes(membersRoutes, AppRouteSections.Members)}
        {renderProtectedRoutes(financesRoutes, AppRouteSections.Finances)}
        {renderProtectedRoutes(adminRoutes, AppRouteSections.Administration)}

        <Route path="*">
          <PageNotFound />
        </Route>
      </Switch>
    </ConnectedRouter>
  );
}

export default AppRouter;
