import { toast } from 'react-toastify';
import { IOption } from '@novo-electronique/react-forms';
import i18n from 'i18next';
import { ThunkDispatch } from 'redux-thunk';

import { ActiveState } from '@common/constants/member';
import { SettingKey } from '@common/constants/setting-key';
import { IEmailFilter } from '@common/models/email-filter';
import { ISetting } from '@common/models/setting';
import { IUser } from '@common/models/user';
import { userRoleValueProvider } from '@common/value-provider';
import { IEntityValue } from '@common/value-provider/entity-value';
import {
  setActiveMembersCount,
  setInactiveMembersCount,
  setLastMassUpdateSendingDate,
} from '@modules/admin/redux/action';
import { selectInactiveMembersCount, selectLastMassUpdateSendingDate } from '@modules/admin/redux/selectors';
import { addOrUpdateBackgroundTask, removeBackgroundTask } from '@modules/app/redux/action';
import { selectBackgroundTasks } from '@modules/app/redux/selectors';
import { CachedEntity } from '@modules/app/redux/state';
import { refreshCache } from '@modules/app/service';
import { getMembersCount, getMembersToArchiveCount } from '@modules/members/services/members';
import apiClient from '@modules/shared/api-client';
import { IBackgroundTask } from '@modules/shared/background-task';
import { valueProviderToOptions } from '@modules/shared/components/Form/utils';
import { handleServiceException } from '@modules/shared/service-error-handler';
import { BackgroundTaskType } from '@src/constants';
import { IRootState } from '@src/redux/root.state';

export function getUsers(): Promise<IUser[]> {
  return apiClient
    .get<IUser[]>('/user/all')
    .then((users: IUser[]) => users)
    .catch(() => {
      return [];
    });
}

export function getUser(id: string): Promise<IUser> {
  return apiClient.get<IUser>(`/user/${id}`).catch(handleServiceException);
}

export function saveUser(user: IUser): (dispatch: ThunkDispatch<{}, {}, any>) => Promise<void> {
  return (dispatch) =>
    apiClient
      .post<IUser>('/user', user)
      .then(() => dispatch(refreshCache([CachedEntity.User], true)))
      .catch(handleServiceException);
}

export function updateActiveMembersCount(
  isForcingUpdate = false,
  activeEmailFilters: Array<IEmailFilter>,
): (dispatch: ThunkDispatch<{}, {}, any>, getState: () => IRootState) => Promise<void> {
  return async (dispatch, getState) => {
    const activeMembersCount = selectLastMassUpdateSendingDate(getState());
    if (!isForcingUpdate && activeMembersCount !== null) {
      return;
    }
    await getMembersCount(ActiveState.Active, activeEmailFilters).then((count) => {
      dispatch(setActiveMembersCount(count));
    });
  };
}

export function updateInactiveMembersCount(
  isForcingUpdate = false,
): (dispatch: ThunkDispatch<{}, {}, any>, getState: () => IRootState) => Promise<void> {
  return async (dispatch, getState) => {
    const inactiveMembersCount = selectInactiveMembersCount(getState());
    if (!isForcingUpdate && inactiveMembersCount !== null) {
      return;
    }

    await getMembersToArchiveCount().then((count) => dispatch(setInactiveMembersCount(count)));
  };
}

export function updateLastMassUpdateSendingDate(
  isForcingUpdate = false,
): (dispatch: ThunkDispatch<{}, {}, any>, getState: () => IRootState) => Promise<void> {
  return async (dispatch, getState) => {
    const lastMassUpdateSendingDate = selectLastMassUpdateSendingDate(getState());
    if (!isForcingUpdate && lastMassUpdateSendingDate !== null) {
      return;
    }
    await apiClient
      .get<ISetting>(`/setting/${SettingKey.LastMassUpdateSendingDate}`)
      .then((setting) => dispatch(setLastMassUpdateSendingDate(new Date(setting.value))))
      .catch(() => null);
  };
}

export function updateMassUpdateBackgroundTaskFeedback(
  intervalDuration = 5000,
): (dispatch: ThunkDispatch<{}, {}, any>, getState: () => IRootState) => Promise<void> {
  return async (dispatch, getState) => {
    let interval = null;
    const task: IBackgroundTask = {
      id: BackgroundTaskType.MassEmailUpdate,
      type: BackgroundTaskType.MassEmailUpdate,
    };

    const updateFeedback = async (isFirstTime = false): Promise<boolean> => {
      const backgroundTasks = selectBackgroundTasks(getState());
      const isSendingMassUpdate = await apiClient
        .get<ISetting>(`/setting/${SettingKey.IsSendingMassUpdate}`)
        .then((setting) => setting.value)
        .catch(() => false);

      const isTaskCleared = !isFirstTime && !backgroundTasks.some(({ id }) => task.id === id);

      if (isSendingMassUpdate && !isTaskCleared) {
        if (isFirstTime) {
          dispatch(addOrUpdateBackgroundTask(task));
        }
        return true;
      } else {
        if (interval) {
          dispatch(setLastMassUpdateSendingDate(new Date()));
          toast.success(i18n.t('membersPage.updateRequestedSuccessfully_plural'));

          clearInterval(interval);
        }
        dispatch(removeBackgroundTask(task.id));
        return false;
      }
    };

    const isActive = await updateFeedback(true);
    if (isActive) {
      interval = setInterval(async () => await updateFeedback(), intervalDuration);
    }
  };
}

export const roleOptions = () => {
  return valueProviderToOptions(userRoleValueProvider);
};

export const formatUser = (user: IUser): IEntityValue | IOption => ({
  value: user.id,
  label: `${user.firstName} ${user.lastName}`,
});
