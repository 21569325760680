import { AccountingMonth } from '@common/constants/accounting-month';
import { gricsFilesToUploadMaxCount } from '@common/constants/global';
import { GricsEntryImportStatus } from '@common/constants/grics-entry-import-status';
import { GricsImportStatus } from '@common/constants/grics-import-status';
import { GricsSectionType } from '@common/constants/grics-section-type';
import { IAttachment } from '@common/models/attachment';
import { IServiceCenter } from '@common/models/service-center';
import { IUser } from '@common/models/user';

export type GricsEntryImportMetadata = {
  id: string;
  description: string;
  status: GricsEntryImportStatus;
  error?: object;
} & Partial<{ [key: string]: any }>;

export interface IGricsSectionImportMetadata {
  type: GricsSectionType;
  totalCount: number;
  createdCount: number;
  modifiedCount: number;
  unchangedCount: number;
  errorCount: number;
  entries: GricsEntryImportMetadata[];
}

export interface IGricsFileUploaded {
  attachmentId: string;
  name: string;
  accountingMonth: AccountingMonth;
}

export interface IGricsImport {
  id: string;

  status: GricsImportStatus;
  date: Date;
  accountingMonth: AccountingMonth;
  budgetYear: string;

  attachment?: IAttachment;
  serviceCenter?: Partial<IServiceCenter>;
  importedBy?: Partial<IUser>;

  metadata: IGricsSectionImportMetadata[];

  queuedDate: Date;
  processingDate: Date;
  completedDate: Date;
}

export const getGricsFilesUploadedValidationSchema = (Yup) =>
  Yup.array()
    .of(
      Yup.object({
        attachmentId: Yup.string().required(),
        name: Yup.string().required(),
        accountingMonth: Yup.number().required(),
      }).required(),
    )
    .min(1)
    .max(gricsFilesToUploadMaxCount);
