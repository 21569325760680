import React from 'react';
import { FieldWidth, FormMultiAsyncSelectField } from '@novo-electronique/react-forms';

import { loadSchoolOptions, toSchoolAsyncOption } from '@modules/members/services/schools';

interface IProps {
  name: string;
  label: string;
  disabled?: boolean;
  width?: FieldWidth;
  autoFocus?: boolean;
}

function SchoolMultiSelectField(props: IProps) {
  return (
    <FormMultiAsyncSelectField
      {...props}
      loadOptions={loadSchoolOptions}
      entityToOption={toSchoolAsyncOption}
    />
  );
}

export default SchoolMultiSelectField;
