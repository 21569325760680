export enum MembershipProcessState {
  ActionRequired = 'action-required',
  PendingForm = 'pending-form',
  PendingEmailConfirmation = 'pending-email-confirmation',
  PendingFirstContribution = 'pending-first-contribution',
  PendingFqdeCompletion = 'pending-fqde-completion',
  PendingAccountCreation = 'pending-account-creation',
  Completed = 'completed',
}

export enum MembershipStatus {
  Processing = 'processing',
  Completed = 'completed',
  Cancelled = 'cancel',
}

export enum MembershipIssue {
  None = 'none',
  EmailMissing = 'email-missing',
}
