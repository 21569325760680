import { IAssociation } from '@common/models/association';
import { IServiceCenter } from '@common/models/service-center';
import { IUser, User } from '@common/models/user';
import { ICacheEntity } from '@modules/app/redux/state';
import { IBackgroundTask } from '@modules/shared/background-task';

export const FEATURE = '[APP]';

export enum AppActionsTypes {
  AppInitialized = '[APP] App initialized',
  SetIsLoading = '[APP] Set loading state',
  AddOrUpdateBackgroundTask = '[APP] Add or update background task',
  RemoveBackgroundTask = '[APP] Remove background task',
  SetActiveUser = `[APP] Set active user`,
  DisconnectActiveUser = `[APP] Disconnect active user`,
  SetCacheLoaded = '[APP] Set cache loaded',
  SetAssociationEntity = '[APP] Set association entity',
  SetServiceCenterEntity = '[APP] Set service center entity',
  SetUserEntity = '[APP] Set local section entity',
}

export interface IAppInitialized {
  type: typeof AppActionsTypes.AppInitialized;
  payload: null;
}

export const setAppInitialized = (): IAppInitialized => ({
  type: AppActionsTypes.AppInitialized,
  payload: null,
});

export interface ISetIsLoading {
  type: typeof AppActionsTypes.SetIsLoading;
  payload: boolean;
}

export const setAppIsLoading = (isLoading: boolean): ISetIsLoading => ({
  type: AppActionsTypes.SetIsLoading,
  payload: isLoading,
});

export interface IAddOrUpdateBackgroundTask {
  type: typeof AppActionsTypes.AddOrUpdateBackgroundTask;
  payload: IBackgroundTask;
}

export const addOrUpdateBackgroundTask = (backgroundTask: IBackgroundTask): IAddOrUpdateBackgroundTask => ({
  type: AppActionsTypes.AddOrUpdateBackgroundTask,
  payload: backgroundTask,
});

export interface IRemoveBackgroundTask {
  type: typeof AppActionsTypes.RemoveBackgroundTask;
  payload: string;
}

export const removeBackgroundTask = (backgroundTaskId: string): IRemoveBackgroundTask => ({
  type: AppActionsTypes.RemoveBackgroundTask,
  payload: backgroundTaskId,
});

export interface ISetActiveUser {
  type: typeof AppActionsTypes.SetActiveUser;
  payload: User;
}

export const setActiveUser = (user: User): ISetActiveUser => ({
  type: AppActionsTypes.SetActiveUser,
  payload: user,
});

export interface IDisconnectActiveUser {
  type: typeof AppActionsTypes.DisconnectActiveUser;
  payload: null;
}

export const disconnectActiveUser = (): IDisconnectActiveUser => ({
  type: AppActionsTypes.DisconnectActiveUser,
  payload: null,
});

interface ISetCacheLoaded {
  type: typeof AppActionsTypes.SetCacheLoaded;
  payload: boolean;
}

export const setCacheLoaded = (isLoaded: boolean): ISetCacheLoaded => ({
  type: AppActionsTypes.SetCacheLoaded,
  payload: isLoaded,
});

interface ISetAssociationEntity {
  type: typeof AppActionsTypes.SetAssociationEntity;
  payload: ICacheEntity<IAssociation>;
}

export const setAssociationEntity = (entity: ICacheEntity<IAssociation>): ISetAssociationEntity => ({
  type: AppActionsTypes.SetAssociationEntity,
  payload: entity,
});

interface ISetServiceCenterEntity {
  type: typeof AppActionsTypes.SetServiceCenterEntity;
  payload: ICacheEntity<IServiceCenter>;
}

export const setServiceCenterEntity = (entity: ICacheEntity<IServiceCenter>): ISetServiceCenterEntity => ({
  type: AppActionsTypes.SetServiceCenterEntity,
  payload: entity,
});

interface ISetUserEntity {
  type: typeof AppActionsTypes.SetUserEntity;
  payload: ICacheEntity<IUser>;
}

export const setUserEntity = (entity: ICacheEntity<IUser>): ISetUserEntity => ({
  type: AppActionsTypes.SetUserEntity,
  payload: entity,
});

export type AppActions =
  | IAppInitialized
  | ISetIsLoading
  | IAddOrUpdateBackgroundTask
  | IRemoveBackgroundTask
  | ISetActiveUser
  | IDisconnectActiveUser
  | ISetAssociationEntity
  | ISetServiceCenterEntity
  | ISetUserEntity
  | ISetCacheLoaded;
