import './Alert.scss';

import React, { ReactElement } from 'react';

interface IProps {
  children: string | ReactElement;
  onClose?: () => void;
}

function Alert({ children, onClose }: IProps) {
  return (
    <div className="f4eAlert">
      <p className="f4eAlert__message">{children}</p>
      <button className="f4eAlert__button material-icons" onClick={() => onClose && onClose()}>
        close
      </button>
    </div>
  );
}

export default Alert;
