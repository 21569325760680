import './PeriodSelector.scss';

import React, { useRef } from 'react';
import { FormSelectFieldOptions } from '@novo-electronique/react-forms';
import classNames from 'classnames';

import { AccountingMonth } from '@common/constants/accounting-month';
import { getAccountingMonthFromMonthIndex } from '@common/utils/accounting-month';
import { getLatestBudgetYear } from '@common/utils/period';
import { accountingMonthValueProvider } from '@common/value-provider';
import { valueProviderToOptions } from '@modules/shared/components/Form/utils';
import { periodOptions } from '@modules/shared/components/PeriodSelector/utils';

interface IProps {
  onChange: (budgetYear: string, accountingMonth?: AccountingMonth) => void;
  small?: boolean;
  showAccountingMonth?: boolean;
}

function PeriodSelector({ onChange, small, showAccountingMonth }: IProps) {
  const periods = periodOptions();

  const budgetYearRef = useRef<string>(getLatestBudgetYear());
  const accountingMonthRef = useRef<AccountingMonth>(getAccountingMonthFromMonthIndex(new Date().getMonth()));

  const className = classNames('f4ePeriodSelector', { 'f4ePeriodSelector--small': small });
  return (
    <div className={className}>
      <select
        className="novoFormField__input novoFormField__input--select"
        defaultValue={budgetYearRef.current}
        onChange={(e) => {
          budgetYearRef.current = e.currentTarget.value;
          onChange(budgetYearRef.current, accountingMonthRef.current);
        }}
      >
        <FormSelectFieldOptions options={periods} keepOriginalOrder={true} addEmptyOption={false} />
      </select>
      {showAccountingMonth && (
        <select
          className="novoFormField__input novoFormField__input--select"
          defaultValue={accountingMonthRef.current}
          onChange={(e) => {
            accountingMonthRef.current = +e.currentTarget.value;
            onChange(budgetYearRef.current, accountingMonthRef.current);
          }}
        >
          <FormSelectFieldOptions
            options={valueProviderToOptions(accountingMonthValueProvider)}
            keepOriginalOrder={true}
            addEmptyOption={false}
          />
        </select>
      )}
    </div>
  );
}

export default PeriodSelector;
