import React from 'react';
import { useTranslation } from 'react-i18next';

import { IAssociationContributionReport } from '@common/models/contribution-report';
import { getPeriodFromBudgetYear } from '@common/utils/period';
import { accountingMonthValueProvider } from '@common/value-provider';
import ContributionReport from '@modules/reports/ContributionReport';
import {
  associationContributionsToReport,
  associationsContributionsToSummaryReport,
  serviceCentersContributionsToSummaryReport,
} from '@modules/reports/service';
import Report from '@modules/shared/components/Report';

interface IProps {
  associationContributions: IAssociationContributionReport[];
}

function ContributionsSummaryReport({ associationContributions }: IProps) {
  const { t } = useTranslation();

  const period = getPeriodFromBudgetYear(associationContributions[0].budgetYear);
  const accountingMonth = accountingMonthValueProvider.label(
    associationContributions[0].accountingMonth.toString(),
  );

  const toAssociation = (associationContribution: IAssociationContributionReport) => (
    <section key={associationContribution.id} className="f4eReport__content f4eReport__content--no-break">
      <ContributionReport report={associationContributionsToReport(associationContribution)} />
      <ContributionReport
        report={serviceCentersContributionsToSummaryReport(associationContribution.serviceCenters)}
        noHeader
      />
    </section>
  );

  const report = associationsContributionsToSummaryReport(associationContributions);
  return (
    <Report
      id="summary"
      title={t('reports.contributions.associationsSummaryTitle')}
      subTitle={t('reports.contributions.byAssociationSubTitle', { period })}
      mention={t('reports.contributions.contributorsCount', {
        count: report.contributorsCount,
        accountingMonth: accountingMonth.toLowerCase(),
      })}
    >
      {associationContributions.map(toAssociation)}
      <section className="f4eReport__content f4eReport__content--no-break">
        <ContributionReport report={{ ...report, title: t('reports.contributions.amountSummaryTitle') }} />
      </section>
    </Report>
  );
}

export default ContributionsSummaryReport;
