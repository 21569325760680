import './Layout.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import AppHeader from '@modules/app/components/AppHeader';
import DocumentTitle from '../DocumentTitle/DocumentTitle';

interface IProps {
  children: any;
  title?: string;
}

function Layout({ children, title }: IProps) {
  const { t } = useTranslation();
  return (
    <article className="f4eLayout">
      <DocumentTitle>{title || t('general.siteTitle')}</DocumentTitle>
      <AppHeader />
      {children}
    </article>
  );
}

export default Layout;
