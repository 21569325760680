export enum AdminActionsTypes {
  SetActiveMembersCount = '[Admin] Set Active Members Count',
  SetInactiveMembersCount = '[Admin] Set Inactive Members Count',
  SetLastMassUpdateSendingDate = '[Admin] Set Last Mass Update Sending Date',
}

export interface ISetActiveMembersCount {
  type: typeof AdminActionsTypes.SetActiveMembersCount;
  payload: number;
}

export const setActiveMembersCount = (count: number): ISetActiveMembersCount => ({
  type: AdminActionsTypes.SetActiveMembersCount,
  payload: count,
});

export interface ISetInactiveMembersCount {
  type: typeof AdminActionsTypes.SetInactiveMembersCount;
  payload: number;
}

export const setInactiveMembersCount = (count: number): ISetInactiveMembersCount => ({
  type: AdminActionsTypes.SetInactiveMembersCount,
  payload: count,
});

export interface ISetLastMassUpdateSendingDate {
  type: typeof AdminActionsTypes.SetLastMassUpdateSendingDate;
  payload: Date;
}

export const setLastMassUpdateSendingDate = (date: Date): ISetLastMassUpdateSendingDate => ({
  type: AdminActionsTypes.SetLastMassUpdateSendingDate,
  payload: date,
});

export type AdminActionTypes =
  | ISetActiveMembersCount
  | ISetInactiveMembersCount
  | ISetLastMassUpdateSendingDate;
