import './Modal.scss';

import React, { ReactNode, ReactNodeArray, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { Blanket } from '@novo-electronique/react-blanket';
import classNames from 'classnames';

const container = document.getElementById('f4e-modal');

export enum ModalSize {
  Small = 'small',
  Normal = 'normal',
  Maximize = 'maximize',
}

export interface IModalProps {
  title: string;
  children: string | ReactNode | ReactNodeArray;
  onClose?: () => void;
  isHost?: boolean;
  size?: ModalSize;
}

let modalId = 0;
const activeModalIds: number[] = [];

function Modal({ title, children, onClose, isHost, size = ModalSize.Normal }: IModalProps) {
  const blanketRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLElement>(null);
  const modalIdRef = useRef<number>(null);
  const onCloseHandler = () => onClose && onClose();

  useEffect(() => {
    modalIdRef.current = ++modalId;
    activeModalIds.push(modalIdRef.current);

    const onKeyDown = (event: KeyboardEvent) => {
      if (event.code === 'Escape' && modalIdRef.current === activeModalIds[activeModalIds.length - 1]) {
        onCloseHandler();
      }
    };

    document.body.style.overflow = 'hidden';
    document.addEventListener('keydown', onKeyDown);

    return () => {
      activeModalIds.pop();
      document.body.style.overflow = 'visible';
      document.removeEventListener('keydown', onKeyDown);
    };
  }, []);

  const onMouseDown = (event: any) => {
    if (blanketRef.current.contains(event.target) && !modalRef.current.contains(event.target)) {
      onCloseHandler();
    }
  };

  const containerClassName = `f4eModalContainer f4eModalContainer--${size}`;
  const contentClassName = classNames('f4eModal__content', { 'f4eModal__content--host': isHost });
  return createPortal(
    <Blanket onMouseDown={onMouseDown} ref={blanketRef}>
      <div className={containerClassName}>
        <article className="f4eModal" role="dialog" ref={modalRef}>
          <header className="f4eModal__header">
            <button className="f4eModal__header__close material-icons" onClick={onCloseHandler}>
              close
            </button>
            <h1 className="f4eModal__header__title">{title}</h1>
          </header>
          <div className={contentClassName}>{children}</div>
        </article>
      </div>
    </Blanket>,
    container,
  );
}

export default Modal;
