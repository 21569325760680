import { PermissionScope } from '@common/constants/permission';

export enum AppRouteSections {
  Unknown = 'unknown',
  Members = '/gestion-membres',
  Finances = '/gestion-financiere',
  Administration = '/administration',
}

export enum AppRoutePages {
  Home = '/',
  Membership = '/adhesion',
  MembershipConfirmation = '/adhesion/confirmation',
  MembershipUpdate = '/adhesion/maj',
  MemberChanges = '/changements/membre',
  Members = '/gestion-membres/membres',
  Associations = '/gestion-membres/associations',
  ServiceCenters = '/gestion-membres/centres-de-services',
  Memberships = '/gestion-membres/adhesions',
  Schools = '/gestion-membres/ecoles',
  ChangesRequests = '/gestion-membres/changements',

  Payments = '/gestion-financiere/paiements',
  GricsImport = '/gestion-financiere/importations',
  ContributionReport = '/gestion-financiere/soldes-annuels-avec-parts',
  CumulativeConciliationReport = '/gestion-financiere/conciliation-cumulative',

  Users = '/administration/gestion-utilisateurs',
  Params = '/administration/parametres',
}

export const sectionsPermissionScopes: { [page in AppRouteSections]?: PermissionScope[] } = {
  [AppRouteSections.Members]: [
    PermissionScope.Member,
    PermissionScope.School,
    PermissionScope.ServiceCenter,
    PermissionScope.Association,
    PermissionScope.Membership,
  ],
  [AppRouteSections.Finances]: [PermissionScope.Finance, PermissionScope.Import],
  [AppRouteSections.Administration]: [PermissionScope.User, PermissionScope.System],
};

export const pagesPermissionScopes: { [page in AppRoutePages]?: PermissionScope[] } = {
  [AppRoutePages.Members]: [PermissionScope.Member],
  [AppRoutePages.Associations]: [PermissionScope.Association],
  [AppRoutePages.ServiceCenters]: [PermissionScope.ServiceCenter],
  [AppRoutePages.Memberships]: [PermissionScope.Membership],
  [AppRoutePages.Schools]: [PermissionScope.School],
  [AppRoutePages.ChangesRequests]: [PermissionScope.Member],

  [AppRoutePages.Payments]: [PermissionScope.Finance],
  [AppRoutePages.GricsImport]: [PermissionScope.Import],
  [AppRoutePages.ContributionReport]: [PermissionScope.Finance],
  [AppRoutePages.CumulativeConciliationReport]: [PermissionScope.Finance],

  [AppRoutePages.Users]: [PermissionScope.User],
  [AppRoutePages.Params]: [PermissionScope.System],
};
