import { IOption } from '@novo-electronique/react-forms';
import { ThunkDispatch } from 'redux-thunk';

import { IServiceCenter } from '@common/models/service-center';
import { IEntityValue } from '@common/value-provider/entity-value';
import { CachedEntity } from '@modules/app/redux/state';
import { refreshCache } from '@modules/app/service';
import apiClient from '@modules/shared/api-client';
import { handleServiceException } from '@modules/shared/service-error-handler';

export function getServiceCenters(): Promise<IServiceCenter[]> {
  return apiClient
    .get<IServiceCenter[]>('/service-center/all')
    .then((serviceCenters: IServiceCenter[]) => serviceCenters)
    .catch(() => {
      return [];
    });
}

export function saveServiceCenter(
  serviceCenter: IServiceCenter,
): (dispatch: ThunkDispatch<{}, {}, any>) => Promise<void> {
  return (dispatch) => {
    return apiClient
      .post<IServiceCenter>('/service-center', serviceCenter)
      .then(() => dispatch(refreshCache([CachedEntity.ServiceCenter], true)))
      .catch(handleServiceException);
  };
}

export function getServiceCenter(id: string): Promise<IServiceCenter> {
  return apiClient.get<IServiceCenter>(`/service-center/${id}`).catch(handleServiceException);
}

export function formatServiceCenter(
  serviceCenter: IServiceCenter,
  key: keyof IServiceCenter = 'id',
): IEntityValue | IOption {
  return {
    value: serviceCenter[key],
    label: `${serviceCenter.number} – ${serviceCenter.name}`,
  };
}

export function toOptions(serviceCenters: IServiceCenter[]): IOption[] {
  return serviceCenters.map((serviceCenter) => formatServiceCenter(serviceCenter));
}
