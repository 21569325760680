import './GricsFileUploaded.scss';

import React, { ChangeEvent, MouseEvent, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@novo-electronique/react-circular-progress';
import { FileUploadedAction, FileUploadedState, IFileUploadedProps } from '@novo-electronique/react-dropzone';
import { FormSelectFieldOptions } from '@novo-electronique/react-forms';
import classNames from 'classnames';

import { gricsFileNameRegex } from '@common/constants/global';
import { accountingMonthValueProvider } from '@common/value-provider';
import { valueProviderToOptions } from '@modules/shared/components/Form/utils';
import { GricsFileFieldContext } from '@modules/shared/components/GricsFilesField/GricsFilesField';
import { getAccountingMonthFromFileName } from '@modules/shared/components/GricsFilesField/utils';

function GricsFileUploaded({
  file,
  disabled,
  labelsNamespace,
  withStatus,
  actions = {},
}: IFileUploadedProps) {
  const { t } = useTranslation();
  const { onAccountingMonthChange } = useContext(GricsFileFieldContext);

  const isMounted = useRef(false);
  const [actionsProcessing, setActionsProcessing] = useState<
    Partial<{ [action in FileUploadedAction]: boolean }>
  >({});

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  });

  const eventHandler = async (event: MouseEvent, action: FileUploadedAction) => {
    event.stopPropagation();
    setActionsProcessing({ ...actionsProcessing, [action]: true });

    const callback = actions[action];
    try {
      await callback(file);
    } finally {
      if (isMounted.current) {
        setActionsProcessing({ ...actionsProcessing, [action]: false });
      }
    }
  };

  const onAccountingMonthChangeHandler = (event: ChangeEvent<HTMLSelectElement>) => {
    onAccountingMonthChange(file.id, +event.target.value);
  };

  const isLoading = !file.id && !file.state;
  const isCompleted = file.id || file.state === FileUploadedState.Completed;
  const isFileNameValid = file.name.match(gricsFileNameRegex);

  const actionsDisabled: Partial<{ [action in FileUploadedAction]: boolean }> = {
    [FileUploadedAction.Remove]: isLoading || disabled,
  };

  const fileClassName = classNames('f4eGricsFileUploaded', {
    'f4eGricsFileUploaded--is-loading': isLoading,
    'f4eGricsFileUploaded--faulty': file.state === FileUploadedState.Faulty,
  });
  const statusClassName = classNames('f4eGricsFileUploaded__status', {
    'f4eGricsFileUploaded__status--loading': isLoading,
    'f4eGricsFileUploaded__status--completed': isCompleted,
    'f4eGricsFileUploaded__status--fault': file.state === FileUploadedState.Faulty,
  });

  return (
    <article className={fileClassName}>
      <div className="f4eGricsFileUploaded__info">
        <span className="f4eGricsFileUploaded__info__icon material-icons">description</span>
        <h3 className="f4eGricsFileUploaded__info__name">{file.name}</h3>
        {isFileNameValid && (
          <label className="f4eGricsFileUploaded__info__accounting-month">
            <select
              className="novoFormField__input novoFormField__input--select"
              onChange={onAccountingMonthChangeHandler}
              disabled={isLoading}
              defaultValue={getAccountingMonthFromFileName(file.name)}
            >
              <FormSelectFieldOptions
                options={valueProviderToOptions(accountingMonthValueProvider)}
                addEmptyOption={false}
                keepOriginalOrder
              />
            </select>
          </label>
        )}
      </div>

      {withStatus && <div className={statusClassName}>{isLoading && <CircularProgress />}</div>}

      <div className="f4eGricsFileUploaded__actions">
        {Object.keys(actions)
          .filter((action) => actions[action])
          .map((action) => (
            <button
              key={action}
              title={(labelsNamespace && t(`${labelsNamespace}.${action}`)) || undefined}
              className={`f4eGricsFileUploaded__actions__button f4eGricsFileUploaded__actions__button--${action} material-icons`}
              type="button"
              disabled={actionsDisabled[action]}
              onClick={(e) => eventHandler(e, action as FileUploadedAction)}
            />
          ))}
      </div>
    </article>
  );
}

export default GricsFileUploaded;
