import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IMember } from '@common/models/member';
import Initializing from '@modules/shared/components/Initializing';
import { SimpleLayout } from '@modules/shared/components/Layout';
import { handleServiceException } from '@modules/shared/service-error-handler';
import { useQuery } from '@modules/shared/useQuery';
import { getMemberByToken, saveMemberUpdateRequest } from '../members/services/members';
import MembershipForm from './components/MembershipForm';
import MembershipUpdateCompleted from './components/MembershipUpdateCompleted';
import MembershipUpdateInvalidRequest from './components/MembershipUpdateInvalidRequest';

enum MembershipUpdatePageStates {
  Loading = 'loading',
  Default = 'default',
  InvalidRequest = 'invalid-request',
  Completed = 'completed',
}

function MembershipUpdatePage() {
  const { t } = useTranslation();
  const [pageState, setPageState] = useState<MembershipUpdatePageStates>(MembershipUpdatePageStates.Loading);
  const [initialValues, setInitialValues] = useState<IMember>(null);
  const query = useQuery();

  const token = query.get('token');

  useEffect(() => {
    if (token !== null) {
      getMemberByToken(token)
        .then((member: IMember) => {
          setInitialValues({
            ...member,
            ackConsent: true,
            ackAuthorizeRepresentationFqde: true,
            ackAuthorizeContribution: true,
            ackAuthorizeServiceCenter: true,
            serviceCenter: { id: member.serviceCenter?.id },
          });
          setPageState(MembershipUpdatePageStates.Default);
        })
        .catch((error) => {
          console.error(error);
          setPageState(MembershipUpdatePageStates.InvalidRequest);
        });
    } else {
      setPageState(MembershipUpdatePageStates.InvalidRequest);
    }
  }, []);

  const onSubmit = (values: IMember) => {
    return saveMemberUpdateRequest(values, token)
      .then(() => {
        setPageState(MembershipUpdatePageStates.Completed);
      })
      .catch(handleServiceException);
  };

  const renderContent = () => {
    switch (pageState) {
      case MembershipUpdatePageStates.Loading:
        return <Initializing />;
      case MembershipUpdatePageStates.Default:
        return (
          <MembershipForm
            onSubmit={onSubmit}
            initialValues={initialValues}
            submitLabelKey="membershipUpdatePage.submit"
            isUpdate
          />
        );
      case MembershipUpdatePageStates.Completed:
        return <MembershipUpdateCompleted />;
      case MembershipUpdatePageStates.InvalidRequest:
        return <MembershipUpdateInvalidRequest />;
    }
  };

  return <SimpleLayout title={t('membershipUpdatePage.title')}>{renderContent()}</SimpleLayout>;
}

export default MembershipUpdatePage;
