import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import adminReducer from '@modules/admin/redux/reducer';
import appReducer from '@modules/app/redux/reducer';
import authReducer from '@modules/auth/redux/reducer';

const rootReducer = (history) => {
  return combineReducers({
    router: connectRouter(history),
    app: appReducer,
    auth: authReducer,
    admin: adminReducer,
  });
};

export default rootReducer;
