import { Formatter } from '@novo-electronique/formatter';

import { Mask } from '../constants/mask';

const defaultPhoneFormatter = new Formatter(Mask.PhoneNumber, ['9']);
const extendedPhoneFormatter = new Formatter(Mask.ExtendedPhoneNumber, ['9']);
const postalCodeFormatter = new Formatter(Mask.PostalCode, ['a', '9']);

export function formatPhoneNumber(phoneNumber: string): string {
  if (!phoneNumber) {
    return '';
  }

  const normalizedPhoneNumber = phoneNumber.replace(/\D/gim, '');
  switch (normalizedPhoneNumber.length) {
    case defaultPhoneFormatter.allowedCharactersCount:
      return defaultPhoneFormatter.format(normalizedPhoneNumber);
    case extendedPhoneFormatter.allowedCharactersCount:
      return extendedPhoneFormatter.format(normalizedPhoneNumber);
    default:
      return phoneNumber;
  }
}

export function formatPostalCode(postalCode: string): string {
  if (!postalCode) {
    return '';
  }

  const normalizedPostalCode = postalCode.toUpperCase().replace(/\s/gim, '');
  if (normalizedPostalCode.length !== postalCodeFormatter.allowedCharactersCount) {
    return postalCode;
  }
  return postalCodeFormatter.format(normalizedPostalCode);
}
