import './WizardStep.scss';

import React, { ReactNode, useContext, useEffect, useRef } from 'react';

import { uuid } from '@common/utils/guid';
import { WizardContext } from './Wizard';

interface IProps {
  title: string;
  directive: string | ReactNode | ReactNode[];
  action?: () => Promise<any>;
  children: ReactNode | ReactNode[];
}

function WizardStep({ directive, children, ...params }: IProps) {
  const idRef = useRef<string>(uuid());
  const { register, remove, getIndex, activeIndex } = useContext(WizardContext);

  useEffect(() => {
    register({ id: idRef.current, ...params });

    return () => {
      remove(idRef.current);
    };
  }, []);

  if (getIndex(idRef.current) !== activeIndex) {
    return null;
  }
  return (
    <article className="f4eWizardStep">
      <p className="f4eWizardStep__directive">{directive}</p>
      <div className="f4eWizardStep__content">{children}</div>
    </article>
  );
}

export default WizardStep;
