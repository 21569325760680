import { IAdminState, initialAdminState } from '@modules/admin/redux/state';
import { AdminActionsTypes, AdminActionTypes } from './action';

const authReducer = (state: IAdminState = initialAdminState, action: AdminActionTypes): IAdminState => {
  switch (action.type) {
    case AdminActionsTypes.SetActiveMembersCount:
      return {
        ...state,
        activeMembersCount: action.payload,
      };
    case AdminActionsTypes.SetInactiveMembersCount:
      return {
        ...state,
        inactiveMembersCount: action.payload,
      };
    case AdminActionsTypes.SetLastMassUpdateSendingDate:
      return {
        ...state,
        lastMassUpdateSendingDate: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
