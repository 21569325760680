import { MembershipProcessState, MembershipStatus } from '@common/constants/membership';
import { MemberNotificationCode, NotificationCode } from '@common/constants/notification';
import { IMember } from '@common/models/member';

export function getAllowedCodes(member: IMember): MemberNotificationCode[] {
  switch (member.membership?.status) {
    case MembershipStatus.Completed:
      return [NotificationCode.MemberUpdateRequest];

    case MembershipStatus.Processing:
      switch (member.membership.processState) {
        case MembershipProcessState.PendingForm:
          return [NotificationCode.NewMemberForm];
        case MembershipProcessState.PendingEmailConfirmation:
          return [NotificationCode.NewMemberConfirmation, NotificationCode.MemberUpdateRequest];
        case MembershipProcessState.PendingFirstContribution:
        case MembershipProcessState.PendingFqdeCompletion:
          return [
            NotificationCode.NewMemberConfirmed,
            NotificationCode.NewMemberAssociation,
            NotificationCode.MemberUpdateRequest,
          ];
      }
  }

  return [];
}
