import React from 'react';
import { useTranslation } from 'react-i18next';

import { AppRouteSections, sectionsPermissionScopes } from '@constants/routes';
import Menu, { MenuItem } from '@modules/shared/components/Menu';

function AppMenu() {
  const { t } = useTranslation();

  return (
    <Menu>
      <MenuItem
        to={AppRouteSections.Members}
        section={AppRouteSections.Members}
        scopes={sectionsPermissionScopes[AppRouteSections.Members]}
      >
        {t('appMenu.members')}
      </MenuItem>

      <MenuItem
        to={AppRouteSections.Finances}
        section={AppRouteSections.Finances}
        scopes={sectionsPermissionScopes[AppRouteSections.Finances]}
      >
        {t('appMenu.finances')}
      </MenuItem>

      <MenuItem
        to={AppRouteSections.Administration}
        section={AppRouteSections.Administration}
        scopes={sectionsPermissionScopes[AppRouteSections.Administration]}
      >
        {t('appMenu.administration')}
      </MenuItem>
    </Menu>
  );
}

export default AppMenu;
