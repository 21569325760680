import i18next from 'i18next';

import { AccountingMonth } from '@common/constants/accounting-month';
import { IContribution } from '@common/models/contribution';
import {
  IAssociationContributionReport,
  IContributionItem,
  IMemberContributions,
  IServiceCenterContributionReport,
} from '@common/models/contribution-report';
import { getMonthIndexFromAccountingMonth } from '@common/utils/accounting-month';
import { flat } from '@common/utils/array';
import { accountantRound } from '@common/utils/currency';
import { uuid } from '@common/utils/guid';
import { getPeriodFromBudgetYear } from '@common/utils/period';
import { ContributionPeriods } from './constants/contribution-periods';
import { ContributionSummaryShares } from './constants/contribution-summary-shares';
import { IContributionReport } from './contribution-report';
import { IContributionReportDetail } from './contribution-report-detail';

const membersContributionsToContribution = (
  acc: IContributionItem[],
  memberContributions: IMemberContributions,
) => [...acc, ...memberContributions.contributions];

const serviceCenterContributionToContributions = (
  acc: IContributionItem[],
  serviceCenterContribution: IServiceCenterContributionReport,
) => [...acc, ...serviceCenterContribution.members.reduce(membersContributionsToContribution, [])];

const memberContributionToContributionDetail = (
  memberContribution: IMemberContributions,
): IContributionReportDetail => {
  return {
    id: memberContribution.id,
    description: `${memberContribution.firstName} ${memberContribution.lastName}`, // TODO: Add member situation
    ...memberContribution.contributions.reduce(contributionItemToMonthlyContributions, {}),
  };
};

const contributionItemToMonthlyContributions = (acc, contribution: IContributionItem) => {
  const updatedAcc = { ...acc };
  const monthKey = `month${
    getMonthIndexFromAccountingMonth(contribution.accountingMonth) + 1
  }` as ContributionPeriods;
  const previousAmount = updatedAcc[monthKey] || 0;
  const previousYearTotalAmount = updatedAcc['yearTotal'] || 0;

  const lineAmount = contribution.associationShare + contribution.federationShare;

  updatedAcc[monthKey] = previousAmount + lineAmount;
  updatedAcc['yearTotal'] = previousYearTotalAmount + lineAmount;

  return updatedAcc;
};

const contributionsToContributionDetail = (acc, contribution: IContribution) => {
  const updatedAcc = { ...acc };
  const monthKey = `month${new Date(contribution.date).getMonth() + 1}` as ContributionPeriods;
  const previousAmount = updatedAcc[monthKey] || 0;
  const previousYearTotalAmount = updatedAcc[ContributionPeriods.YearTotal] || 0;

  const lineAmount =
    contribution.regularContribution + contribution.socialContribution + contribution.specialContribution;

  updatedAcc.id = contribution.id || uuid();
  updatedAcc[monthKey] = previousAmount + lineAmount;
  updatedAcc[ContributionPeriods.YearTotal] = previousYearTotalAmount + lineAmount;

  return updatedAcc;
};

export const periodTotal = (
  contributionDetails: IContributionReportDetail[],
  period: ContributionPeriods,
) => {
  return contributionDetails.reduce((acc: number, contributionDetail: IContributionReportDetail) => {
    return acc + (contributionDetail[period] || 0);
  }, 0);
};

const serviceCenterContributionsToContributionDetail = (
  serviceCenterContributionReport: IServiceCenterContributionReport,
): IContributionReportDetail => {
  return {
    id: serviceCenterContributionReport.id,
    description: `${serviceCenterContributionReport.number} – ${serviceCenterContributionReport.name}`,
    ...Object.values(ContributionPeriods).reduce((obj, period) => {
      obj[period] = periodTotal(
        serviceCenterContributionReport.members.map(memberContributionToContributionDetail),
        period,
      );
      return obj;
    }, {} as { [key in ContributionPeriods]: number }),
  };
};

export const associationContributionsToReport = (
  associationsContributionReport: IAssociationContributionReport,
): IContributionReport => {
  return {
    title: `${associationsContributionReport.number} – ${associationsContributionReport.name}`,
    summaryTitle: i18next.t('reports.contributions.serviceCenterSummary'),
    period: getPeriodFromBudgetYear(associationsContributionReport.budgetYear),
    accountingMonth: associationsContributionReport.accountingMonth,
    details: associationsContributionReport.serviceCenters.map(
      serviceCenterContributionsToContributionDetail,
    ),
    contributorsCount: associationsContributionReport.serviceCenters.reduce(
      (total, serviceCenter) => serviceCenter.activeContributorsCount + total,
      0,
    ),
  };
};

export const associationsContributionsToSummaryReport = (
  associationsContributions: IAssociationContributionReport[],
): IContributionReport => {
  const toContributionMonthlySummary = (
    acc: { [key: string]: IContributionReportDetail },
    reportDetail: IContributionReportDetail,
  ) => {
    acc[reportDetail.id] = Object.values(ContributionPeriods).reduce((obj, period) => {
      obj[period] = accountantRound((reportDetail[period] || 0) + (obj[period] || 0));
      return obj;
    }, acc[reportDetail.id]);

    return acc;
  };

  const contributionReportDetails = flat(
    associationsContributions.map(
      (associationContributions) =>
        serviceCentersContributionsToSummaryReport(associationContributions.serviceCenters).details,
    ),
  );

  const details = contributionReportDetails.reduce(toContributionMonthlySummary, {
    [ContributionSummaryShares.Fqde]: {
      id: ContributionSummaryShares.Fqde,
      description: i18next.t('reports.contributions.fqdeShare'),
    } as IContributionReportDetail,
    [ContributionSummaryShares.Association]: {
      id: ContributionSummaryShares.Association,
      description: i18next.t('reports.contributions.associationShare'),
    } as IContributionReportDetail,
  });

  return {
    period: getPeriodFromBudgetYear(associationsContributions[0].budgetYear),
    accountingMonth: associationsContributions[0].accountingMonth,
    details: Object.values(details),
    contributorsCount: associationsContributions.reduce(
      (total, associationContributions) =>
        total +
        associationContributions.serviceCenters.reduce(
          (total, serviceCenterContributions) => total + serviceCenterContributions.activeContributorsCount,
          0,
        ),
      0,
    ),
  };
};

export const serviceCentersContributionsToSummaryReport = (
  serviceCentersContributions: IServiceCenterContributionReport[],
): IContributionReport => {
  const toContributionMonthlySummary = (
    acc: { [key: string]: IContributionReportDetail },
    contribution: IContributionItem,
  ) => {
    const updatedAcc = { ...acc };
    const monthKey = `month${
      getMonthIndexFromAccountingMonth(contribution.accountingMonth) + 1
    }` as ContributionPeriods;

    const addAmount = (amount: number, share: ContributionSummaryShares, period: ContributionPeriods) => {
      const previousAmount = updatedAcc[share][period] || 0;
      return accountantRound(previousAmount + amount);
    };

    updatedAcc[ContributionSummaryShares.Fqde][monthKey] = addAmount(
      contribution.federationShare,
      ContributionSummaryShares.Fqde,
      monthKey as ContributionPeriods,
    );

    updatedAcc[ContributionSummaryShares.Fqde][ContributionPeriods.YearTotal] = addAmount(
      contribution.federationShare,
      ContributionSummaryShares.Fqde,
      ContributionPeriods.YearTotal,
    );

    // --

    updatedAcc[ContributionSummaryShares.Association][monthKey] = addAmount(
      contribution.associationShare,
      ContributionSummaryShares.Association,
      monthKey as ContributionPeriods,
    );

    updatedAcc[ContributionSummaryShares.Association][ContributionPeriods.YearTotal] = addAmount(
      contribution.associationShare,
      ContributionSummaryShares.Association,
      ContributionPeriods.YearTotal,
    );

    // --

    // NOTE: Proposing to use the summary line like the other reports.
    // updatedAcc[ContributionSummaryShares.Total][monthKey] = addAmount(
    //   contribution.federationShare + contribution.associationShare,
    //   ContributionSummaryShares.Total,
    //   monthKey as ContributionPeriods,
    // );

    // updatedAcc[ContributionSummaryShares.Total][ContributionPeriods.YearTotal] = addAmount(
    //   contribution.federationShare + contribution.associationShare,
    //   ContributionSummaryShares.Total,
    //   ContributionPeriods.YearTotal,
    // );

    return updatedAcc;
  };

  const flattenedContributions = serviceCentersContributions.reduce(
    serviceCenterContributionToContributions,
    [],
  );

  const details = flattenedContributions.reduce(toContributionMonthlySummary, {
    [ContributionSummaryShares.Fqde]: {
      id: ContributionSummaryShares.Fqde,
      description: i18next.t('reports.contributions.fqdeShare'),
    } as IContributionReportDetail,
    [ContributionSummaryShares.Association]: {
      id: ContributionSummaryShares.Association,
      description: i18next.t('reports.contributions.associationShare'),
    } as IContributionReportDetail,
  });

  return {
    period: getPeriodFromBudgetYear(serviceCentersContributions[0].budgetYear),
    accountingMonth: serviceCentersContributions[0].accountingMonth,
    details: Object.values(details),
  };
};

export const serviceCenterContributionsToReport = (
  serviceCenterContributions: IServiceCenterContributionReport,
): IContributionReport => {
  return {
    title: `${serviceCenterContributions.number} – ${serviceCenterContributions.name}`,
    summaryTitle: i18next.t('reports.contributions.serviceCenterSummary'),
    period: getPeriodFromBudgetYear(serviceCenterContributions.budgetYear),
    accountingMonth: serviceCenterContributions.accountingMonth,
    details: serviceCenterContributions.members.map(memberContributionToContributionDetail),
    contributorsCount: serviceCenterContributions.activeContributorsCount,
  };
};

export const contributionsToReport = (
  contributions: IContribution[],
  accountingMonth: AccountingMonth = AccountingMonth.June,
): IContributionReport => {
  return {
    accountingMonth,
    period: getPeriodFromBudgetYear(contributions[0].budgetYear),
    details: [contributions.reduce(contributionsToContributionDetail, {})],
  };
};
