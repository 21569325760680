export enum DataType {
  Text = 'text',
  Boolean = 'boolean',
  ActiveState = 'activeState',
  MemberStatus = 'memberStatus',
  JobType = 'jobType',
  Number = 'number',
  PostalCode = 'postalCode',
  PhoneNumber = 'phoneNumber',
  Date = 'date',
  MembershipIssue = 'membershipIssue',
  MembershipStatus = 'membershipStatus',
  MembershipProcessState = 'membershipProcessState',
  Association = 'association',
  AssociationType = 'associationType',
  ServiceCenter = 'serviceCenter',
  User = 'user',
  GricsSectionType = 'gricsSectionType',
  GricsEntryImportStatus = 'gricsEntryImportStatus',
  GricsImportStatus = 'gricsImportStatus',
  GricsImportChangeStatus = 'gricsImportChangeStatus',
  Gender = 'gender',
  Schooling = 'schooling',
  Role = 'role',
  UserRole = 'userRole',
  LevelOfSchooling = 'levelOfSchooling',
  Province = 'province',
  PaymentMethod = 'paymentMethod',
  Amount = 'amount',
  AgDelegateStatus = 'agDelegateStatus',
  OtherMemberStatus = 'otherMemberStatus',
  Authorization = 'authorization',
  PermissionAccessLevel = 'permissionAccessLevel',
  PermissionScope = 'permissionScope',
  AccountingMonth = 'accountingMonth',
}
