import { AssociationType } from '@common/constants/association-type';
import { getNumberValidationSchema, parseDateString } from '../utils/validation-schema';
import { IAssociationFinancialInfo } from './association-financial-info';
import { IHistory } from './history';
import { IMember } from './member';
import { IServiceCenter } from './service-center';

export interface IAssociation {
  id?: string;
  number: number;
  name: string;
  president: string;
  type: AssociationType;
  financialInfos: IAssociationFinancialInfo[];
  dateOfFounding?: Date;
  serviceCenters?: IServiceCenter[];
  members?: IMember[];
  history?: IHistory;
  contactName?: string;
  contactEmail?: string;
}

export const getAssociationValidationSchema = (Yup) =>
  Yup.object({
    number: getNumberValidationSchema(Yup, 1),
    name: Yup.string().max(250).required(),
    type: Yup.string().oneOf(Object.values(AssociationType)).required(),
    president: Yup.string().max(250),
    dateOfFounding: Yup.date().nullable().transform(parseDateString).max(new Date()),
    contactName: Yup.string().nullable().max(150),
    contactEmail: Yup.string().nullable().email().max(150),
  });

export const getAssociationInitialValues = (): IAssociation => ({
  number: null,
  name: '',
  type: AssociationType.Normal,
  president: '',
  financialInfos: [],
  dateOfFounding: null,
  contactName: '',
  contactEmail: '',
});
