import React, { useRef } from 'react';
import { Dropzone, IFileUploaded } from '@novo-electronique/react-dropzone';
import {
  FieldEvent,
  FieldWidth,
  FormFieldError,
  FormFieldLabel,
  useFormField,
} from '@novo-electronique/react-forms';

import { AccountingMonth } from '@common/constants/accounting-month';
import { acceptedFormats } from '@common/constants/global';
import { IGricsFileUploaded } from '@common/models/grics-import';
import GricsFileUploaded from '@modules/shared/components/GricsFilesField/GricsFileUploaded';
import { getAccountingMonthFromFileName } from '@modules/shared/components/GricsFilesField/utils';

export const GricsFileFieldContext = React.createContext<{
  onAccountingMonthChange: (id: string, accountingMonth: AccountingMonth) => void;
}>({ onAccountingMonthChange: null });

interface IProps {
  name: string;
  label?: string;
  upload: (file: IFileUploaded) => Promise<string>;
  remove?: (file: IFileUploaded) => Promise<void>;
  maxFiles?: number;
  disabled?: boolean;
  width?: FieldWidth;
  onChange?: FieldEvent<IGricsFileUploaded[]>;
}

function GricsFilesField({ name, label, width, disabled, onChange, ...dropzoneProps }: IProps) {
  const accountingMonthsMapRef = useRef<{ [id: string]: AccountingMonth }>({});

  const {
    fieldProps,
    setFieldValue,
    required,
    disabled: formDisabled,
    value,
  } = useFormField<IGricsFileUploaded[]>(name, width, { onChange });

  const onFileChange = (files: IFileUploaded[], isUploading: boolean) => {
    const value = isUploading
      ? []
      : files
          .filter((file) => file.id)
          .map(
            (file): IGricsFileUploaded => ({
              attachmentId: file.id,
              name: file.name,
              accountingMonth:
                accountingMonthsMapRef.current[file.id] !== undefined
                  ? accountingMonthsMapRef.current[file.id]
                  : getAccountingMonthFromFileName(file.name),
            }),
          );

    setFieldValue(name, value);
  };

  const onAccountingMonthChange = (id: string, accountingMonth: AccountingMonth) => {
    accountingMonthsMapRef.current[id] = accountingMonth;
    setFieldValue(
      name,
      value.map(
        (file): IGricsFileUploaded => ({
          ...file,
          accountingMonth:
            accountingMonthsMapRef.current[file.attachmentId] !== undefined
              ? accountingMonthsMapRef.current[file.attachmentId]
              : file.accountingMonth,
        }),
      ),
    );
  };

  return (
    <GricsFileFieldContext.Provider value={{ onAccountingMonthChange }}>
      <div {...fieldProps}>
        {label && (
          <div className="novoFormField__name">
            <FormFieldLabel required={required}>{label}</FormFieldLabel>
          </div>
        )}
        <Dropzone
          {...dropzoneProps}
          onChange={onFileChange}
          labelsNamespace="general.forms.dropzone"
          disabled={disabled || formDisabled}
          fileUploadedComponent={GricsFileUploaded}
          accept={acceptedFormats}
          multiple
        />
        <FormFieldError name={name} />
      </div>
    </GricsFileFieldContext.Provider>
  );
}
export default GricsFilesField;
