import { AccessLevel, PermissionScope } from '@common/constants/permission';
import { IUser, User } from '@common/models/user';

export function hasPermission(
  scopes: PermissionScope | PermissionScope[],
  accessLevel: AccessLevel,
  user: IUser | User,
) {
  if (!scopes) {
    return true;
  }

  scopes = Array.isArray(scopes) ? scopes : [scopes];
  return scopes.some(
    (scope) => user && user.permissions && (user.permissions[scope] || AccessLevel.None) >= accessLevel,
  );
}
