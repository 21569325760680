import React from 'react';
import { useTranslation } from 'react-i18next';

import { AppRoutePages, pagesPermissionScopes } from '@constants/routes';
import Menu, { MenuItem, MenuStyles } from '@modules/shared/components/Menu';

function AdministrationSubMenu() {
  const { t } = useTranslation();

  return (
    <Menu style={MenuStyles.SubMenu}>
      <MenuItem to={AppRoutePages.Users} scopes={pagesPermissionScopes[AppRoutePages.Users]}>
        {t('usersPage.menu')}
      </MenuItem>
      <MenuItem to={AppRoutePages.Params} scopes={pagesPermissionScopes[AppRoutePages.Params]}>
        {t('paramsPage.menu')}
      </MenuItem>
    </Menu>
  );
}

export default AdministrationSubMenu;
