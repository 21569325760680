import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DataTable, useDataTable } from '@novo-electronique/react-data-table';

import { AccessLevel, PermissionScope } from '@common/constants/permission';
import { ISchool } from '@common/models/school';
import { selectUser } from '@modules/app/redux/selectors';
import { generateColumnDefinition } from '@modules/data-table/column-definition-factory';
import { DataTableSource } from '@modules/data-table/data-table-source';
import { saveSchool } from '@modules/members/services/schools';
import Layout, { LayoutContent, LayoutHeader } from '@modules/shared/components/Layout/';
import Toolbar, { ToolbarActionType } from '@modules/shared/components/Toolbar';
import { hasPermission } from '@modules/shared/permission';
import { useQuery } from '@modules/shared/useQuery';
import { AppRoutePages } from '@src/constants';
import SchoolEditionForm from './components/SchoolEditionForm';

function SchoolsPage() {
  const { t } = useTranslation();
  const currentUser = useSelector(selectUser);
  const query = useQuery();
  const history = useHistory();

  const schoolId = query.get('id');

  const onTableSelectionChanged = ({ id }: Partial<ISchool>) => {
    history.push(`${AppRoutePages.Schools}?id=${id}`);
  };

  const { gridApi, gridProperties, resetFilters, exportToCsv, exportToXlsx, refresh } = useDataTable(
    DataTableSource.Schools,
    generateColumnDefinition(DataTableSource.Schools),
    onTableSelectionChanged,
    { options: { getRowNodeId: (data) => data.id } },
  );

  useEffect(() => {
    if (schoolId) {
      gridApi?.getRowNode(schoolId)?.setSelected(true);
    }
  }, [schoolId]);

  const addSchool = () => {
    history.push(`${AppRoutePages.Schools}?id`);
  };

  const onSubmit = (values: ISchool) => {
    return saveSchool(values).then(() => {
      history.push(AppRoutePages.Schools);
      refresh({ purge: true });
    });
  };

  const onCancel = () => {
    history.push(AppRoutePages.Schools);
  };

  const hasFullAccess = hasPermission(PermissionScope.School, AccessLevel.Full, currentUser);
  return (
    <Layout>
      <LayoutHeader title={t('schoolsPage.title')}>
        <Toolbar
          actions={{
            [ToolbarActionType.Add]: hasFullAccess && addSchool,
            [ToolbarActionType.Export]: [
              { action: exportToCsv, label: 'CSV' },
              { action: exportToXlsx, label: 'XLSX' },
            ],
            [ToolbarActionType.FilterOff]: resetFilters,
            [ToolbarActionType.Refresh]: () => refresh({ purge: true }),
          }}
        />
      </LayoutHeader>

      <LayoutContent>
        <DataTable {...gridProperties} />

        {schoolId !== null && (
          <SchoolEditionForm
            schoolId={schoolId}
            readonly={!hasFullAccess}
            submitHandler={onSubmit}
            cancelHandler={onCancel}
          />
        )}
      </LayoutContent>
    </Layout>
  );
}

export default SchoolsPage;
