import React, { ReactNode, ReactNodeArray, useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { AccordionContext } from './Accordion';

const transitionDuration = 250;

interface IProps {
  value: number | string;
  title: string | ReactNode | ReactNodeArray;
  children: string | ReactNode | ReactNodeArray;
  active?: boolean;
}

function AccordionItem({ value, title, children, active }: IProps) {
  const ref = useRef(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const { value: activeValue, onChange } = useContext(AccordionContext);

  useEffect(() => {
    if (active) {
      onChange(value);
    }
    const timeout = setTimeout(() => setIsInitialized(true), transitionDuration);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);

  const isVisible = value === activeValue;
  const className = classNames('f4eAccordionItem', {
    'f4eAccordionItem--visible': isVisible,
    'f4eAccordionItem--no-animation': !isInitialized,
  });
  return (
    <article className={className}>
      <h1 className="f4eAccordionItem__title">
        <button className="f4eAccordionItem__title__button" onClick={() => onChange(value)}>
          {title}
        </button>
      </h1>
      <div
        className="f4eAccordionItem__content"
        ref={ref}
        style={{
          maxHeight: isVisible ? ref.current?.scrollHeight : 0,
          transitionDuration: `${transitionDuration}ms`,
        }}
      >
        <div className="f4eAccordionItem__content__wrapper">{children}</div>
      </div>
    </article>
  );
}

export default AccordionItem;
