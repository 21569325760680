import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DataTable, useDataTable } from '@novo-electronique/react-data-table';

import { AccessLevel, PermissionScope } from '@common/constants/permission';
import { IMemberChangesRequest } from '@common/models/member-changes-request';
import { selectUser } from '@modules/app/redux/selectors';
import { generateColumnDefinition } from '@modules/data-table/column-definition-factory';
import { DataTableSource } from '@modules/data-table/data-table-source';
import Layout, { LayoutContent, LayoutHeader } from '@modules/shared/components/Layout';
import Toolbar, { ToolbarActionType } from '@modules/shared/components/Toolbar';
import { hasPermission } from '@modules/shared/permission';
import { useQuery } from '@modules/shared/useQuery';
import { AppRoutePages } from '@src/constants';
import ChangeRequestDetails from './components/ChangeRequestDetails';

function ChangesRequestsPage() {
  const { t } = useTranslation();
  const currentUser = useSelector(selectUser);
  const query = useQuery();
  const history = useHistory();

  const changeRequestId = query.get('id');

  const onTableSelectionChanged = ({ id }: Partial<IMemberChangesRequest>) => {
    history.push(`${AppRoutePages.ChangesRequests}?id=${id}`);
  };

  const { gridApi, gridProperties, refresh, resetFilters } = useDataTable(
    DataTableSource.ChangesRequests,
    generateColumnDefinition(DataTableSource.ChangesRequests),
    onTableSelectionChanged,
    {
      options: { showSidebar: false, getRowNodeId: (data) => data.id },
    },
  );

  useEffect(() => {
    if (changeRequestId) {
      gridApi?.getRowNode(changeRequestId)?.setSelected(true);
    }
  }, [changeRequestId]);

  const closeDetailForm = (actionTaken?: boolean) => {
    history.push(AppRoutePages.ChangesRequests);
    if (actionTaken) refresh({ purge: true });
  };

  const hasFullAccess = hasPermission(PermissionScope.Member, AccessLevel.Full, currentUser);
  return (
    <Layout>
      <LayoutHeader title={t('changesRequestsPage.title')}>
        <Toolbar
          actions={{
            [ToolbarActionType.FilterOff]: resetFilters,
            [ToolbarActionType.Refresh]: () => refresh({ purge: true }),
          }}
        />
      </LayoutHeader>
      <LayoutContent>
        <DataTable {...gridProperties} />

        {changeRequestId !== null && (
          <ChangeRequestDetails
            changeRequestId={changeRequestId}
            readonly={!hasFullAccess}
            closeHandler={closeDetailForm}
          />
        )}
      </LayoutContent>
    </Layout>
  );
}

export default ChangesRequestsPage;
