import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  Form,
  FormActionButton,
  FormActionButtonType,
  FormContext,
  FormDateField,
  FormFields,
  FormFieldSpacer,
  FormSelectField,
  FormSelectFieldOptions,
  FormTextField,
  IFormErrors,
} from '@novo-electronique/react-forms';
import {
  SidePanelContent,
  SidePanelFooter,
  SidePanelFooterActions,
  SidePanelHeader,
  SidePanelHeaderNav,
} from '@novo-electronique/react-side-panel';
import { Tab, TabContext, TabList, TabPanel } from '@novo-electronique/react-tabs';
import * as Yup from 'yup';

import {
  getAssociationInitialValues,
  getAssociationValidationSchema,
  IAssociation,
} from '@common/models/association';
import { associationTypeValueProvider } from '@common/value-provider';
import { setAppIsLoading } from '@modules/app/redux/action';
import { DataTableSource } from '@modules/data-table/data-table-source';
import History from '@modules/history/History';
import { getAssociation } from '@modules/members/services/associations';
import { valueProviderToOptions } from '@modules/shared/components/Form/utils';
import SidePanel from '@modules/shared/components/SidePanel';
import AssociationFinanceForm from './AssociationFinanceForm';

interface IProps {
  associationId: string;
  readonly: boolean;
  submitHandler: (values: IAssociation, setErrors?: (errors: IFormErrors) => void) => Promise<void>;
  cancelHandler: () => void;
}

enum HeaderTabs {
  Association = 'association',
  Finance = 'finance',
  History = 'history',
}

function AssociationEditionForm({ associationId, readonly, submitHandler, cancelHandler }: IProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState(null);
  const [selectedTab, setSelectedTab] = useState<HeaderTabs>(HeaderTabs.Association);

  useEffect(() => {
    if (associationId) {
      dispatch(setAppIsLoading(true));
      getAssociation(associationId)
        .then((association: IAssociation) => {
          setInitialValues(association);
        })
        .finally(() => dispatch(setAppIsLoading(false)));
    } else {
      setInitialValues(getAssociationInitialValues());
    }
  }, [associationId]);

  if (!initialValues) {
    return null;
  }
  return (
    <FormContext
      initialValues={initialValues}
      validationSchema={getAssociationValidationSchema(Yup)}
      onSubmit={submitHandler}
    >
      <SidePanel closeHandler={cancelHandler}>
        <TabContext onChange={(value) => setSelectedTab(value as HeaderTabs)}>
          <SidePanelHeader displayCloseButton={true}>
            {associationId ? initialValues.name : t('associationsPage.addAssociation')}
          </SidePanelHeader>
          {associationId && (
            <SidePanelHeaderNav isHost>
              <TabList>
                <Tab value={HeaderTabs.Association} active>
                  {t('associationsPage.tabs.association')}
                </Tab>
                <Tab value={HeaderTabs.Finance}>{t('associationsPage.tabs.finance')}</Tab>
                <Tab value={HeaderTabs.History}>{t('associationsPage.tabs.history')}</Tab>
              </TabList>
            </SidePanelHeaderNav>
          )}

          <Form readonly={readonly} hidden={selectedTab !== HeaderTabs.Association}>
            <SidePanelContent>
              <FormFields>
                <FormTextField name="number" label={t('models.association.number')} autoFocus />
                <FormTextField name="name" label={t('models.association.name')} />
                <FormSelectField name="type" label={t('models.association.type')}>
                  <FormSelectFieldOptions options={valueProviderToOptions(associationTypeValueProvider)} />
                </FormSelectField>
                <FormDateField
                  name="dateOfFounding"
                  label={t('models.association.dateOfFounding')}
                  maxDate={new Date()}
                />
                <FormTextField name="president" label={t('models.association.president')} />
                <FormFieldSpacer width={50} />
                <FormTextField name="contactName" label={t('models.association.contactName')} />
                <FormTextField name="contactEmail" label={t('models.association.contactEmail')} />
              </FormFields>
            </SidePanelContent>

            {!readonly && selectedTab === HeaderTabs.Association && (
              <SidePanelFooter>
                <SidePanelFooterActions>
                  <FormActionButton type={FormActionButtonType.Submit} small={true}>
                    {t('general.forms.save')}
                  </FormActionButton>
                </SidePanelFooterActions>
                <SidePanelFooterActions isSecondary={true}>
                  <FormActionButton type={FormActionButtonType.Reset} small={true} />
                </SidePanelFooterActions>
              </SidePanelFooter>
            )}
          </Form>

          {associationId && (
            <SidePanelContent
              hidden={selectedTab !== HeaderTabs.Finance && selectedTab !== HeaderTabs.History}
            >
              <TabPanel value={HeaderTabs.Finance}>
                <AssociationFinanceForm associationId={associationId} />
              </TabPanel>
              <TabPanel value={HeaderTabs.History}>
                <History
                  id={initialValues.history?.id}
                  datasource={DataTableSource.Associations}
                  hidden={selectedTab !== HeaderTabs.History}
                />
              </TabPanel>
            </SidePanelContent>
          )}
        </TabContext>
      </SidePanel>
    </FormContext>
  );
}

export default AssociationEditionForm;
