import { AppActions, AppActionsTypes } from './action';
import { IAppState, initialAppState } from './state';

const appReducer = (state: IAppState = initialAppState, action: AppActions): IAppState => {
  switch (action.type) {
    case AppActionsTypes.AppInitialized: {
      return {
        ...state,
        isInitialized: true,
      };
    }
    case AppActionsTypes.SetIsLoading: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case AppActionsTypes.AddOrUpdateBackgroundTask: {
      let backgroundTasks;
      const index = state.backgroundTasks.findIndex((task) => task.id === action.payload.id);

      if (index !== -1) {
        backgroundTasks = [...state.backgroundTasks];
        backgroundTasks[index] = action.payload;
      } else {
        backgroundTasks = [...state.backgroundTasks, action.payload];
      }

      return {
        ...state,
        backgroundTasks,
      };
    }
    case AppActionsTypes.RemoveBackgroundTask: {
      return {
        ...state,
        backgroundTasks: [...state.backgroundTasks].filter((task) => task.id !== action.payload),
      };
    }
    case AppActionsTypes.SetActiveUser: {
      return {
        ...state,
        user: action.payload,
      };
    }
    case AppActionsTypes.DisconnectActiveUser: {
      return {
        ...state,
        user: null,
      };
    }
    case AppActionsTypes.SetCacheLoaded: {
      return {
        ...state,
        cache: {
          ...state.cache,
          isLoaded: action.payload,
        },
      };
    }
    case AppActionsTypes.SetAssociationEntity: {
      return {
        ...state,
        cache: {
          ...state.cache,
          association: action.payload,
        },
      };
    }
    case AppActionsTypes.SetServiceCenterEntity: {
      return {
        ...state,
        cache: {
          ...state.cache,
          serviceCenter: action.payload,
        },
      };
    }
    case AppActionsTypes.SetUserEntity: {
      return {
        ...state,
        cache: {
          ...state.cache,
          user: action.payload,
        },
      };
    }
    default:
      return state;
  }
};

export default appReducer;
