import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Form,
  FormActionButton,
  FormActionButtonType,
  FormContext,
  FormDateField,
  FormFields,
  FormPhoneField,
  FormPostalCodeField,
  FormSelectField,
  FormSelectFieldOptions,
  FormTextField,
  IFormErrors,
} from '@novo-electronique/react-forms';
import {
  SidePanelContent,
  SidePanelFooter,
  SidePanelFooterActions,
  SidePanelHeader,
  SidePanelHeaderNav,
} from '@novo-electronique/react-side-panel';
import { Tab, TabContext, TabList, TabPanel } from '@novo-electronique/react-tabs';
import * as Yup from 'yup';

import {
  getServiceCenterInitialValues,
  getServiceCenterValidationSchema,
  IServiceCenter,
} from '@common/models/service-center';
import { setAppIsLoading } from '@modules/app/redux/action';
import { selectAssociations, selectAssociationValueProvider } from '@modules/app/redux/selectors';
import { DataTableSource } from '@modules/data-table/data-table-source';
import History from '@modules/history/History';
import { toOptions } from '@modules/members/services/associations';
import { getServiceCenter } from '@modules/members/services/service-centers';
import SidePanel from '@modules/shared/components/SidePanel';

interface IProps {
  serviceCenterId: string;
  readonly: boolean;
  submitHandler: (values: IServiceCenter, setErrors?: (errors: IFormErrors) => void) => Promise<void>;
  cancelHandler: () => void;
}

enum HeaderTabs {
  ServiceCenter = 'serviceCenter',
  History = 'history',
}

function ServiceCenterEditionForm({ serviceCenterId, readonly, submitHandler, cancelHandler }: IProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const associations = useSelector(selectAssociations);
  const associationValueProvider = useSelector(selectAssociationValueProvider);

  const [subTitle, setSubTitle] = useState(' ');
  const [initialValues, setInitialValues] = useState(null);
  const [selectedTab, setSelectedTab] = useState<HeaderTabs>();

  useEffect(() => {
    if (serviceCenterId) {
      dispatch(setAppIsLoading(true));
      getServiceCenter(serviceCenterId)
        .then((serviceCenter: IServiceCenter) => {
          if (serviceCenter.association) {
            setSubTitle(associationValueProvider.label(serviceCenter.association.id));
          }
          setInitialValues({ ...serviceCenter, association: { id: serviceCenter.association?.id } });
        })
        .finally(() => dispatch(setAppIsLoading(false)));
    } else {
      setInitialValues(getServiceCenterInitialValues());
    }
  }, [serviceCenterId]);

  if (!initialValues) {
    return null;
  }
  return (
    <FormContext
      initialValues={initialValues}
      validationSchema={getServiceCenterValidationSchema(Yup)}
      onSubmit={submitHandler}
    >
      <SidePanel closeHandler={cancelHandler}>
        <TabContext onChange={(value) => setSelectedTab(value as HeaderTabs)}>
          <SidePanelHeader subTitle={subTitle} displayCloseButton={true}>
            {serviceCenterId ? initialValues.name : t('serviceCenterPage.addServiceCenter')}
          </SidePanelHeader>
          {serviceCenterId && (
            <SidePanelHeaderNav isHost>
              <TabList>
                <Tab value={HeaderTabs.ServiceCenter} active>
                  {t('serviceCenterPage.tabs.serviceCenter')}
                </Tab>
                <Tab value={HeaderTabs.History}>{t('serviceCenterPage.tabs.history')}</Tab>
              </TabList>
            </SidePanelHeaderNav>
          )}
          <Form readonly={readonly}>
            <SidePanelContent>
              <TabPanel value={HeaderTabs.ServiceCenter} visible={!serviceCenterId}>
                <FormFields>
                  <FormTextField name="number" label={t('models.serviceCenter.number')} autoFocus />
                  <FormTextField name="name" label={t('models.serviceCenter.name')} />
                  <FormSelectField name="association.id" label={t('models.serviceCenter.association')}>
                    <FormSelectFieldOptions options={toOptions(associations)} keepOriginalOrder />
                  </FormSelectField>
                  <FormDateField
                    name="dateOfIntegration"
                    label={t('models.serviceCenter.dateOfIntegration')}
                    maxDate={new Date()}
                  />
                  <FormTextField name="address" label={t('models.serviceCenter.address')} />
                  <FormTextField name="city" label={t('models.serviceCenter.city')} />
                  <FormPostalCodeField name="postalCode" label={t('models.serviceCenter.postalCode')} />
                  <FormPhoneField name="phoneNumber" label={t('models.serviceCenter.phoneNumber')} />
                  <FormTextField name="domain" label={t('models.serviceCenter.domain')} />
                </FormFields>
              </TabPanel>

              {serviceCenterId && (
                <TabPanel value={HeaderTabs.History}>
                  <History
                    id={initialValues.history?.id}
                    datasource={DataTableSource.ServiceCenters}
                    hidden={selectedTab !== HeaderTabs.History}
                  />
                </TabPanel>
              )}
            </SidePanelContent>

            {!readonly && (
              <SidePanelFooter>
                <SidePanelFooterActions>
                  <FormActionButton type={FormActionButtonType.Submit} small={true}>
                    {t('general.forms.save')}
                  </FormActionButton>
                </SidePanelFooterActions>
                <SidePanelFooterActions isSecondary={true}>
                  <FormActionButton type={FormActionButtonType.Reset} small={true} />
                </SidePanelFooterActions>
              </SidePanelFooter>
            )}
          </Form>
        </TabContext>
      </SidePanel>
    </FormContext>
  );
}

export default ServiceCenterEditionForm;
