import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import Initializing from '@modules/shared/components/Initializing';
import { SimpleLayout } from '@modules/shared/components/Layout';
import MembershipAlreadyCompleted from './components/MembershipAlreadyCompleted';
import MembershipCompleted from './components/MembershipCompleted';
import MembershipUnknown from './components/MembershipUnknown';
import { confirmMembership, MembershipConfirmationStatus } from './service';

function MembershipConfirmationPage() {
  const { t } = useTranslation();
  const queryParams = new URLSearchParams(useLocation().search);
  const [confirmationStatus, setConfirmationStatus] = useState<MembershipConfirmationStatus>(null);

  useEffect(() => {
    confirmMembership(queryParams.get('token')).then((status: MembershipConfirmationStatus) => {
      setConfirmationStatus(status);
    });
  }, []);

  if (!confirmationStatus) {
    return <Initializing />;
  }

  const renderContent = () => {
    switch (confirmationStatus) {
      case MembershipConfirmationStatus.Confirmed:
        return <MembershipCompleted />;
      case MembershipConfirmationStatus.AlreadyConfirmed:
        return <MembershipAlreadyCompleted />;
      case MembershipConfirmationStatus.Unknown:
        return <MembershipUnknown />;
    }
  };

  return (
    <SimpleLayout title={t('membershipPage.title')}>
      <div className="f4eMembership--confirmation">{renderContent()}</div>
    </SimpleLayout>
  );
}

export default MembershipConfirmationPage;
