import './App.scss';
import '@novo-electronique/react-blanket/dist/index.css';
import '@novo-electronique/react-tooltip/dist/index.css';
import '@novo-electronique/react-circular-progress/dist/index.css';
import '@novo-electronique/react-button/dist/index.css';
import '@novo-electronique/react-dropzone/dist/index.css';
import '@novo-electronique/react-forms/dist/index.css';
import '@novo-electronique/react-data-table/dist/index.css';
import '@novo-electronique/react-side-panel/dist/index.css';
import '@novo-electronique/react-tabs/dist/index.css';
import 'react-toastify/dist/ReactToastify.css';

import React, { Suspense, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { DataTableProvider } from '@novo-electronique/react-data-table';
import classNames from 'classnames';

import environment, { Environments } from '@config/env';
import { updateMassUpdateBackgroundTaskFeedback } from '@modules/admin/service';
import AppRouter from '@modules/app/AppRouter';
import {
  selectCache,
  selectIsAppInitialized,
  selectIsAppLoading,
  selectUser,
} from '@modules/app/redux/selectors';
import { CachedEntity } from '@modules/app/redux/state';
import { refreshCache } from '@modules/app/service';
import { checkIfAuthenticated } from '@modules/auth/service';
import getColumnTypes from '@modules/data-table/column-types';
import { getCsvFile, getData, getXlsxFile } from '@modules/data-table/service';
import { updateGricsBackgroundTaskFeedback } from '@modules/finances/service';
import Alert from '@modules/shared/components/Alert';
import BackgroundTaskFeedback from '@modules/shared/components/BackgroundTaskFeedback';
import Initializing from '@modules/shared/components/Initializing';

function App() {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectUser);
  const isInitialized = useSelector(selectIsAppInitialized);
  const isLoading = useSelector(selectIsAppLoading);
  const cache = useSelector(selectCache);

  const [isDisplayingAlert, setIsDisplayingAlert] = useState<boolean>(
    [Environments.Development, Environments.Staging].includes(environment.env) &&
      !window.sessionStorage.getItem('alert'),
  );

  useEffect(() => {
    dispatch(checkIfAuthenticated());
    dispatch(refreshCache([CachedEntity.ServiceCenter]));
  }, []);

  useEffect(() => {
    if (currentUser) {
      dispatch(refreshCache([CachedEntity.Association, CachedEntity.User]));
      dispatch(updateGricsBackgroundTaskFeedback());
      dispatch(updateMassUpdateBackgroundTaskFeedback());
    }
  }, [currentUser]);

  const hideAlert = () => {
    window.sessionStorage.setItem('alert', 'true');
    setIsDisplayingAlert(false);
  };

  const columnTypes = getColumnTypes(cache);
  const className = classNames('f4eApp', { 'f4eApp--is-loading': isLoading });

  return (
    <div className={className}>
      {isDisplayingAlert && (
        <Alert onClose={hideAlert}>
          <Trans
            i18nKey="general.alertMessage"
            components={{
              a: <a href="https://membres.fqde.qc.ca/" target="_blank" rel="noopener noreferrer" />,
            }}
          />
        </Alert>
      )}

      {(!isInitialized || !cache.isLoaded) && <Initializing />}
      {isInitialized && cache.isLoaded && (
        <Suspense fallback={null}>
          <DataTableProvider
            columnTypes={columnTypes}
            getData={getData}
            getCsvFile={getCsvFile}
            getXlsxFile={getXlsxFile}
          >
            <AppRouter />
            <BackgroundTaskFeedback />
          </DataTableProvider>
          <ToastContainer />
        </Suspense>
      )}
    </div>
  );
}

export default App;
