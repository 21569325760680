import { toast } from 'react-toastify';
import { IOption } from '@novo-electronique/react-forms';
import i18n from 'i18next';

import { MembershipProcessState, MembershipStatus } from '@common/constants/membership';
import { AccessLevel } from '@common/constants/permission';
import { IMember } from '@common/models/member';
import { IMembership } from '@common/models/membership';
import { getTokenPayload } from '@common/utils/token';
import { membershipProcessStateValueProvider, membershipStatusValueProvider } from '@common/value-provider';
import apiClient from '@modules/shared/api-client';
import { valueProviderToOptions } from '@modules/shared/components/Form/utils';
import { handleServiceException } from '@modules/shared/service-error-handler';

export enum MembershipConfirmationStatus {
  Confirmed = 'confirmed',
  AlreadyConfirmed = 'already-confirmed',
  Unknown = 'unknown',
}

export function getAdherent(token: string): Promise<IMember> {
  return apiClient.get<IMember>(`/membership/adherent/${token}`).catch((error) => {
    const { accessLevel } = getTokenPayload(token);

    if (error.payload?.statusCode === 404 && accessLevel === AccessLevel.Full) {
      toast.info(i18n.t('membershipPage.error_already-filled'), { autoClose: false });
    } else if (accessLevel === AccessLevel.Read) {
      toast.error(i18n.t('membershipPage.error_invalid-token'), { autoClose: false });
    }

    throw error;
  });
}

export function saveAdherent(token: string, member: IMember): Promise<void> {
  if (token !== null) {
    return apiClient.put(`/membership/adherent/${token}`, member);
  }
  return apiClient.post('/membership/adherent', member);
}

export function getMembership(id: string): Promise<IMembership> {
  return apiClient.get<IMembership>(`/membership/${id}`).catch(handleServiceException);
}

export function confirmMembership(token: string): Promise<MembershipConfirmationStatus> {
  return apiClient
    .post(`/membership/confirm`, { token })
    .then(() => {
      return MembershipConfirmationStatus.Confirmed;
    })
    .catch((error) => {
      if (error.payload?.statusCode === 409) return MembershipConfirmationStatus.AlreadyConfirmed;

      return MembershipConfirmationStatus.Unknown;
    });
}

export function updateMembership(
  id: string,
  status: MembershipStatus,
  externalAccountCreated: boolean,
  notes: string,
): Promise<void> {
  return apiClient
    .put<void>(`/membership/${id}`, { status, externalAccountCreated, notes })
    .catch(handleServiceException);
}

export const statusOptions = (): IOption[] => {
  return valueProviderToOptions(membershipStatusValueProvider);
};

export const statusOptionsFiltered = (membership: IMembership): IOption[] => {
  return statusOptions().filter(({ value }) => {
    if (
      membership.processState === MembershipProcessState.PendingFqdeCompletion ||
      value === MembershipStatus.Cancelled
    ) {
      return true;
    } else if (membership.processState === MembershipProcessState.Completed) {
      return value === MembershipStatus.Completed;
    } else {
      return value === MembershipStatus.Processing;
    }
  });
};

export const processStateOptions = (): IOption[] => {
  return valueProviderToOptions(membershipProcessStateValueProvider);
};
