import { IAdminState, initialAdminState } from '@modules/admin/redux/state';
import { IAppState, initialAppState } from '@modules/app/redux/state';
import { IAuthState, initialAuthState } from '@modules/auth/redux/state';

export interface IRootState {
  app: IAppState;
  auth: IAuthState;
  admin: IAdminState;
}

const initialRootState: IRootState = {
  app: initialAppState,
  auth: initialAuthState,
  admin: initialAdminState,
};

export default initialRootState;
