import './History.scss';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IHistory } from '@common/models/history';
import { DataTableSource } from '@modules/data-table/data-table-source';
import Initializing from '@modules/shared/components/Initializing';
import ChangeRecords from './components/ChangeRecords';
import { getHistory } from './service';

interface IProps {
  id?: string;
  datasource: DataTableSource;
  hidden: boolean;
  excludedProperties?: string[];
}

function History({ id, datasource, hidden = false, excludedProperties = [] }: IProps) {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [history, setHistory] = useState<IHistory>(null);

  useEffect(() => {
    let isCancelled = false;

    if (!hidden) {
      if (!history || history.id !== id) {
        getHistory(id)
          .then((history: IHistory) => {
            if (!isCancelled) {
              setHistory(history);
            }
          })
          .catch(() => {})
          .finally(() => setLoading(false));
      }
    }

    return () => {
      isCancelled = true;
    };
  }, [id, hidden]);

  if (isLoading) return <Initializing />;
  if (!history || history.records.length === 0) return <p>{t('history.empty')}</p>;

  return (
    <ChangeRecords
      datasource={datasource}
      records={history.records}
      excludedProperties={excludedProperties}
    />
  );
}

export default History;
