import React from 'react';

import { AppRoutePages } from '@constants/routes';
import ChangesRequestsPage from '@modules/members/ChangesRequestsPage';
import AssociationsPage from './AssociationsPage';
import MembershipsPage from './MembershipsPage';
import MembersPage from './MembersPage';
import SchoolsPage from './SchoolsPage';
import ServiceCentersPage from './ServiceCentersPage';

export default [
  { path: AppRoutePages.Members, component: <MembersPage /> },
  { path: AppRoutePages.Schools, component: <SchoolsPage /> },
  { path: AppRoutePages.ServiceCenters, component: <ServiceCentersPage /> },
  { path: AppRoutePages.Associations, component: <AssociationsPage /> },
  { path: AppRoutePages.Memberships, component: <MembershipsPage /> },
  { path: AppRoutePages.ChangesRequests, component: <ChangesRequestsPage /> },
];
