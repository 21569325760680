import React from 'react';
import { useTranslation } from 'react-i18next';

import { AppRoutePages, pagesPermissionScopes } from '@constants/routes';
import Menu, { MenuItem, MenuStyles } from '@modules/shared/components/Menu';

function FinancesSubMenu() {
  const { t } = useTranslation();

  return (
    <Menu style={MenuStyles.SubMenu}>
      <MenuItem to={AppRoutePages.Payments} scopes={pagesPermissionScopes[AppRoutePages.Payments]}>
        {t('paymentsPage.menu')}
      </MenuItem>
      <MenuItem to={AppRoutePages.GricsImport} scopes={pagesPermissionScopes[AppRoutePages.GricsImport]}>
        {t('gricsImportPage.menu')}
      </MenuItem>
      <MenuItem
        to={AppRoutePages.ContributionReport}
        scopes={pagesPermissionScopes[AppRoutePages.ContributionReport]}
      >
        {t('contributionReportPage.menu')}
      </MenuItem>
      <MenuItem
        to={AppRoutePages.CumulativeConciliationReport}
        scopes={pagesPermissionScopes[AppRoutePages.CumulativeConciliationReport]}
      >
        {t('cumulativeConciliationReportPage.menu')}
      </MenuItem>
    </Menu>
  );
}

export default FinancesSubMenu;
