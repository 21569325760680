export enum DataTableSource {
  Members = '/member/query',
  Associations = '/association/query',
  ServiceCenters = '/service-center/query',
  Memberships = '/membership/query',
  ChangesRequests = '/member/changes/query',
  GricsImports = '/grics/import/query',
  ContributionsDue = '/contribution/due/query',
  Users = '/user/query',
  Schools = '/school/query',
}
