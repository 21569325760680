import './Initializing.scss';

import React from 'react';

import { ReactComponent as LoadingImage } from '@assets/loading.svg';

export default function Initializing() {
  return (
    <div className="f4eInitializing">
      <LoadingImage className="f4eInitializing__image" />
    </div>
  );
}
