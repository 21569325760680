import './Report.scss';

import React, { ReactNode } from 'react';

import ReportHeader from './ReportHeader';

interface IProps {
  id?: string | number;
  title: string;
  subTitle: string;
  children: ReactNode;
  mention?: string;
  includeReportHeader?: boolean;
}

function Report({ id, children, title, subTitle, mention, includeReportHeader = true }: IProps) {
  return (
    <article className="f4eReport" data-report-id={id}>
      <ReportHeader
        title={title}
        subTitle={subTitle}
        mention={mention}
        includeReportHeader={includeReportHeader}
      />
      {children}
    </article>
  );
}

export default Report;
