import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps, useHistory } from 'react-router-dom';

import env from '@config/env';
import { AppRoutePages } from '@constants/routes';
import { selectUser } from '@modules/app/redux/selectors';
import { signOut } from '@modules/auth/service';
import { IdleTimer } from '@modules/shared/idle-timer';
import { useThunkDispatch } from '@src/redux/useThunkDispatch';

export default function AppProtectedRoute({ children, ...rest }: RouteProps) {
  const currentUser = useSelector(selectUser);
  const history = useHistory();
  const dispatch = useThunkDispatch();

  useEffect(() => {
    const timer = new IdleTimer(env.sessionTimeout, window.sessionStorage);
    timer.run(() => dispatch(signOut()).then(() => history.push(AppRoutePages.Home)));

    return () => timer.dispose();
  }, []);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        currentUser ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: AppRoutePages.Home,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
