import './Icon.scss';

import React from 'react';

export enum IconType {
  Add = 'add',
  Download = 'download',
  Export = 'export',
  FilterOff = 'filterOff',
  Refresh = 'refresh',
  Print = 'print',
  Edit = 'edit',
  EditCalendar = 'edit-calendar',
  View = 'view',
}

interface IProps {
  type: IconType;
}

function Icon({ type }: IProps) {
  return <i className={`material-icons f4eIcon f4eIcon--${type}`} />;
}

export default Icon;
