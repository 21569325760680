import './SimpleLayout.scss';

import React, { ReactNode, ReactNodeArray } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { ReactComponent as Logo } from '@assets/logo.svg';
import ErrorBoundary from '@modules/shared/components/ErrorBoundary/ErrorBoundary';
import DocumentTitle from '../DocumentTitle/DocumentTitle';

interface IProps {
  children: ReactNode | ReactNodeArray;
  title?: string;
  width?: 'large' | 'medium' | 'small';
}

export default function SimpleLayout({ children, title, width }: IProps) {
  const { t } = useTranslation();
  const layoutClassName = classNames('f4eSimpleLayout', { [`f4eSimpleLayout--${width}`]: width });
  const headerClassName = classNames('f4eSimpleLayout__panel__header', {
    'f4eSimpleLayout__panel__header--titled': title,
  });

  return (
    <div className={layoutClassName}>
      <DocumentTitle>{t('general.siteTitle')}</DocumentTitle>
      <article className="f4eSimpleLayout__panel">
        <header className={headerClassName}>
          <Logo className="f4eSimpleLayout__panel__header__logo" />
          {title && <h1 className="f4eSimpleLayout__panel__header__title">{title}</h1>}
        </header>
        <div className="f4eSimpleLayout__panel__content">
          <ErrorBoundary>{children}</ErrorBoundary>
        </div>
      </article>
    </div>
  );
}
