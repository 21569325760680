import './Toolbar.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonSize, ButtonStyle } from '@novo-electronique/react-button';

import Icon from '@modules/shared/components/Icon';
import { IconType } from '@modules/shared/components/Icon/Icon';
import ToolbarButton from '@modules/shared/components/Toolbar/ToolbarButton';
import DropdownButton from '../DropdownButton';
import { IToolbarAction } from './models';

export enum ToolbarActionType {
  Add = 'add',
  Download = 'download',
  Export = 'export',
  FilterOff = 'filterOff',
  Refresh = 'refresh',
  Print = 'print',
  Edit = 'edit',
  View = 'view',
}

interface IProps {
  actions?: Partial<{ [type in ToolbarActionType]: (() => void) | IToolbarAction | IToolbarAction[] }>;
}

function Toolbar({ actions = {} }: IProps) {
  const { t } = useTranslation();

  return (
    <div className="f4eToolbar" role="toolbar">
      {Object.keys(actions)
        .filter((type) => actions[type])
        .map((type) => {
          const toolbarAction: (() => void) | IToolbarAction | IToolbarAction[] = actions[type];

          if (Array.isArray(toolbarAction)) {
            return (
              <DropdownButton
                key={type}
                size={ButtonSize.Small}
                style={ButtonStyle.Action}
                options={toolbarAction}
              >
                <Icon type={type as IconType} />
                {t(`general.actions.${type}`)}
              </DropdownButton>
            );
          }

          return (
            <ToolbarButton
              key={type}
              action={typeof toolbarAction === 'object' ? toolbarAction.action : toolbarAction}
              disabled={typeof toolbarAction === 'object' ? toolbarAction.disabled : false}
              icon={(typeof toolbarAction === 'object' && toolbarAction.icon) || (type as IconType)}
            >
              {(typeof toolbarAction === 'object' && toolbarAction.label) || t(`general.actions.${type}`)}
            </ToolbarButton>
          );
        })}
    </div>
  );
}

export default Toolbar;
