import { IContribution } from '@common/models/contribution';
import { accountantRound } from '@common/utils/currency';

export interface IContributionsByServiceCenter {
  [key: string]: IServiceCenterContributions;
}

export interface IServiceCenterContributions {
  serviceCenterId: string;
  serviceCenterLabel: string;
  contributions: IContribution[];

  regularContributionTotal: number;
  socialContributionTotal: number;
  specialContributionTotal: number;
}

export interface IMemberContributionsState {
  contributionsByServiceCenter?: IContributionsByServiceCenter;
  selectedServiceCenterIds: { [key: string]: boolean };
  selectedBudgetYear?: string;
}

const defaultState: IMemberContributionsState = {
  contributionsByServiceCenter: null,
  selectedServiceCenterIds: {},
};

const reducer = (state: IMemberContributionsState = defaultState, action) => {
  switch (action.type) {
    case 'CONTRIBUTION_RECEIVED':
      return {
        ...state,
        selectedServiceCenterIds: {},
        contributionsByServiceCenter: contributionsToContributionsByServiceCenter(action.payload),
      };

    case 'CONTRIBUTION_UPDATED':
      return {
        ...state,
        contributionsByServiceCenter: contributionsToContributionsByServiceCenter(action.payload),
      };

    case 'TOGGLE_SERVICE-CENTER_DETAILS':
      return {
        ...state,
        selectedServiceCenterIds: {
          ...state.selectedServiceCenterIds,
          [action.payload]: !state.selectedServiceCenterIds[action.payload],
        },
      };

    case 'SELECT_BUDGET_YEAR':
      return {
        ...state,
        selectedBudgetYear: action.payload,
      };

    default:
      return state;
  }
};

const contributionsToContributionsByServiceCenter = (contributions: IContribution[]) => {
  if (!contributions || contributions.length < 1) return null;

  return contributions.reduce(function (acc: IContributionsByServiceCenter, contribution: IContribution) {
    if (!contribution.serviceCenter) return acc;
    const serviceCenterContributions = acc[contribution.serviceCenter.id];

    if (serviceCenterContributions) {
      acc[contribution.serviceCenter.id] = {
        ...serviceCenterContributions,
        contributions: [...serviceCenterContributions.contributions, contribution].sort((a, b) =>
          a.periodNumber > b.periodNumber ? -1 : 1,
        ),
        regularContributionTotal: accountantRound(
          serviceCenterContributions.regularContributionTotal + contribution.regularContribution,
        ),
        socialContributionTotal: accountantRound(
          serviceCenterContributions.socialContributionTotal + contribution.socialContribution,
        ),
        specialContributionTotal: accountantRound(
          serviceCenterContributions.specialContributionTotal + contribution.specialContribution,
        ),
      };
    } else {
      acc[contribution.serviceCenter.id] = {
        serviceCenterId: contribution.serviceCenter.id,
        serviceCenterLabel: `${contribution.serviceCenter.number} : ${contribution.serviceCenter.name}`,
        contributions: [contribution],
        regularContributionTotal: contribution.regularContribution || 0,
        socialContributionTotal: contribution.socialContribution || 0,
        specialContributionTotal: contribution.specialContribution || 0,
      };
    }

    return acc;
  }, {});
};

export const contributionsReceived = (contributions: IContribution[]) => ({
  type: 'CONTRIBUTION_RECEIVED',
  payload: contributions,
});

export const contributionsUpdated = (contributions: IContribution[]) => ({
  type: 'CONTRIBUTION_UPDATED',
  payload: contributions,
});

export const toggleServiceCenterDetails = (serviceCenterId: string) => ({
  type: 'TOGGLE_SERVICE-CENTER_DETAILS',
  payload: serviceCenterId,
});

export const selectBudgetYear = (budgetYear: string) => ({
  type: 'SELECT_BUDGET_YEAR',
  payload: budgetYear,
});

export default reducer;
