import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  Form,
  FormActionButton,
  FormActionButtonType,
  FormCheckboxField,
  FormContext,
  FormDataContext,
  FormDateField,
  FormEmailField,
  FormFields,
  FormMultiSelectField,
  FormPhoneField,
  FormPostalCodeField,
  FormRadioField,
  FormSelectField,
  FormSelectFieldOptions,
  FormTextField,
  IFormErrors,
} from '@novo-electronique/react-forms';
import * as Yup from 'yup';

import { Authorization } from '@common/constants/member';
import { getMemberRegistrationValidationSchema } from '@common/models/member';
import { IServiceCenter } from '@common/models/service-center';
import { subtractYears } from '@common/utils/date';
import { selectServiceCenters } from '@modules/app/redux/selectors';
import {
  genderOptions,
  jobTypeOptions,
  levelOfSchoolingOptions,
  provinceOptions,
  roleOptions,
  schoolingOptions,
} from '@modules/members/services/members';
import { toOptions } from '@modules/members/services/service-centers';
import SchoolMultiSelectField from '@modules/shared/components/Form/SchoolMultiSelectField';
import FormPrint from '@modules/shared/components/FormPrint';

interface IProps {
  initialValues: any;
  onSubmit: (values: any, setErrors?: (errors: IFormErrors) => void) => Promise<void>;
  submitLabelKey: string;
  isUpdate?: boolean;
  readonly?: boolean;
}

function MembershipForm({ initialValues, onSubmit, submitLabelKey, isUpdate, readonly }: IProps) {
  const { t } = useTranslation();
  const serviceCenters = useSelector(selectServiceCenters);

  const validateInitialValues = (values) => {
    if (values && values.professionalEmail && values.serviceCenter) {
      const [, domain] = values.professionalEmail.split('@');

      if (!domain || domain !== getServiceCenterFromId(values.serviceCenter.id)?.domain) {
        return { ...values, professionalEmail: '' };
      }
    }

    return values;
  };

  const getServiceCenterFromId = (id: string): IServiceCenter => {
    return serviceCenters.find((serviceCenter) => id === serviceCenter.id);
  };

  const onServiceCenterChange = (
    _serviceCenterId: string,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  ) => {
    setFieldValue('professionalEmail', '', false);
  };

  return (
    <FormPrint>
      <FormContext
        onSubmit={onSubmit}
        initialValues={validateInitialValues(initialValues)}
        validationSchema={getMemberRegistrationValidationSchema(Yup)}
      >
        <FormDataContext.Consumer>
          {({ values }) => {
            const serviceCenter = getServiceCenterFromId(values.serviceCenter.id);
            const association = serviceCenter?.association || null;

            return (
              <Form readonly={readonly}>
                <FormFields title={t('membershipPage.personalInfo')}>
                  <FormTextField name="firstName" label={t('models.member.firstName')} />
                  <FormTextField name="lastName" label={t('models.member.lastName')} />
                  <FormTextField name="personalEmail" label={t('models.member.personalEmail')} />
                  <FormDateField
                    name="dob"
                    label={t('models.member.dob')}
                    width={25}
                    minDate={new Date(1900, 0)}
                    maxDate={subtractYears(new Date(), 18)} // Must have at least 18 years old
                  />
                  <FormSelectField name="gender" label={t('models.member.gender')} width={25}>
                    <FormSelectFieldOptions options={genderOptions()} keepOriginalOrder />
                  </FormSelectField>
                  <FormPhoneField name="personalPhoneNumber" label={t('models.member.personalPhoneNumber')} />
                  <FormSelectField name="schooling" label={t('models.member.schooling')}>
                    <FormSelectFieldOptions options={schoolingOptions()} keepOriginalOrder />
                  </FormSelectField>
                  <FormFields title={t('membershipPage.address')}>
                    <FormTextField
                      name="addrCivicNumber"
                      label={t('models.member.addrCivicNumber')}
                      width={12.5}
                    />
                    <FormTextField name="addrStreet" label={t('models.member.addrStreet')} width={25} />
                    <FormTextField name="addrApp" label={t('models.member.addrAppShort')} width={12.5} />
                    <FormTextField name="addrCity" label={t('models.member.addrCity')} width={25} />
                    <FormSelectField name="addrProvince" label={t('models.member.addrProvince')} width={12.5}>
                      <FormSelectFieldOptions options={provinceOptions()} />
                    </FormSelectField>
                    <FormPostalCodeField
                      name="addrPostalCode"
                      label={t('models.member.addrPostalCode')}
                      width={12.5}
                    />
                  </FormFields>
                </FormFields>

                <FormFields title={t('membershipPage.professionalInfo')}>
                  <FormDateField
                    name="startDate"
                    label={t('models.member.startDate')}
                    width={33}
                    minDate={new Date(1996, 0, 1)}
                    maxDate={new Date()}
                  />
                  <FormSelectField name="role" label={t('models.member.role')} width={33}>
                    <FormSelectFieldOptions options={roleOptions()} />
                  </FormSelectField>

                  <FormSelectField
                    name="jobClassification"
                    label={t('models.member.jobClassification')}
                    width={33}
                  >
                    <option value="" />
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                  </FormSelectField>

                  <FormMultiSelectField
                    options={levelOfSchoolingOptions()}
                    name="levelsOfSchooling"
                    label={t('models.member.levelsOfSchooling')}
                    width={100}
                  />

                  <SchoolMultiSelectField name="schools" label={t('models.member.schools')} width={100} />

                  <FormSelectField
                    name="serviceCenter.id"
                    label={t('models.member.serviceCenter')}
                    width={75}
                    onChange={onServiceCenterChange}
                  >
                    <FormSelectFieldOptions options={toOptions(serviceCenters)} keepOriginalOrder />
                  </FormSelectField>

                  <FormSelectField name="jobType" label={t('models.member.jobType')} width={25}>
                    <FormSelectFieldOptions options={jobTypeOptions()} />
                  </FormSelectField>

                  <FormPhoneField
                    name="professionalPhoneNumber"
                    label={t('models.member.professionalPhoneNumber')}
                    width={37.5}
                  />
                  <FormTextField
                    name="professionalPhoneNumberExt"
                    label={t('models.member.professionalPhoneNumberExt')}
                    width={12.5}
                  />
                  {(!serviceCenter || serviceCenter?.domain) && (
                    <FormEmailField
                      name="professionalEmail"
                      domain={serviceCenter?.domain}
                      label={t('models.member.professionalEmail')}
                    />
                  )}
                  {serviceCenter && !serviceCenter.domain && (
                    <FormTextField name="professionalEmail" label={t('models.member.professionalEmail')} />
                  )}
                  <FormPhoneField
                    name="cellPhoneNumber"
                    label={t('models.member.cellPhoneNumber')}
                    width={37.5}
                  />
                </FormFields>

                {!isUpdate && (
                  <FormFields
                    disabled={association === undefined}
                    title={t('membershipPage.associationMembership', {
                      context: association === undefined ? 'undefined' : association === null ? 'null' : '',
                      association: association?.name,
                    })}
                  >
                    <FormCheckboxField name="ackConsent" label={t('models.member.ackConsent')} width={100} />
                    <FormCheckboxField
                      name="ackAuthorizeRepresentationFqde"
                      label={t('models.member.ackAuthorizeRepresentationFqde')}
                      width={100}
                    />
                    <FormCheckboxField
                      name="ackAuthorizeContribution"
                      label={t('models.member.ackAuthorizeContribution')}
                      width={100}
                    />
                    <FormCheckboxField
                      name="ackAuthorizeServiceCenter"
                      label={t('models.member.ackAuthorizeServiceCenter')}
                      width={100}
                    />
                    <p className="f4eImportantMention">
                      N.B. La transmission du formulaire à partir de votre adresse courriel fait foi de votre
                      signature.
                    </p>
                  </FormFields>
                )}

                <FormFields title={t('membershipPage.personalInfoProtectionNotice')}>
                  <p>
                    Conformément à la Loi sur la protection des renseignements personnels dans le secteur
                    privé, la FQDE et l’Association vous informent que les renseignements personnels qu’elles
                    vous demandent de leur communiquer de même que ceux qu’elles consigneront à votre dossier
                    par la suite sont confidentiels et seront traités comme tels, dans le respect des
                    dispositions légales applicables.
                  </p>

                  <p>
                    Sachez que ces renseignements sont recueillis parce qu’ils sont nécessaires à la
                    réalisation de notre mandat, à savoir la protection des membres et l’avancement de la
                    profession. L’usage et la divulgation de ces renseignements ne peut s’effectuer que dans
                    ce cadre ou autrement si requis par la loi. Seulement le personnel de la FQDE et celui de
                    votre association ayant besoin de ces renseignements pourront y avoir accès. Ils seront
                    détenus à la Fédération et/ou à votre association de manière sécuritaire et vous pourrez
                    vous prévaloir du droit d’accès à votre dossier selon les modalités définies par votre
                    association ou votre fédération. Avant d’exercer ce droit en vertu des dispositions de la
                    Loi, nous vous conseillons de vous adresser, par écrit, au président de la FQDE ou de
                    votre association.
                  </p>

                  <h2>ACCÈS À DES TIERS</h2>
                  <p>
                    La FQDE a une entente de partenariat avec la Compagnie d’assurance La Personnelle qui
                    offre des programmes avantageux aux membres. Vous avez le droit de refuser cette
                    communication en cochant ci-bas la case appropriée. En autorisant cette communication,
                    vous acceptez que la Personnelle vous contacte afin de vous proposer des offres exclusives
                    dans le cadre des programmes convenus avec la FQDE.
                  </p>

                  <div className="f4eMembershipAuthorizePersonalInfo">
                    <FormRadioField
                      name="ackAuthorizePersonalInfo"
                      value={Authorization.Accept}
                      label={t('member.thirdParty_accept', { context: Authorization.Accept })}
                      width={100}
                    />
                    <div className="f4eMembershipAuthorizePersonalInfo__or-line" />
                    <FormRadioField
                      name="ackAuthorizePersonalInfo"
                      value={Authorization.Refuse}
                      label={t('member.thirdParty', { context: Authorization.Refuse })}
                      width={100}
                    />
                  </div>

                  <p>
                    Une fois par année, vous recevrez un courriel vous demandant de mettre à jour vos
                    informations. Vous pouvez retirer vos autorisations à ce moment-là. Sinon, en tout temps
                    vous pouvez le faire en envoyant un courriel à la FQDE à{' '}
                    <a href="mailto:adhesions@fqde.qc.ca">adhesions@fqde.qc.ca</a>.
                  </p>
                </FormFields>

                {!readonly && (
                  <FormActionButton
                    type={FormActionButtonType.Submit}
                    disabled={isUpdate ? false : undefined}
                  >
                    {t(submitLabelKey)}
                  </FormActionButton>
                )}
              </Form>
            );
          }}
        </FormDataContext.Consumer>
      </FormContext>
    </FormPrint>
  );
}

export default MembershipForm;
