import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ServerSideStoreType } from '@ag-grid-community/core';
import { DataTable, useDataTable } from '@novo-electronique/react-data-table';

import { AccountingMonth } from '@common/constants/accounting-month';
import { AccessLevel, PermissionScope } from '@common/constants/permission';
import { IContributionsDue } from '@common/models/contributions-due';
import { getAccountingMonthFromMonthIndex } from '@common/utils/accounting-month';
import { getLatestBudgetYear } from '@common/utils/period';
import { selectUser } from '@modules/app/redux/selectors';
import Layout, { LayoutContent, LayoutHeader } from '@modules/shared/components/Layout/';
import PeriodSelector from '@modules/shared/components/PeriodSelector';
import Toolbar, { ToolbarActionType } from '@modules/shared/components/Toolbar';
import { hasPermission } from '@modules/shared/permission';
import { useQuery } from '@modules/shared/useQuery';
import { AppRoutePages } from '@src/constants';
import { generateColumnDefinition } from '@src/modules/data-table/column-definition-factory';
import { DataTableSource } from '@src/modules/data-table/data-table-source';
import PaymentEditionForm from './components/PaymentEditionForm';
import { deletePayment, savePayment } from './service';

function PaymentsPage() {
  const { t } = useTranslation();
  const currentUser = useSelector(selectUser);
  const query = useQuery();
  const history = useHistory();

  const contributionsDueId = query.get('id');

  const onTableSelectionChanged = ({ id }: IContributionsDue) => {
    history.push(`${AppRoutePages.Payments}?id=${id}`);
  };

  const { isReady, gridApi, gridProperties, refresh, exportToCsv, exportToXlsx } = useDataTable(
    DataTableSource.ContributionsDue,
    generateColumnDefinition(DataTableSource.ContributionsDue),
    onTableSelectionChanged,
    {
      options: {
        showSidebar: false,
        showFloatingFilter: false,
        enableGrouping: false,
        getRowNodeId: (data) => data.id || data.serviceCenterNumber || data.associationNumber,
        groupColumnDef: {
          headerName: t('reports.cumulativeConciliation.association'),
          sortable: false,
          flex: 2.3,
        },
        serverSideStoreType: ServerSideStoreType.Full,
      },
    },
  );

  useEffect(() => {
    if (isReady) {
      onPeriodChange(getLatestBudgetYear(), getAccountingMonthFromMonthIndex(new Date().getMonth()));
    }
  }, [isReady]);

  useEffect(() => {
    if (contributionsDueId) {
      gridApi?.getRowNode(contributionsDueId)?.setSelected(true);
    }
  }, [contributionsDueId]);

  const onPeriodChange = (budgetYear: string, accountingMonth: AccountingMonth) => {
    gridApi.getFilterInstance('budgetYear').setModel({
      type: 'equals',
      filter: budgetYear,
    });
    gridApi.getFilterInstance('accountingMonth').setModel({
      type: 'lessThanOrEqual',
      filter: accountingMonth,
    });

    gridApi.onFilterChanged();
  };

  const refreshSelectedNode = () => {
    gridApi.getSelectedNodes().forEach((node) => {
      let parent = node.parent;
      while (parent) {
        refresh({ route: parent.getRoute() });
        parent = parent.parent;
      }
    });
  };

  const onSubmit = (values: IContributionsDue) => {
    return savePayment(values.id, values.payment).then(() => {
      history.push(AppRoutePages.Payments);
      refreshSelectedNode();
    });
  };

  const onCancel = () => {
    history.push(AppRoutePages.Payments);
  };

  const onDelete = (contribution: IContributionsDue) => {
    deletePayment(contribution.id).then(() => {
      history.push(AppRoutePages.Payments);
      refreshSelectedNode();
    });
  };

  const hasFullAccess = hasPermission(PermissionScope.Import, AccessLevel.Full, currentUser);
  return (
    <Layout>
      <LayoutHeader title={t('paymentsPage.title')}>
        <PeriodSelector onChange={onPeriodChange} showAccountingMonth small />
        <Toolbar
          actions={{
            [ToolbarActionType.Export]: [
              { action: exportToCsv, label: 'CSV' },
              { action: exportToXlsx, label: 'XLSX' },
            ],
            [ToolbarActionType.Refresh]: () => refresh({ purge: true }),
          }}
        />
      </LayoutHeader>

      <LayoutContent>
        <DataTable {...gridProperties} />

        {contributionsDueId && (
          <PaymentEditionForm
            contributionsDueId={contributionsDueId}
            readonly={!hasFullAccess}
            submitHandler={onSubmit}
            cancelHandler={onCancel}
            deleteHandler={onDelete}
            refreshHandler={refreshSelectedNode}
          />
        )}
      </LayoutContent>
    </Layout>
  );
}

export default PaymentsPage;
