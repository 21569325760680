import './theme/main.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { FormProvider } from '@novo-electronique/react-forms';

import { Mask } from '@common/constants/mask';
import App from '@modules/app/App';
import bootstrap from './bootstrap';
import reportWebVitals from './report-web-vitals';

const { store, i18n } = bootstrap();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <FormProvider
          isShowingRequiredSymbol={true}
          labelsNamespace="general.forms"
          phoneMask={Mask.PhoneNumber}
          postalCodeMask={Mask.PostalCode}
        >
          <App />
        </FormProvider>
      </I18nextProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('f4e'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
