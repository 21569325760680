import { AccountingMonth } from '../constants/accounting-month';

const firstMonthIndex = 6;
const monthsCount = 12;

export function getAccountingMonthFromMonthIndex(monthIndex: number): AccountingMonth {
  return (monthIndex % firstMonthIndex) + (monthIndex < firstMonthIndex ? monthsCount - firstMonthIndex : 0);
}

export function getMonthIndexFromAccountingMonth(accountingMonth: AccountingMonth): number {
  const diff = monthsCount - firstMonthIndex;
  return Math.floor(accountingMonth / diff) === 0 ? accountingMonth + diff : accountingMonth - diff;
}
