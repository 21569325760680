import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Form,
  FormActionButton,
  FormActionButtonType,
  FormContext,
  FormFields,
  FormMultiSelectField,
  FormPhoneField,
  FormPostalCodeField,
  FormSelectField,
  FormSelectFieldOptions,
  FormTextField,
  IFormErrors,
} from '@novo-electronique/react-forms';
import {
  SidePanelContent,
  SidePanelFooter,
  SidePanelFooterActions,
  SidePanelHeader,
  SidePanelHeaderNav,
} from '@novo-electronique/react-side-panel';
import { Tab, TabContext, TabList, TabPanel } from '@novo-electronique/react-tabs';
import * as Yup from 'yup';

import { getSchoolInitialValues, getSchoolValidationSchema, ISchool } from '@common/models/school';
import { setAppIsLoading } from '@modules/app/redux/action';
import { selectServiceCenters, selectServiceCenterValueProvider } from '@modules/app/redux/selectors';
import { DataTableSource } from '@modules/data-table/data-table-source';
import History from '@modules/history/History';
import { getSchool } from '@modules/members/services/schools';
import { toOptions } from '@modules/members/services/service-centers';
import SidePanel from '@modules/shared/components/SidePanel';
import { levelOfSchoolingOptions } from '../services/members';

enum HeaderTabs {
  School = 'school',
  History = 'history',
}

interface IProps {
  schoolId: string;
  readonly: boolean;
  submitHandler: (values: ISchool, setErrors?: (errors: IFormErrors) => void) => Promise<void>;
  cancelHandler: () => void;
}

function SchoolEditionForm({ schoolId, readonly, submitHandler, cancelHandler }: IProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const serviceCenters = useSelector(selectServiceCenters);
  const serviceCenterValueProvider = useSelector(selectServiceCenterValueProvider);

  const [initialValues, setInitialValues] = useState<ISchool>(null);
  const [subTitle, setSubTitle] = useState<string>('');
  const [selectedTab, setSelectedTab] = useState<HeaderTabs>();

  useEffect(() => {
    if (schoolId) {
      dispatch(setAppIsLoading(true));
      getSchool(schoolId)
        .then((school: ISchool) => {
          setSubTitle(serviceCenterValueProvider.label(school.serviceCenter.id));
          setInitialValues(school);
        })
        .finally(() => dispatch(setAppIsLoading(false)));
    } else {
      setInitialValues(getSchoolInitialValues());
    }
  }, [schoolId]);

  if (!initialValues) {
    return null;
  }
  return (
    <FormContext
      initialValues={initialValues}
      validationSchema={getSchoolValidationSchema(Yup)}
      onSubmit={submitHandler}
    >
      <SidePanel closeHandler={cancelHandler}>
        <TabContext onChange={(value) => setSelectedTab(value as HeaderTabs)}>
          <SidePanelHeader displayCloseButton={true} subTitle={subTitle}>
            {schoolId ? initialValues.name : t('schoolsPage.addSchool')}
          </SidePanelHeader>
          {schoolId && (
            <SidePanelHeaderNav isHost>
              <TabList>
                <Tab value={HeaderTabs.School} active>
                  {t('schoolsPage.tabs.school')}
                </Tab>
                <Tab value={HeaderTabs.History}>{t('serviceCenterPage.tabs.history')}</Tab>
              </TabList>
            </SidePanelHeaderNav>
          )}

          <Form readonly={readonly}>
            <SidePanelContent>
              <TabPanel value={HeaderTabs.School} visible={!schoolId}>
                <FormFields>
                  <FormTextField name="number" label={t('models.school.number')} width={25} autoFocus />
                  <FormTextField name="name" label={t('models.school.name')} width={75} />

                  <FormSelectField
                    name="serviceCenter.id"
                    label={t('models.member.serviceCenter')}
                    width={100}
                  >
                    <FormSelectFieldOptions options={toOptions(serviceCenters)} keepOriginalOrder />
                  </FormSelectField>

                  <FormMultiSelectField
                    name="levelsOfSchooling"
                    label={t('models.school.levelsOfSchooling')}
                    options={levelOfSchoolingOptions()}
                    width={100}
                  />

                  <FormTextField name="address" label={t('models.school.address')} width={37.5} />
                  <FormTextField name="city" label={t('models.school.city')} width={37.5} />
                  <FormPostalCodeField name="postalCode" label={t('models.school.postalCode')} width={25} />

                  <FormTextField name="email" label={t('models.school.email')} width={50} />
                  <FormTextField name="website" label={t('models.school.website')} width={50} />

                  <FormPhoneField name="phoneNumber" label={t('models.school.phoneNumber')} width={37.5} />
                  <FormTextField name="phoneNumberExt" label={t('models.school.phoneNumberExt')} width={25} />
                  <FormPhoneField name="faxNumber" label={t('models.school.faxNumber')} width={37.5} />
                </FormFields>
              </TabPanel>

              {schoolId && (
                <TabPanel value={HeaderTabs.History}>
                  <History
                    id={initialValues.history?.id}
                    datasource={DataTableSource.Schools}
                    hidden={selectedTab !== HeaderTabs.History}
                  />
                </TabPanel>
              )}
            </SidePanelContent>

            {!readonly && selectedTab !== HeaderTabs.History && (
              <SidePanelFooter>
                <SidePanelFooterActions>
                  <FormActionButton type={FormActionButtonType.Submit} small={true}>
                    {t('general.forms.save')}
                  </FormActionButton>
                </SidePanelFooterActions>
                <SidePanelFooterActions isSecondary={true}>
                  <FormActionButton type={FormActionButtonType.Reset} small={true} />
                </SidePanelFooterActions>
              </SidePanelFooter>
            )}
          </Form>
        </TabContext>
      </SidePanel>
    </FormContext>
  );
}

export default SchoolEditionForm;
