import errorCodes from '@common/constants/error-codes';

export default class Exception {
  constructor(public code: string, public message: string = '', public payload?: any, public stack?: string) {
    this.stack = this.stack || Exception.stack();
  }

  static new(err: Error | Exception) {
    if (err instanceof Error) {
      return this.fromError(err);
    }
    return { ...err };
  }

  static fromError(err: Error) {
    const code = err.name !== 'Error' ? err.name : errorCodes.UNEXPECTED_ERROR_OCCURRED;
    const exception = new Exception(code, err.message, null);
    exception.stack = err.stack;
    return exception;
  }

  private static stack() {
    let message = null;
    new Error().stack
      .split('\n')
      .splice(1)
      .forEach((line) => {
        const match = /at new ([^(]+).*exceptions.js/g.exec(line);
        if (match) {
          message = match[1] + '\n';
        } else if (message && line.indexOf('node_module') === -1) {
          message += line;
        }
      });
    return message;
  }
}

export class NotSupportedException extends Exception {
  constructor(reason: string) {
    super(errorCodes.NOT_SUPPORTED_EXCEPTION, reason);
  }
}

export class InvalidOperationException extends Exception {
  constructor(reason: string) {
    super(errorCodes.INVALID_OPERATION, reason);
  }
}

export class InvalidDataException extends Exception {
  constructor(reason: string) {
    super(errorCodes.INVALID_DATA, reason);
  }
}

export class ArgumentException extends Exception {
  constructor(name: string, reason: string) {
    super(errorCodes.INVALID_ARGUMENT, `Invalid argument '${name}': ${reason}'`);
  }
}

export class ArgumentRequiredException extends ArgumentException {
  constructor(name: string) {
    super(name, 'required');
  }
}

export class ArgumentOutOfRangeException extends ArgumentException {
  constructor(name: string, value: any) {
    super(name, `value '${value}' is out of range`);
  }
}

export class NotFoundException extends Exception {
  constructor(message?: string) {
    super(errorCodes.NOT_FOUND, message || '');
  }
}

export class ValidationException extends Exception {
  constructor(errors: any[]) {
    super(errorCodes.NOT_FOUND, 'Data validation failed', errors);
  }
}

export class PersistencyException extends Exception {
  constructor(message?: string) {
    super(errorCodes.PERSISTENCY_ERROR, message);
  }
}

export class UnexpectedErrorOccurredException extends Exception {
  constructor(message?: string) {
    super(errorCodes.UNEXPECTED_ERROR_OCCURRED, message);
  }
}

export class UnauthorizedException extends Exception {
  constructor(message?: string) {
    super(errorCodes.UNAUTHORIZED, message);
  }
}
