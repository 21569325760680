import React, { useState } from 'react';
import { Button, ButtonSize, ButtonStyle } from '@novo-electronique/react-button';

import Icon from '@modules/shared/components/Icon';
import { IToolbarAction } from '@modules/shared/components/Toolbar/models';

type Props = { children: string } & Pick<IToolbarAction, 'icon'> &
  Partial<Omit<IToolbarAction, 'label' | 'icon'>>;

function ToolbarButton({ children, action, icon, disabled }: Props) {
  const [isLoading, setIsLoading] = useState(false);

  const performAction = async () => {
    try {
      setIsLoading(true);
      await action?.();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button
      size={ButtonSize.Small}
      style={ButtonStyle.Action}
      onClick={performAction}
      disabled={disabled}
      isLoading={isLoading}
    >
      <Icon type={icon} />
      {children}
    </Button>
  );
}

export default ToolbarButton;
