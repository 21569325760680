import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  FieldEvent,
  Form,
  FormActionButton,
  FormActionButtonType,
  FormAmountField,
  FormContext,
  FormDateField,
  FormFields,
  FormSelectField,
  FormSelectFieldOptions,
  IOption,
} from '@novo-electronique/react-forms';
import * as Yup from 'yup';

import { financialYearFirstMonth, initialYear, periodNumbersCount } from '@common/constants/global';
import { getContributionValidationSchema, IContribution } from '@common/models/contribution';
import { IMember } from '@common/models/member';
import { range } from '@common/utils/array';
import { getLatestPeriod, getPeriodNumberFromDate } from '@common/utils/period';
import { selectServiceCenters } from '@modules/app/redux/selectors';
import { saveMemberContribution } from '@modules/members/services/members';
import { toOptions } from '@modules/members/services/service-centers';
import { FormModal, ModalSize } from '@modules/shared/components/Modal';

interface IProps {
  onClose: () => void;
  member: IMember;
  contribution?: IContribution;
}

function MemberContributionEditionModal({ member, contribution, onClose }: IProps) {
  const { t } = useTranslation();
  const serviceCenters = useSelector(selectServiceCenters);
  const formikRef = useRef(null);

  const periodOptions = range(periodNumbersCount).map((d) => {
    const value = (d + 1).toString();
    return {
      value,
      label: value,
    };
  }) as IOption[];

  const initialValues = contribution
    ? contribution
    : ({
        periodNumber: '' as any,
        date: null,
        serviceCenter: member.serviceCenter,
        regularContribution: 0,
        specialContribution: 0,
        socialContribution: 0,
      } as IContribution);

  const updatePeriodNumber: FieldEvent<Date> = (value, setFieldValue) => {
    if (value && !contribution && !formikRef.current.touched['periodNumber']) {
      setFieldValue('periodNumber', getPeriodNumberFromDate(value), false);
    }
  };

  const onSubmit = async (values) => {
    await saveMemberContribution({ ...values, member });
    onClose();
  };

  const maxYear = getLatestPeriod().end;
  return (
    <FormModal
      title={t(`memberContributions.${contribution ? 'edit' : 'add'}`)}
      size={ModalSize.Small}
      onClose={onClose}
      formikRef={formikRef}
    >
      <FormContext
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={getContributionValidationSchema(Yup)}
        formikRef={formikRef}
      >
        <Form>
          <FormFields>
            <FormSelectField
              name="serviceCenter.id"
              label={t('memberContributions.headers.serviceCenter')}
              width={50}
            >
              <FormSelectFieldOptions options={toOptions(serviceCenters)} keepOriginalOrder />
            </FormSelectField>
            <FormDateField
              name="date"
              label={t('memberContributions.headers.date')}
              width={25}
              minDate={new Date(initialYear, financialYearFirstMonth)}
              maxDate={new Date(maxYear, financialYearFirstMonth, 0, 23, 59, 59, 999)}
              onChange={updatePeriodNumber}
            />
            <FormSelectField name="periodNumber" label={t('memberContributions.headers.period')} width={25}>
              <FormSelectFieldOptions options={periodOptions} keepOriginalOrder />
            </FormSelectField>
            <FormAmountField
              name="regularContribution"
              label={t('memberContributions.headers.regular')}
              width={33}
            />
            <FormAmountField
              name="socialContribution"
              label={t('memberContributions.headers.social')}
              width={33}
            />
            <FormAmountField
              name="specialContribution"
              label={t('memberContributions.headers.special')}
              width={33}
            />
          </FormFields>
          <FormActionButton type={FormActionButtonType.Submit} small>
            {t('general.forms.save')}
          </FormActionButton>
        </Form>
      </FormContext>
    </FormModal>
  );
}

export default MemberContributionEditionModal;
