import { IAsyncOption } from '@novo-electronique/react-forms';

import { ISchool } from '@common/models/school';
import apiClient from '@modules/shared/api-client';
import { handleServiceException } from '@modules/shared/service-error-handler';

export function findSchools(name: string): Promise<ISchool[]> {
  return apiClient
    .get<ISchool[]>(`/school/find?name=${encodeURIComponent(name)}`)
    .catch(handleServiceException);
}

export function saveSchool(school: ISchool): Promise<ISchool> {
  return apiClient.post<ISchool>('/school', school).catch(handleServiceException);
}

export function getSchool(id: string): Promise<ISchool> {
  return apiClient.get<ISchool>(`/school/${id}`).catch(handleServiceException);
}

export const loadSchoolOptions = (name: string) => {
  return findSchools(name).then((schools: ISchool[]) => schools.map(toSchoolAsyncOption));
};

export const toSchoolAsyncOption = (school: ISchool): IAsyncOption => ({
  id: school.id,
  value: school.id,
  label: `${school.name} (${school.city})`,
});
