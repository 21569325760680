import { getStatusName } from '@common/utils/http-status';

export default {
  UNEXPECTED_ERROR_OCCURRED: 'UNEXPECTED_ERROR_OCCURRED',
  INVALID_ARGUMENT: 'INVALID_ARGUMENT',
  INVALID_DATA: 'INVALID_DATA',
  INVALID_OPERATION: 'INVALID_OPERATION',
  VALIDATION_ERROR: 'VALIDATION_ERROR',
  PERSISTENCY_ERROR: 'PERSISTENCY_ERROR',
  ATLASSIAN_CLIENT_ERROR: 'ATLASSIAN_CLIENT_ERROR',
  JIRA_SYNC_ERROR: 'JIRA_SYNC_ERROR',
  NOT_SUPPORTED_EXCEPTION: 'NOT_SUPPORTED_EXCEPTION',

  BAD_REQUEST: getStatusName(400),
  UNAUTHORIZED: getStatusName(401),
  FORBIDDEN: getStatusName(403),
  NOT_FOUND: getStatusName(404),
  METHOD_NOT_ALLOWED: getStatusName(405),
  REQUEST_TIMEOUT: getStatusName(408),
  CONFLICT: getStatusName(409),
  IM_A_TEAPOT: getStatusName(418),
  UNPROCESSABLE_ENTITY: getStatusName(422),
  LOCKED: getStatusName(423),
  INTERNAL_SERVER_ERROR: getStatusName(500),
  NOT_IMPLEMENTED: getStatusName(501),
  SERVICE_UNAVAILABLE: getStatusName(503),
};
