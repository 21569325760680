import { ColDef } from '@ag-grid-community/core';

import {
  associationsColumnDefinition,
  contributionsDueColumnDefinition,
  gricsImportsChangesColumnDefinition,
  gricsImportsColumnDefinition,
  membersColumnDefinition,
  membershipsColumnDefinition,
  schoolsColumnDefinition,
  serviceCentersColumnDefinition,
  usersColumnDefinition,
} from './column-definitions';
import { DataTableSource } from './data-table-source';

export const generateColumnDefinition = (datasource: DataTableSource): ColDef[] => {
  switch (datasource) {
    case DataTableSource.Members:
      return membersColumnDefinition();
    case DataTableSource.Associations:
      return associationsColumnDefinition();
    case DataTableSource.ServiceCenters:
      return serviceCentersColumnDefinition();
    case DataTableSource.Memberships:
      return membershipsColumnDefinition();
    case DataTableSource.GricsImports:
      return gricsImportsColumnDefinition();
    case DataTableSource.ChangesRequests:
      return gricsImportsChangesColumnDefinition();
    case DataTableSource.Users:
      return usersColumnDefinition();
    case DataTableSource.ContributionsDue:
      return contributionsDueColumnDefinition();
    case DataTableSource.Schools:
      return schoolsColumnDefinition();

    default:
      console.error('generateColumnDefinition', 'Unhandled datasource.');
  }
};
