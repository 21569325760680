import { AccountingMonth } from '@common/constants/accounting-month';
import { gricsFileNameRegex } from '@common/constants/global';
import { getAccountingMonthFromMonthIndex } from '@common/utils/accounting-month';

export function getAccountingMonthFromFileName(fileName: string): AccountingMonth {
  if (!fileName.match(gricsFileNameRegex)) {
    return;
  }
  const [, , , month] = fileName.split('-');
  return getAccountingMonthFromMonthIndex(+month - 1);
}
