import {
  addDays as dateFnsAddDays,
  differenceInDays as dateFnsDifferenceInDays,
  differenceInWeeks as dateFnsDifferenceInWeeks,
  endOfMonth as dateFnsEndOfMonth,
  format,
  getWeek as dateFnsGetWeek,
  isDate as dateFnsIsDate,
  isValid as dateFnsIsValid,
  max as dateFnsMax,
  parse as dateFnsParse,
  parseISO,
  subMonths,
  subYears,
} from 'date-fns';
import { enCA as en, fr } from 'date-fns/locale';
import { zonedTimeToUtc as dateFnsZonedTimeToUtc } from 'date-fns-tz';
import i18n from 'i18next';

import { Language } from '../constants/language';

export function getDate(): Date {
  return new Date();
}

export function parse(date: string, format: string): Date {
  return dateFnsParse(date, format, new Date());
}

export function formatDate(
  date: string | Date,
  pattern: string = 'yyyy-MM-dd',
  language = i18n.language as Language,
): string {
  if (!date) return '';

  const parsedDate = date instanceof Date ? date : parseISO(date);

  if (isValid(parsedDate)) {
    return customFormat(parsedDate, pattern, language);
  }

  return date instanceof Date ? date.toString() : date;
}

export function parseIsoDateString(date: string): Date {
  return parseISO(date);
}

export function isDate(date: any): boolean {
  return dateFnsIsDate(date);
}

export function isValid(date: Date): boolean {
  return dateFnsIsValid(date);
}

export function getWeek(date: Date): number {
  return dateFnsGetWeek(date);
}

export function differenceInDays(laterDate: Date, earlierDate: Date): number {
  return dateFnsDifferenceInDays(laterDate, earlierDate);
}

export function endOfMonth(date: Date): Date {
  return dateFnsEndOfMonth(date);
}

export function addDays(date: Date, daysToAdd: number): Date {
  return dateFnsAddDays(date, daysToAdd);
}

export function differenceInWeeks(laterDate: Date, earlierDate: Date): number {
  return dateFnsDifferenceInWeeks(laterDate, earlierDate);
}

export function subtractMonths(date: Date, monthsToSubtract: number): Date {
  return subMonths(date, monthsToSubtract);
}

export function subtractYears(date: Date, yearsToSubtract: number): Date {
  return subYears(date, yearsToSubtract);
}

export function monthName(monthIndex: number): string {
  const date = new Date();
  date.setDate(1);
  date.setMonth(monthIndex - 1);

  return format(new Date(date), 'LLLL', { locale: fr });
}

export function zonedTimeToUtc(date: Date, timeZone: string): Date {
  return dateFnsZonedTimeToUtc(date, timeZone);
}

export function max(...params: Date[]): Date {
  const dates = params
    .filter((date) => date)
    .map((date) => new Date(date))
    .filter((date) => isValid(date));

  if (dates.length <= 0) {
    return null;
  }
  return dateFnsMax(dates);
}

function customFormat(date: Date, pattern: string, language: Language): string {
  const dateFormatted = format(date, pattern, { locale: getLocale(language) });
  if (language !== Language.Fr) {
    return dateFormatted;
  }

  switch (pattern) {
    case 'PPP':
      const parts = dateFormatted.split(' ');
      if (parts[0] === '1' && language === Language.Fr) {
        parts[0] = '1er';
      }
      return parts.join(' ');
    default:
      return dateFormatted;
  }
}

function getLocale(language: Language) {
  switch (language) {
    case Language.Fr:
      return fr;
    default:
      return en;
  }
}
