import './BackgroundTaskFeedback.scss';

import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { CircularProgress } from '@novo-electronique/react-circular-progress';

import { removeBackgroundTask } from '@modules/app/redux/action';
import { selectBackgroundTasks } from '@modules/app/redux/selectors';
import { IBackgroundTask } from '@modules/shared/background-task';

export default function BackgroundTaskFeedback() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const activeBackgroundTasks = useSelector(selectBackgroundTasks);
  const backgroundTasksRef = useRef<IBackgroundTask[]>([]);

  const renderToastContent = (task: IBackgroundTask) => {
    return (
      <>
        <div className="f4eBackgroundTaskFeedback__content__feedback">
          <CircularProgress />
          {task.cancellable && (
            <button
              className="f4eBackgroundTaskFeedback__content__feedback__cancel material-icons"
              title={t('general.actions.stop')}
              onClick={() => dispatch(removeBackgroundTask(task.id))}
            >
              stop
            </button>
          )}
        </div>
        <p className="f4eBackgroundTaskFeedback__content__text">
          {t(`general.backgroundTask`, { context: task.type, ...task.params })}
        </p>
      </>
    );
  };

  useEffect(() => {
    activeBackgroundTasks.forEach((task) => {
      if (!toast.isActive(task.id)) {
        toast.dark(renderToastContent(task), {
          autoClose: false,
          closeOnClick: false,
          className: 'f4eBackgroundTaskFeedback',
          bodyClassName: 'f4eBackgroundTaskFeedback__content',
          toastId: task.id,
          position: 'bottom-left',
        });
      } else {
        toast.update(task.id, { render: renderToastContent(task) });
      }
    });

    backgroundTasksRef.current
      .filter((task) => !activeBackgroundTasks.some((activeTask) => activeTask.id === task.id))
      .forEach((task) => toast.dismiss(task.id));

    backgroundTasksRef.current = [...activeBackgroundTasks];
  }, [activeBackgroundTasks]);

  return null;
}
