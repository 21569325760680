import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  FormAmountField,
  FormDataContext,
  FormDateField,
  FormFields,
  FormMultiSelectField,
  FormPhoneField,
  FormPostalCodeField,
  FormSelectField,
  FormSelectFieldOptions,
  FormTextField,
  IOption,
} from '@novo-electronique/react-forms';
import classNames from 'classnames';

import { subtractYears } from '@common/utils/date';
import { selectServiceCenters } from '@modules/app/redux/selectors';
import {
  activeStateOptions,
  genderOptions,
  jobTypeOptions,
  levelOfSchoolingOptions,
  memberStatusOptions,
  provinceOptions,
  roleOptions,
  schoolingOptions,
} from '@modules/members/services/members';
import { toOptions } from '@modules/members/services/service-centers';
import SchoolMultiSelectField from '@modules/shared/components/Form/SchoolMultiSelectField';

interface IProps {
  disabled?: boolean;
}

export function MemberInfoSections({ disabled }: IProps) {
  const { t } = useTranslation();
  const serviceCenters = useSelector(selectServiceCenters);
  const { values } = useContext(FormDataContext);

  const statusOptions = useMemo<IOption[]>(
    () => memberStatusOptions(values.activeState),
    [values.activeState],
  );

  const onActiveStateChange = (
    value: string,
    setFieldValue: (field: string, value: any, shouldValidate: boolean) => void,
  ) => {
    // reset the status value since we will reset the available status options
    setFieldValue('status', '', false);
  };

  return (
    <>
      <FormFields title={t('membersPage.situation')} disabled={disabled}>
        <FormSelectField
          name="activeState"
          label={t('models.member.activeState')}
          onChange={onActiveStateChange}
          autoFocus
        >
          <FormSelectFieldOptions addEmptyOption={false} options={activeStateOptions()} />
        </FormSelectField>
        <FormSelectField name="status" label={t('models.member.status')}>
          <FormSelectFieldOptions options={statusOptions} />
        </FormSelectField>
      </FormFields>
      <FormFields title={t('membershipPage.personalInfo')} disabled={disabled}>
        <FormTextField name="firstName" label={t('models.member.firstName')} />
        <FormTextField name="lastName" label={t('models.member.lastName')} />
        <FormTextField name="identificationNumber" label={t('models.member.identificationNumber')} />
        <FormDateField
          name="dob"
          label={t('models.member.dob')}
          width={25}
          minDate={new Date(1900, 0)}
          maxDate={subtractYears(new Date(), 18)} // Must have at least 18 years old
        />
        <FormSelectField name="gender" label={t('models.member.gender')} width={25}>
          <FormSelectFieldOptions options={genderOptions()} />
        </FormSelectField>
        <FormTextField name="personalEmail" label={t('models.member.personalEmail')} />
        <FormPhoneField name="personalPhoneNumber" label={t('models.member.personalPhoneNumber')} />
        <FormSelectField name="schooling" label={t('models.member.schooling')}>
          <FormSelectFieldOptions options={schoolingOptions()} />
        </FormSelectField>
        <FormFields title={t('membershipPage.address')}>
          <FormTextField name="addrCivicNumber" label={t('models.member.addrCivicNumber')} width={25} />
          <FormTextField name="addrStreet" label={t('models.member.addrStreet')} />
          <FormTextField name="addrApp" label={t('models.member.addrApp')} width={25} />
          <FormTextField name="addrCity" label={t('models.member.addrCity')} />
          <FormSelectField name="addrProvince" label={t('models.member.addrProvince')} width={25}>
            <FormSelectFieldOptions options={provinceOptions()} />
          </FormSelectField>
          <FormPostalCodeField name="addrPostalCode" label={t('models.member.addrPostalCode')} width={25} />
        </FormFields>
      </FormFields>
      <FormFields title={t('membershipPage.professionalInfo')} disabled={disabled}>
        <FormSelectField name="role" label={t('models.member.role')} width={33}>
          <FormSelectFieldOptions options={roleOptions()} />
        </FormSelectField>
        <FormDateField
          name="startDate"
          label={t('models.member.startDate')}
          minDate={new Date(1996, 0, 1)}
          maxDate={new Date()}
          width={33}
        />
        <FormSelectField name="jobClassification" label={t('models.member.jobClassification')} width={33}>
          <option value="" />
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10</option>
          <option value="11">11</option>
        </FormSelectField>

        <FormMultiSelectField
          name="levelsOfSchooling"
          label={t('models.member.levelsOfSchooling')}
          options={levelOfSchoolingOptions()}
          width={100}
        />
        <SchoolMultiSelectField name="schools" label={t('models.member.schools')} width={100} />

        <FormSelectField name="serviceCenter.id" label={t('models.member.serviceCenter')} width={75}>
          <FormSelectFieldOptions options={toOptions(serviceCenters)} keepOriginalOrder />
        </FormSelectField>
        <FormSelectField name="jobType" label={t('models.member.jobType')} width={25}>
          <FormSelectFieldOptions options={jobTypeOptions()} />
        </FormSelectField>

        <FormPhoneField
          name="professionalPhoneNumber"
          label={t('models.member.professionalPhoneNumber')}
          width={37.5}
        />
        <FormTextField
          name="professionalPhoneNumberExt"
          label={t('models.member.professionalPhoneNumberExt')}
          width={12.5}
        />
        <FormPhoneField name="cellPhoneNumber" label={t('models.member.cellPhoneNumber')} />

        <FormTextField name="professionalEmail" type="email" label={t('models.member.professionalEmail')} />
        <FormAmountField name="salary" label={t('models.member.salary')} disabled />
      </FormFields>
    </>
  );
}

export default MemberInfoSections;
