import React, { Component, ReactNode } from 'react';
import { withTranslation } from 'react-i18next';
import { i18n, TFunction } from 'i18next';

import StatusBox, { Status } from '@modules/shared/components/StatusBox';

interface IProps {
  children: ReactNode | ReactNode[];
  t: TFunction;
  i18n: i18n;
}

interface IState {
  error: Error;
}

class ErrorBoundary extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { error: undefined };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
    this.setState({ error });
  }

  render() {
    if (this.state.error) {
      return (
        <StatusBox status={Status.Error} title={this.props.t('errorBoundary.title')}>
          {this.props.t('errorBoundary.message')}
        </StatusBox>
      );
    }
    return this.props.children;
  }
}

export default withTranslation()(ErrorBoundary);
