export enum ContributionPeriods {
  Month1 = 'month1', // Jan
  Month2 = 'month2', // Feb
  Month3 = 'month3', // Mar
  Month4 = 'month4', // Apr
  Month5 = 'month5', // May
  Month6 = 'month6', // Jun
  Month7 = 'month7', // Jul
  Month8 = 'month8', // Aug
  Month9 = 'month9', // Sep
  Month10 = 'month10', // Oct
  Month11 = 'month11', // Nov
  Month12 = 'month12', // Dec
  YearTotal = 'yearTotal',
}
