import { PaymentMethod } from '@common/constants/payment-method';

export interface IPayment {
  id?: string;
  amount: number;
  method: PaymentMethod;
  transactionNumber: string;
  date: Date;
}

export const getPaymentValidationSchema = (Yup) =>
  Yup.object({
    amount: Yup.number().required(),
    method: Yup.string().required(),
    transactionNumber: Yup.string().max(250).required(),
    date: Yup.date().required(),
  });
